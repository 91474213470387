
@import "../_mixins/pux/breakpoints.scss";
@import "../_mixins/pux/css-rules.scss";
@import "../_mixins/pux/scale.scss";
@import "../_variables.scss";

$slider-header-height: 7rem;
$slider-content-center: false;
$slider-color: #fff;
$slider-max-width: 75rem;
$slider-full-window: true;
$slider-height: 700px;

$slider-arrow: true;
$slider-arrow-width: 4rem;
$slider-arrow-height: $slider-arrow-width;
$slider-arrow-background: transparent;
$slider-arrow-radius: 50%;
$slider-arrow-border: none;
$slider-arrow-left: 2rem;
$slider-arrow-right: 2rem;
$slider-arrow-before-size: 20px;
$slider-arrow-prev-code: "\e901";
$slider-arrow-next-code: "\e901";

$slider-dot: true;
$slider-dot-width: 30px;
$slider-dot-height: $slider-dot-width;
$slider-dot-background: rgba(30,30,30,0.2);
$slider-dot-active: rgba(30,30,30,1);
$slider-dot-border: none;
$slider-dot-radius: 50%;
$slider-dot-padding: 0 2rem 0 0;
$slider-dot-bottom: 8rem;


@mixin slick-arrows($arrow-width: $slider-arrow-width, $arrow-height: $slider-arrow-width,$arrow-background: $slider-arrow-background,$arrow-radius: $slider-arrow-radius,$arrow-border: $slider-arrow-border,$arrow-left: $slider-arrow-left,$arrow-right: $slider-arrow-right,$arrow-before-size: $slider-arrow-before-size,$arrow-prev-code: $slider-arrow-prev-code,$arrow-next-code: $slider-arrow-next-code){
  .slick-arrow{
    position:absolute;
    top:50%;
    margin-top: -($arrow-width/2);
    width: $arrow-width;
    height: $arrow-height;
    @include border-radius($arrow-radius);
    border: $arrow-border;
    font-size:0;
    background: $arrow-background;
    outline: 0;
    z-index: 22;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:before{
      font-size: $arrow-before-size;
    }
    &.slick-prev{
      left: $arrow-left;
      &:before{
        content: $arrow-prev-code;
        display: block;
        @include opacity(0.5);
        @include rotate(90deg);
      }
    }
    &.slick-next{
      right: $arrow-right;
      &:before{
        content: $arrow-next-code;
        display: block;
        @include rotate(-90deg);
        @include opacity(0.5);
      }
    }
  }
}

@mixin slick-arrows-size($arrow-width, $arrow-height){
  .slick-arrow{
    width: $arrow-width;
    height: $arrow-height;
  }
}

@mixin slick-arrows-position($arrow-left, $arrow-right){
  .slick-arrow{
    &.slick-prev{
      left: $arrow-left;
    }
    &.slick-next{
      right:  $arrow-right;
    }
  }
}

@mixin slick-dost($dot-bottom: $slider-dot-bottom, $dot-padding: $slider-dot-padding, $dot-width: $slider-dot-width, $dot-height: $slider-dot-height, $dot-border: $slider-dot-border, $dot-radius: $slider-dot-radius, $dot-background: $slider-dot-background, $dot-active: $slider-dot-active){
  .slick-dots{
    @include flex-block;
    @include align-items(center);
    position: relative;
    top:-11rem;
    left:0;
    width:100%;
    max-width: 169rem;
    margin:auto;
    bottom: $dot-bottom;
    padding-left: $container-padding-left;
    padding-right: $container-padding-right;
    @include media(md){
        padding-left: $container-padding-left-md;
        padding-right: $container-padding-right-md;
    }
    li{
      margin:0;
      padding: $dot-padding;
      &:before{
        display:none;
      }
      &:last-child{
        padding:0;
      }
      button{
        font-size: 0;
        outline: 0;
        @include pux-static-size("width", $dot-width);
        @include pux-static-size("height", $dot-height);
        border: $dot-border;
        background: $dot-background;
        @include border-radius($dot-radius);
        padding: 0;
        @include flex-block;
        @include align-items(center);
        @include justify-content(center);
        &:before{
          content:"";
          @include pux-static-size("width", 6px);
          @include pux-static-size("height", 6px);
          background: #fff;
          @include border-radius(50%);
        }
      }
      &.slick-active{
        button{
          background: $dot-active;
        }
      }
    }
  }
}

.slider-init{
  .main-slider{
    width: 100%;
    background-repeat: no-repeat !important;
    @include background-size(cover);
    @include flex-block;
    @include flex-direction(row);
    &-inner{
      width:100%;
      padding:6rem 0 6rem 0;
      @if $slider-full-window{
        @include calc("min-height", "100vh - "+ $slider-header-height);
      } @else {
         min-height: #{$slider-height}rem;
      }
      @include flex-block;
      @include flex-direction(column);
      @include align-items(center);
      @include justify-content(center);
      &-content{
        max-width: $slider-max-width;
        @if $slider-content-center{
          text-align: center;
        }
        &-text{
          color: $slider-color;
          h1,
          h2{
            color: $slider-color;
          }
        }
        &-btn{
          padding: 3rem 0 0 0;
        }
      }
      .pux-container{
        width:100%;
      }
    }
    &.top-center{
      background-position: top center !important;
    }
    &.top-left{
      background-position: top left !important;
    }
    &.top-right{
      background-position: top right !important;
    }
    &.center-center{
      background-position: center center !important;
    }
    &.bottom-center{
      background-position: bottom center !important;
    }
    &.bottom-left{
      background-position: bottom left !important;
    }
    &.bottom-right{
      background-position: bottom right !important;
    }
  }
  @if $slider-arrow{
    @include slick-arrows;
  }
  @if $slider-dot{
    @include slick-dost;
  }
}
