
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";


.cms-bootstrap [class^="icon-"]:before, .cms-bootstrap [class*=" icon-"]:before {
    font-family: 'Core-icons' !important;
}

*:before,
*:after{
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.helper-4k{
  max-width: 200rem;
  margin:auto;
}

.hide{
  display: none !important;
}

.show{
  display:block !important;
}

.text-center{
  text-align: center;
}

.text-left{
  text-align: left;
}

.text-right{
  text-align: right;
}

.uppercase{
  text-transform: uppercase;
}

html {
  font-size: $scale-big-size*$scale-ratio-xxs;
  		@include media(xs) {
  				font-size: $scale-big-size*$scale-ratio-xs;
  		}
  		@include media(sm) {
  				font-size: $scale-big-size*$scale-ratio-sm;
  		}
  		@include media(md) {
  				font-size: $scale-big-size*$scale-ratio-md;
  		}
  		@include media(lg) {
  				font-size: $scale-base-size*$scale-ratio-lg;
  		}
  		@include media(xl) {
  				font-size: $scale-base-size*$scale-ratio-xl;
  		}
  		@include media(xxl) {
  				font-size: $scale-base-size*$scale-ratio-xxl;
      }
      
      &.big-body{
        		font-size: $scale-big-size*$scale-ratio-xxs;
        		@include media(xs) {
        				font-size: $scale-big-size*$scale-ratio-xs;
        		}
        		@include media(sm) {
        				font-size: $scale-big-size*$scale-ratio-sm;
        		}
        		@include media(md) {
        				font-size: $scale-big-size*$scale-ratio-md;
        		}
        		@include media(lg) {
        				font-size: $scale-big-size*$scale-ratio-lg;
        		}
        		@include media(xl) {
        				font-size: $scale-big-size*$scale-ratio-xl;
        		}
        		@include media(xxl) {
        				font-size: $scale-big-size*$scale-ratio-xxl;
        		}

            .floating-label{
              height: 60px!important;
              @include media(md){
                height: 75px!important;
              }
            }

            .floating-label-text{
              height: 120px!important;
              @include media(md){
                height: 180px!important;
              }
            }
      }
}

@media only screen and( -webkit-min-device-pixel-ratio: 1.25 ),
       only screen and(      -o-min-device-pixel-ratio: 5/4 ),
       only screen and( min-resolution: 120dpi ),
       only screen and( min-resolution: 1.25dppx ) {
		html {
				font-size: $scale-big-size*$scale-ratio-xxs;
				@include media(xs) {
						font-size: $scale-big-size*$scale-ratio-xs;
				}
				@include media(sm) {
						font-size: $scale-big-size*$scale-ratio-sm;
				}
				@include media(md) {
						font-size: $scale-big-size*$scale-ratio-md;
				}
				@include media(lg) {
						font-size: $scale-base-size*$scale-ratio-lg;
				}
				@include media(xl) {
						font-size: $scale-base-size*$scale-ratio-xl*0.8;
				}
				@include media(xxl) {
						font-size: $scale-base-size*$scale-ratio-xxl*0.8;
				}
		}
}

form,
html,
body{
    font-family:$font-family;
    height:100%;
    color: $base-black;
}

body{
	 @include pux-scale-with-min("font-size", 18px, 16px);
}

img{
    max-width:100%;
    height:auto !important;
}

@each $value in $px-values {
	  .pt-#{strip-unit($value)} {
				@include pux-scale-with-min("padding-top", $value, 20px);
	  }


	  .pb-#{strip-unit($value)} {
				@include pux-scale-with-min("padding-bottom", $value, 20px);
	  }



	  .mt-#{strip-unit($value)} {
				@include pux-scale-with-min("margin-top", $value, 20px);
	  }



	  .mb-#{strip-unit($value)} {
				@include pux-scale-with-min("margin-bottom", $value, 20px);
	  }

}

h1,
.h1{
  @include pux-scale-with-min("font-size", 48px, 25px);
  @include pux-scale-with-min("line-height", 50px, 23px);
  padding:5rem 0;
}

h2,
.h2{
  @include pux-scale-with-min("font-size", 33px, 22px);
  @include pux-scale-with-min("line-height", 37px, 28px);
  font-weight: 800;
  text-transform: uppercase;
  padding: 2rem 0;
}

h3,
.h3{
  @include pux-scale-with-min("font-size", 24px, 20px);
  @include pux-scale-with-min("line-height", 28px, 20px);
  font-weight: 700;
  padding: 1rem 0 2rem;
}

h4,
.h4{
  @include pux-scale-with-min("font-size", 22px, 18px);
  @include pux-scale-with-min("line-height", 28px, 20px);
  font-weight: 700;
}

big,
.perex{
  @include pux-scale-with-min("font-size", $font-size-perex, $min-font-size-perex);
  @include pux-scale-with-min("line-height", $line-height-perex, $min-line-height-perex);
}

small,
.text-small{
  @include pux-scale-with-min("font-size", $font-size-small, $min-font-size-small);
  @include pux-scale-with-min("line-height", $line-height-small, $min-line-height-small);
}

a{
  cursor: pointer;
  @include transition;
  &:focus,
  &:hover{
    outline:none;
    text-decoration: underline;
  }
}

.btn-download{
  font-size: 0;
  &:before{
    content:"\e927";
    @include pux-scale-with-min("font-size", 25px, 20px);
  }
  &:focus,
  &:hover{
    text-decoration: none;
  }
}

.btn{
  cursor: pointer;
  @include pux-scale-with-min("font-size", 15px, 15px);
  display: inline-block;
  @include border-radius(0);
  border: none;
  padding:1.5rem 6rem;
  @include box-shadow(none);
  text-transform: uppercase;
  font-weight: 700;
  &:focus,
  &:hover{
      text-decoration: none;
      @include box-shadow(none);
  }
}

.image-top-center{
  position: top center !important;
}

.image-top-left{
  position: top left !important;
}

.image-top-right{
  position: top left !important;
}

.image-bottom-center{
  position: bottom center !important;
}

.image-bottom-left{
  position: bottom left !important;
}

.image-bottom-right{
  position: bottom right !important;
}

main{
    min-height:40rem;
    ol{
        margin:0;
        padding:0;
        list-style:none;
        counter-reset: my-ol;
        li{
            position:relative;
            margin:0 0 1.5rem 0;
            padding:0 0 0 30px;
            &:before{
                content: counter(my-ol)".";
                counter-increment: my-ol;
                position:absolute;
                left:0;
                top:3px;
                font-family: $font-family!important;
                font-weight:bold;
            }
        }
    }
    ul{
        margin:0;
        padding:0;
        list-style:none;
        li{
            margin:0 0 1.5rem 0;
            padding:0 0 0 30px;
            position:relative;
            &:before{
                content:"";
                width:6px;
                height:6px;
                position:absolute;
                top:7px;
                left:0;
                @include border-radius(50%);
            }
        }
    }
    table{
      width:100%;
      tr{
        td{
          padding:1rem 1.5rem;
        }
      }
    }
    .respo-table{
      table{
        width:100%;
        thead{
          display: none;
          @include media($respo-table-break){
            display: table-header-group;
          }
          tr{
            th{
              font-weight: bold;
              padding:1rem 1.5rem;
            }
          }
        }
        tbody{
          tr{
            display: block;
            border-bottom: none;
            margin:0 0 2rem 0;
            @include media($respo-table-break){
              display: table-row;
              margin: 0;
              border: none;
            }
            td{
              @include flex-block;
              @include justify-content(space-between);
              width:100%;
              @include media($respo-table-break){
                display: table-cell;
                border: none;
                width:auto;
              }
              &:before{
                  content:attr(data-th);
                  display:block;
                  text-align:left;
                  font-family:$font-family !important;
                  margin:0 1.5rem 0 0;
                  @include media($respo-table-break){
                      display:none;
                  }
              }
            }
          }
        }
      }
    }
}
