
@import "../_mixins/pux/breakpoints.scss";
@import "../_mixins/pux/css-rules.scss";
@import "../_mixins/pux/scale.scss";
@import "../_variables.scss";
@import "../_dps-variables.scss";

$social-networks: ("facebook", "twitter", "linkedin", "youtube", "instagram", "pinterest", "google", "vimeo");

$social-colors: (
  'facebook': #3b5998,
  'twitter': #00aced,
  'youtube': #cc181e,
  'google': #d34836,
  'linkedin': #0d77b7,
  'instagram': #8a3ab9,
  'pinterest': #cd2029,
  'vimeo':#45bbff
);

$social-codes: (
  'facebook': "\e911",
  'twitter': "\e914",
  'youtube': "\e916",
  'google': "\e90f",
  'linkedin': "\e912",
  'instagram': "\e910",
  'pinterest': "\e913",
  'vimeo':"\e915"
);

$social-font-size: 25px;
$social-padding: 0 1.5rem 0 0;
$social-size: 50px;
$social-radius: 50%;
$social-background: $social-background;
$social-background-hover: darken($social-background,10);
$social-border: none;
$social-border-hover: none;
$social-color: $social-color;
$sosical-color-hover: $social-color;
$social-bgc-theme: true;
$social-bgc-theme-hover: false;
$social-theme-hover: true;

@mixin social-ico($code, $font-size, $size){
    &:before{
      position: absolute;
      content:$code;
      @include pux-scale-with-min("font-size", $font-size, 15px);
      color: #ffffff;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
}

@mixin create-social($font-size: $social-font-size, $padding: $social-padding, $size: $social-size, $radius: $social-radius, $background: $social-background, $background-hover: $social-background-hover, $border: $social-border, $border-hover: $social-border-hover, $color: $social-color, $color-hover: $sosical-color-hover, $bgc-theme: $social-bgc-theme, $bgc-theme-hover: $social-bgc-theme-hover, $theme-hover: $social-theme-hover){
  ul{
    @include flex-block;
    @include align-items(center);
    margin:0;
    padding:0;
    list-style:none;
    li{
      padding: $padding;
      &:before{
        display:none;
      }
      a{
        position: relative;
        font-size: 0;
        display:block;
        text-decoration: none;
        @include pux-scale-with-min("width", $size, 25px);
        @include pux-scale-with-min("height", $size, 25px);
        @include border-radius($radius);
        background: $background;
        border: $border;
        color: $color;
        text-align: center;
        @include transition;
        &:focus,
        &:hover{
          background: $background-hover;
          color: $color-hover;
          border: $border-hover;
          text-decoration: none;
        }
        span{
          display: none;
        }
      }
    }
  }
  @each $value in $social-networks {
    .#{$value}{
      @include social-ico(map-get($social-codes, $value), $font-size, $size);
      @if $bgc-theme{
        background: map-get($social-colors, $value);
        &:focus,
        &:hover{
          background: darken(map-get($social-colors, $value),10);
        }
      }
      @if $bgc-theme-hover{
        &:focus,
        &:hover{
          background: map-get($social-colors, $value);
        }
      }
      @if $theme-hover{
        &:focus,
        &:hover{
          color: map-get($social-colors, $value);
          border-color: map-get($social-colors, $value);
        }
      }
    }
  }
}
