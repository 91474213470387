
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";


.label-form,
label{
  display: block;
  margin:1rem 0 0.5rem 0;
  @include pux-static-size("font-size", 16px);
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"]{
  -moz-appearance:textfield;
}

.form-control,
textarea,
input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"]{
  width:100%;
  @include pux-scale-with-min("height", $input-height, 40px);
  padding: 0 1.5rem;
  @include pux-static-size("font-size", 16px);
  @include border-radius(.5rem);
  &:focus{
    outline: 0;
  }
}

textarea.form-control,
textarea{
  @include pux-scale-with-min("height", $textarea-height, 100px);
  padding: 1.5rem;
}


input[type="submit"]{
  cursor: pointer;
  &:focus,
  &:hover{

  }
}

.required{
    label{
        &:before{
            content:"*";
            display: inline-block;
            vertical-align: 0.6rem;
            margin: 0 2px 0 0;
            @include pux-static-size("font-size", 10px);
        }
    }
}

label{
  &.required{
    &:after{
        content:"*";
        display: inline-block;
        vertical-align: 0.6rem;
        margin: 0 2px 0 0;
        @include pux-static-size("font-size", 10px);
    }
  }
}

.file{
  .uploader-upload{
      display:none;
  }
  label{
      display:inline-block;
      padding:1rem 2rem;
      text-transform:uppercase;
      cursor:pointer;
      @include pux-static-size("font-size", 16px);
      @include border-radius(0);
      max-width: 24rem;
      margin:0;
      width: 100%;
      text-align:center;
      cursor:pointer;
      letter-spacing:1px;
      outline:0 !important;
  }
  .file-name{
      padding:0.5rem;
  }
}

@if($use-blink-error == 1){
  .form-validation-error,
  .Error:not(.form-validation-valid){
      select,
      .selectric,
      input[type="text"],
      input[type="password"],
      input[type="email"],
      input[type="number"],
      textarea{
          border: 1px solid $error-input-border !important;
          @include box-shadow(inset 0 0 0 1px $error-input-border);
          @include animation(normal, blink, 1s, ease, 2);
      }
  }

  .form-validation-error{
    select,
    .selectric,
    input[type="text"],
    input[type="password"],
    input[type="email"],
    input[type="number"],
    textarea{
        padding-right: 6rem;
    }
  }

    @include keyframes(blink){
        0% {
            background: $error-blink-start;
        }
        50% {
            background: $error-blink-final;
        }
        100% {
            background: $error-blink-start;
        }
    }
}

.error-span{
  @include pux-scale-with-min("height", $input-height - 10, 30px);
  @include pux-scale-with-min("width", $input-height - 10, 30px);
  @include flex-block;
  @include justify-content(center);
  @include align-items(center);
  background: $base-grey;
  position: absolute;
  @include pux-static-size("top", 5px);
  @include pux-static-size("right", 5px);
  @include border-radius(50%);
}

div.form-validation-error{
  .error-span{
    &:before{
      content:"\2715";
      color:red;
    }
  }
}

div.form-validation-valid{
  .FormErrorLabel,
  .EditingFormErrorLabel{
    display: none;
  }
  .error-span{
    &:before{
      content:"\2713";
      color:green;
    }
  }
}

div.form-validation-error,
div.form-validation-valid{
  position: relative;
}

.ErrorLabel,
.form-control-error,
div.form-validation-error,
.EditingFormErrorLabel{
    @include pux-static-size("font-size", 16px);
    color:$error-label-color;
    display:block;
    margin:0.5rem 0 0 0;
}

.radio{
    input[type="radio"]{
        display:none;
        & +label{
            position:relative;
            padding:0 0 1rem 25px;
            display: inline-block;
            margin:0;
            &:before{
                content:"";
                @include border-radius(50%);
                width:16px;
                height:16px;
                display:block;
                position:absolute;
                left:0;
                top:3px;
                @include transition;
            }
            &:after{
                content:"";
                display:block;
                left:5px;
                top:8px;
                width:6px;
                height:6px;
                @include border-radius(50%);
                position:absolute;
                @include opacity(0);
                @include transition;
            }
        }
        &:checked{
            & +label{
                &:after{
                    @include opacity(1);
                }
            }
        }
    }
}

.checkbox{
    input[type="checkbox"]{
        display:none;
        & +label{
            position:relative;
            padding:0 0 1rem 25px;
            margin:0;
            display: inline-block;
            &:before{
                content:"";
                width:16px;
                height:16px;
                display:block;
                position:absolute;
                left:0;
                top:3px;
                @include transition;
            }
            &:after{
                content:"";
                display:block;
                left:5px;
                top:8px;
                width:6px;
                height:6px;
                position:absolute;
                @include opacity(0);
                @include transition;
            }
        }
        &:checked{
            & +label{
                &:after{
                    @include opacity(1);
                }
            }
        }
    }
}

.row-form{
  @include flex-block;
  @include flex-wrap(wrap);
  margin:0 -1rem;
}

.row-form{
  [class^="col"]{
    padding-left:1rem;
    padding-right:1rem;
  }
}

#request-form {
  .selectric {
    width: 100%;
  }
}
