@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?f272cl');
  src:  url('fonts/icomoon.eot?f272cl#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?f272cl') format('truetype'),
    url('fonts/icomoon.woff?f272cl') format('woff'),
    url('fonts/icomoon.svg?f272cl#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-accessibility:before {
  content: "\e92d";
}
.icon-address:before {
  content: "\e92e";
}
.icon-email:before {
  content: "\e92f";
}
.icon-gdpr:before {
  content: "\e930";
}
.icon-phone:before {
  content: "\e931";
}
.icon-place:before {
  content: "\e932";
}
.icon-signpost:before {
  content: "\e933";
}
.icon-sitemap:before {
  content: "\e934";
}
.icon-zoom-in:before {
  content: "\e923";
}
.icon-zoom-out:before {
  content: "\e924";
}
.icon-rtf:before {
  content: "\e925";
}
.icon-doc1:before {
  content: "\e926";
}
.icon-file-transfer:before {
  content: "\e927";
}
.icon-docx:before {
  content: "\e920";
}
.icon-xls:before {
  content: "\e921";
}
.icon-ppt:before {
  content: "\e922";
}
.icon-akce:before {
  content: "\e91a";
}
.icon-fotky:before {
  content: "\e91b";
}
.icon-kontakt:before {
  content: "\e91c";
}
.icon-o_nas:before {
  content: "\e91d";
}
.icon-upozorneni:before {
  content: "\e91e";
}
.icon-sluzby:before {
  content: "\e91f";
}
.icon-360:before {
  content: "\e900";
  color: #f8b620;
}
.icon-arrow-big:before {
  content: "\e901";
  color: #f8b620;
}
.icon-arrow-small:before {
  content: "\e902";
  color: #f8b620;
}
.icon-play:before {
  content: "\e903";
  color: #f8b620;
}
.icon-zastupni-ikona:before {
  content: "\e904";
  color: #f8b620;
}
.icon-man:before {
  content: "\e905";
}
.icon-woman:before {
  content: "\e906";
}
.icon-ze_zivota_domova:before {
  content: "\e907";
}
.icon-service:before {
  content: "\e908";
}
.icon-past_event:before {
  content: "\e909";
}
.icon-o_nas1:before {
  content: "\e90a";
}
.icon-notification:before {
  content: "\e90b";
}
.icon-kontakt1:before {
  content: "\e90c";
}
.icon-homepage:before {
  content: "\e90d";
}
.icon-event:before {
  content: "\e90e";
}
.icon-googleplus:before {
  content: "\e90f";
}
.icon-instagram:before {
  content: "\e910";
}
.icon-facebook:before {
  content: "\e911";
}
.icon-linkedin:before {
  content: "\e912";
}
.icon-pinterest:before {
  content: "\e913";
}
.icon-twitter:before {
  content: "\e914";
}
.icon-vimeo:before {
  content: "\e915";
}
.icon-youtube:before {
  content: "\e916";
}
.icon-doc:before {
  content: "\e917";
}
.icon-jpg:before {
  content: "\e919";
}
.icon-pdf:before {
  content: "\e918";
}
.icon-external-link:before {
  content: "\e92c";
}
.icon-right-quote-sign:before {
  content: "\e928";
}
.icon-timeline-hodiny-zlute:before {
  content: "\e929";
  color: #f8b620;
}
.icon-timeline-hodiny-bile:before {
  content: "\e92a";
  color: #fff;
}
.icon-envelope:before {
  content: "\e92b";
}
