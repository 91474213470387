
@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "modules/pux-social.scss";
@import "_mixins/pux/scale.scss";
@import "_variables.scss";
@import "_dps-variables.scss";

.pux-container{
    margin-left: auto;
    margin-right: auto;
    padding-left: $container-padding-left;
    padding-right: $container-padding-right;
    max-width: $container-max-width;
    &:after{
        display: block;
        content: "";
        clear: both;
    }
    @include media(md){
        padding-left: $container-padding-left-md;
        padding-right: $container-padding-right-md;
    }
    .pux-container{
      padding-left:0;
      padding-right:0;
    }
}

.pux-container{
  .row{
    @include flex-block;
    @include flex-wrap(wrap);
    margin-left: $row-margin-left;
    margin-right: $row-margin-right;
    @include media(md){
      margin-left: $row-margin-left-md;
      margin-right: $row-margin-right-md;
    }
  }
}

.pux-container{
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
    .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12,
    .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
    .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
    .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
    .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
    .col-xl-auto{
        padding-left: $col-padding-left;
        padding-right: $col-padding-right;
        @include media(md){
          padding-left: $col-padding-left-md;
          padding-right: $col-padding-right-md;
        }
    }
}

.pux-fluid-container{
    padding-left: $fluid-container-padding-left;
    padding-right: $fluid-container-padding-right;
    @include media(md){
      padding-left: $fluid-container-padding-left-md;
      padding-right: $fluid-container-padding-right-md;
    }
    @include media(xl){
      padding-left: $fluid-container-padding-left-xl;
      padding-right: $fluid-container-padding-right-xl;
    }
}

.small-container{
  max-width: 1099px;
  margin:auto;
  padding-left: $container-padding-left;
  padding-right: $container-padding-right;
  @include media(md){
      padding-left: $container-padding-left-md;
      padding-right: $container-padding-right-md;
  }
}

.pux-container{
  .small-container{
    max-width: 1000px;
    padding-left:0;
    padding-right:0;
  }
}

header{
  position: fixed;
  width: 100%;
  z-index: 8888;
  //box-shadow: 0 1px #dedede;
  @include transition;
  .header{
    @include flex-block;
    @include justify-content(space-between);
    height: 8rem;
    @include media(xxs, $menu-break) {
      @include align-items(center);
    }
    @include media($menu-break){
      height: 7rem;
    }
    &-logo{
      @include flex-block;
      @include align-items(center);
      a{
        display:inline-block;
        span{
          display:none;
        }
        img{
          max-width: none;
          height: 5rem !important;
          @include transition;
        }
      }
    }
    &-menu{
      //padding: 1rem 0;
      display:none;
      position: absolute;
      top:100%;
      z-index: 77;
      left:0;
      width: 100%;
      @include calc("max-height", "100vh - 5rem");
      overflow-y: auto;
      min-height: 12.5rem;
      @include media($menu-break){
        @include flex-block;
        position: static;
        background: none;
        width:100%;
        min-height: 7rem;
        overflow-y:visible;
        justify-content: flex-end;
        align-self: stretch;
      }
      ul{
        margin:0;
        padding:0;
        list-style: none;
        @include media($menu-break){
          @include flex-block;
        }
        li{
          display: block;
          position: relative;
          margin: 2rem 0;
          outline: none;
          @include media($menu-break){
            margin:0;
            @include flex-block;
            @include align-items(center);
          }
          @include media(xl){
            margin:0 0.5rem;
          }
          @include media(xxl){
            margin:0 0.5rem;
          }
          span:not(.menu-openner),
          a{
            display:block;
            padding:1.5rem 1rem;
            @include media(xl){
              padding:1.5rem 1.5rem 1.5rem 1rem;
            }
            @include media(xxl){
              padding:1.5rem 1.5rem;
            }
            &:focus,
            &:hover{
              text-decoration: none;
            }
          }
          span{
            outline: none;
          }
          span:not(.menu-openner){
            cursor: default;
          }
          &.menu-360{
            display: flex;
            &:before{
              content: "\e900";
              align-self: center;
              width: 30px;
              margin-left: $main-left;
              @include media(md) {
                margin-left: $main-left-md;
              }
              @include media($menu-break){
                width: auto;
                margin-left: 3px;
                display: none;
              }
              @include media(xl){
                display: block;
              }
            }
          }
          ul{
            display: none;
            width:100%;
            @include opacity(1);
            visibility: visible;
            @include transition;
            @include media($menu-break){
              position: absolute;
              top: 100%;
              width:25rem;
              left: 50%;
              right: 50%;
              transform: translateX(-11.5rem);
              visibility: hidden;
              @include opacity(0);
              display: block;
              padding: 1rem 0rem;
            }
            li{
              @include media($menu-break){
                margin: 0!important;
                }
              }
              a{
                padding-left: $main-left;
                padding-right: $main-right;
                @include media(md) {
                  padding-left: $main-left-md;
                  padding-right: $main-right-md;
                }
                @include media($menu-break){
                  padding: 1rem 1.5rem;
                  display: block;
                  width: 100%;
                }
                @include media(xl){
                  padding: 1rem 2.5rem;
                }
              }
            }
            &.scroll {
              @include media($menu-break){
                ul {
                  overflow: auto;
                  &::-webkit-scrollbar {
                    width: 1em;
                  }
                  &::-webkit-scrollbar-button:start:decrement,
                  &::-webkit-scrollbar-button:end:increment {
                    display: block;
                    border-style: solid;
                    height: 13px;
                    width: 16px;
                  }
                  &::-webkit-scrollbar-thumb {
                    border-top: 2px;
                    border-bottom: 2px;
                  }
                  &::-webkit-scrollbar-button:start:decrement {
                    border-width: 0 7px 7px 7px;

                  }
                  &::-webkit-scrollbar-button:end:increment {
                    border-width: 7px 7px 0 7px;
                  }
                }
              }
            }
          &:hover,
          &:focus,&.focused{
            & > ul {
              visibility: visible;
              @include opacity(1);
            }
          }
          &.open{
            .menu-openner{
              &:after{
                @include rotate(0deg);
              }
            }
            ul{
              display:block;
            }
          }
          &.has-ul{
            @include media($menu-break){
              margin:0 0.5rem 0 0;
            }
            @include media(xl){
              margin:0 0.5rem;
            }
            @include media(xxl){
              margin:0 0.5rem;
            }
            span:not(.menu-openner),
            a{
              @include media($menu-break){
                padding:1.5rem 2rem 1.5rem 1rem;
              }
              @include media(xl){
                padding:1.5rem 2rem 1.5rem 1.5rem;
              }
            }
          }
        }
      }
      >ul {
        @include media($menu-break) {
          height: 100%;
          justify-content: flex-end;
        }
        >li:last-child {
          margin-right: 0;
          a {
            @include media($menu-break) {
              padding-right: 0;
            }
          }
        }
      }
      .menu-openner{
        position: absolute;
        top:0;
        right: $main-right;
        width: 6rem;
        height: 6rem;
        @include media(md) {
          right: $main-right-md;
          width: 5rem;
          height: 5rem;
        }
        @include media($menu-break){
          position: absolute;
          width: auto;
          height: auto;
          background: transparent;
          bottom: .5rem;
          right: 1rem;
        }
        &:after,
        &:before{
          content:"";
          width:70%;
          height:2px;
          position: absolute;
          left:15%;
          top:50%;
          margin-top:-1px;
          @include media($menu-break){
            content: "\e902";
            background: transparent;
            height: auto;
            width: auto;
            font-size: 6px;
          }
        }
        &:after{
          @include rotate(90deg);
          @include transition;
          @include media($menu-break){
            display: none;
          }
        }
      }
      .menu-openner-full-width {
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    &-hamburger{
      margin-right: -5px;
      @include media(md) {
        margin-right: -2px;
      }
      @include media($menu-break){
        display: none;
      }
      .open-menu{
        width:$hamburger-width;
        height:$hamburger-width;
        position: relative;
        display: block;
        @include media(md) {
          width: 5rem;
        }
        span{
          width:$hamburger-width - 1rem;
          position: absolute;
          left:0.5rem;
          height: 3px;
          margin-top:-1.5px;
          top:50%;
          @include transition;
          &:before,
          &:after{
            content:"";
            width:$hamburger-width - 1rem;
            left:0;
            height:3px;
            position: absolute;
            @include transition;
          }
          &:before{
            top:-8px;
          }
          &:after{
            bottom:-8px;
          }
        }
      }
    }
    &.open{
      .header-hamburger{
        .open-menu{
          span{
            background: transparent;
            &:before{
              top:0;
              @include rotate(45deg);
            }
            &:after{
              top:0;
              @include rotate(-45deg);
            }
          }
        }
      }
      .header-menu{
        display: block;
      }
    }
  }
}

.EditMode,
.DesignMode {
  header {
    position: static;
  }
}

footer{
  border-top:1px solid $color-line;
  @include pux-scale-with-min('font-size', 18px, 16px);
  @include pux-scale-with-min("line-height", $line-height-small, $min-line-height-small);
  .footer-top {
    line-height: 1.8;
    padding: 6rem 0 2rem 0;
    div[class^=col] {
      padding: 0 2rem;
    }
    &.row{
      @include flex-block;
      @include flex-wrap(wrap);
      padding-left: 0;
      padding-right: 0;
      margin-left: -2rem;
      margin-right: -2rem;
    }
    div[class^=col].row {
      padding: 0;
      margin: 0;
    }
    a {
      display: block;
      text-decoration: underline;
      font-weight: 700;
      &:focus,
      &:hover {
        text-decoration: none;
      }
    }
    .footer-links>a,
    .footer-map-link,
    .insert-phone,
    .insert-mail {
      display: block;
      position: relative;
      padding-left: 25px;
      &:before {
        position: absolute;
        left: 0;
        top: calc(50% - 0.5em);
        text-decoration: underline;
        display: inline-block;
      }
      &::before,
      &::before:hover {
        text-decoration: none;
      }
    }
    .footer-contacts {
      margin: 0 0 2rem 0;
      @include media(xxl) {
        margin: 4rem 0 2rem 0;
      }
    }
    .col-12.footer-logo {
      margin-top: 2rem;
      @include media(xl) {
        margin-top: 0;
      }
      img {
        max-width: 150px;
      }
    }
    .col-12.footer-map {
      padding-top: 3rem;
      @include media(md) {
        padding-top: 0;
        padding-left: 0;
        padding-right: 0;
      }
      img {
        max-width: 150px;
      }
    }
    .col-12.footer-map {
      @include media(sm) {
        padding-top: 0;
      }
    }
  }
  .footer-bottom {
    padding: 3rem 0 2.5rem 0;
    @include pux-scale-with-min('font-size', 17px, 15px);
    div[class^=col] {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    div[class^=col]:last-child {
      @include media(md) {
        justify-content: flex-end;
        flex-wrap: nowrap;
        white-space: nowrap;
      }
    }
  }
  .footer-address-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 2rem;
  }
  .footer-address {
    &-box {
      max-width: 100%;
      flex: 100%;
      padding-right: 2rem;
      @include media(xs) {
        flex: auto;
        flex-grow: 0;
      }
    }
    padding-left: 25px;
    display: block;
    position: relative;
    padding-left: 25px;
    flex-shrink: 1;
    &:before {
      position: absolute;
      left: 0;
      top: 0.3em;
      display: inline-block;
    content: "\e92e";
    }
  }
  .footer-map {
    max-width: 100%;
    flex: 100%;
    @include media(xs) {
      flex: auto;
      flex-grow: 1;
    }
  }
  .footer-social{
    @include create-social;
    margin: 2rem 0 2rem 0;
    @include media(xxl) {
      margin: 4rem 0 2rem 0;
    }
  }

  .icon-footer-copyright-second:before,
  .icon-footer-copyright-first:before {
    display: inline-block;
    content:'';
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .icon-footer-copyright-first {
    &:before{
      height: 35px;
      width: 75px;
      background-image: url('../img/footer1.png');
      margin: 0 1.5rem;
    }

  }
  .icon-footer-copyright-second {
    &:before{
      height: 24px;
      width: 136px;
      background-image: url('../img/footer2.png');
      margin:0 0 0 1.5rem;
    }

  }

  .footer-map-link {
    &:before {
    content: "\e932";
    }
  }
  .insert-phone {
    &:before {
    content: "\e931";
    }
  }
  .insert-mail {
    &:before {
    content: "\e92f";
    }
  }
  .footer-gdpr {
    &:before {
    content: "\e930";
    }
  }
  .footer-accessibility {
    &:before {
    content: "\e92d";
    }
  }
  .footer-sitemap {
    &:before {
    content: "\e934";
    }
  }
  .footer-signpost {
    &:before {
    content: "\e933";
    }
  }
}

.InternetExplorer{
  .footer-logo{
    display: block !important;
  }
}

.is-xxl{
  display:none;
  @include media(xxl){
      display:block;
  }
}

.slick-track{
  position: relative;
}

.intro-container{
  position: relative;
  height: 100%;
  .bgr-image{
    height: 100%;
    width: 100%;
    background-position: center!important;
    background-repeat: no-repeat!important;
    background-size: cover!important;
    position: relative;
    /*&:after{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 75vh;
      background: #000;
      @include opacity(0.15);
    }*/

  }
  .pux-container{
    position: relative;
    .intro-title{
      margin: 0;
      padding: 1.5rem 3rem;
      @include media(md){
        left: 4.5rem;
      }
    }
  }
}

.slider-wrapper{
  position: relative;
  height: 75vh;
}

.dots-wrapper{
  position: absolute;
  width: 100%;
  top: 2rem;
  left: 0;
  @include media(md){
    top: 0;
  }
  .pux-container{
    text-align: right;
  }
}

.slider-placeholder{
  width: 15rem;
}

.slider-init{
  height: 100%;
  .slick-list{
    height: 100%;
    .slick-track{
      height: 100%;
    }
  }
}

.founder-wrapper{
  position: absolute;
  bottom: 0;
  z-index: 5;
  left: 0;
  width: 100%;
  .pux-container{
    text-align: right;
    .founder-item{
      position: relative;
    }
    img{
      width: 14rem;
      position: absolute;
      bottom: 0;
      right: 0;
      @include media(lg){
        width: 12rem;
      }
      @include media(xxl){
        width: 10rem;
      }
    }
    span{
      background: $base-white;
      width: 14rem;
      text-align: center;
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 0.5rem;
      @include transform(translateY(100%));
      @include media(lg){
        width: 12rem;
      }
      @include media(xxl){
        width: 10rem;
      }
    }
  }

  &.wide-logo {
    //position: relative;
    position: absolute;
    padding: 2rem 0;
    top:0;
    @include media(md){
      position: absolute;
      padding: 0;
      top: auto;
      bottom: 0;
    }

    .founder-item {
      @include flex-block;
      @include align-items(center);
      @include media(md){
        display: block;
      }

      img {
        order: 2;
        width: auto;
        height: 5.25rem!important;

        @include media(md) {
          height: auto!important;
        }
      }

      span {
        order: 1;
        @include transform(translateY(0));
        width: 18rem;

        @include media(md){
          @include transform(translateY(100%));
        }
      }

      img, span{
        position: static;

        @include media(md) {
          width: 165px;
          position: absolute;
        }

        @include media(lg) {
          width: 175px;
        }

        @include media(xl) {
          width: 200px;
        }

        @include media(xxl) {
          width: 220px;
        }
      }
    }
  }
}

.slick-dots{
  margin: auto;
  //position: absolute;
  top: 0;
  right: 15px;
  padding: 3rem 0;
  @include media(md){
    right: 4.5rem;
  }
  &.reverse{
    right: auto;
    left: 15px;
    @include media(md){
      right: auto;
      left: 4.5rem;
    }
  }
  li{
    &:first-child:last-child{
      display: none;
    }
    display: inline-block;
    margin: 0 2rem;
    padding: 0;
    &:first-child{
      margin-left: 0;
    }
    &:last-child{
      margin-right: 0;
    }
    button{
      cursor: pointer;
      @include transition;
      height: 20px;
      width: 20px;
      @include border-radius(20rem);
      border: 0;
      font-size: 0;
      &:hover,
      &:focus{
        outline: 0!important;
      }
    }
    &:before{
      width: 0;
      height: 0;
      display: none;
    }
  }
}

.breadcrumbs{
  @include flex-block;
  @include align-items(center);
  @include justify-content(center);
  .home{
    @include flex-block;
    @include align-items(center);
    font-size: 0;
    &:after{
      content: "\e90d";
      @include pux-scale-with-min("font-size", 15px, 16px);
      display: block;
    }
    &:hover,
    &:focus{
      text-decoration: none;
      &:after{
        text-decoration: none;
      }
    }
  }
  padding-top: 1rem;
  .breadcrumbs-separator:after{
    position: relative;
    top: 0px;
    display: inline-block;
    content: "\e902";
    vertical-align: middle;
    @include transform(scale(0.25) rotate(270deg));
    margin: auto .5rem;
  }
}

.header-helper{
  height: 8rem;
  @include media($menu-break){
    height: 7rem;
  }
}

.header-intro{
  padding-top: 1rem;
  padding-bottom: 4rem;
  text-align: center;
  position: relative;
  .bgr-image{
    position: absolute;
    top:0;
    left:0;
    opacity: 0.2;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-position: center!important;
    background-repeat: no-repeat!important;
    background-size: cover!important;
  }
  h1{
    font-weight: 600;
    padding: 2rem 0 2rem 0;
    margin:0;
  }
  &-bread,
  &-title,
  &-subtitle{
    position: relative;
    z-index: 3;
  }
  &-title{
    .h1{
      padding: 0;
      margin: 3rem 0 1rem;
    }
  }
  &-subtitle{
    font-weight: 700;
    @include pux-scale-with-min("font-size", 13px, 13px);
    @include flex-block;
    @include align-items(center);
    @include justify-content(center);
    &:before{
      content: "\e90e";
      margin-right: 1rem;
    }
  }
}

.text-container{
  padding: 5rem 0;
  margin: auto;
  max-width: 900px;
}


.detail-photo{
  padding: 7.5px;
  position: relative;
  height: 30rem;
  width: 100%;
  overflow: hidden;
  display: block;
  &-bgr{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center!important;
    background-repeat: no-repeat!important;
    background-size: cover!important;
    @include transition;
  }
  &:focus,
  &:hover{
    text-decoration: none;
    .detail-photo-bgr{
      @include transform(scale(1.2));
    }
  }
}

.detail-photo-item{
  text-align: center;
  &-image {
    padding: 7.5px;
    position: relative;
    height: 30rem;
    width: 100%;
    overflow: hidden;
    text-align: center;
  }
  &-bgr{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center!important;
    background-repeat: no-repeat!important;
    background-size: cover!important;
    @include transition;
  }
  &-text {
    margin: 1rem 0 2rem 0;
    h4 {
      transition: 0.3s ease all;
    }
  }
  &:focus,
  &:hover{
    text-decoration: none;
    .detail-photo-item-bgr{
      @include transform(scale(1.2));
    }
  }
}

.intro-botttom{
  position: absolute;
  left:0;
  bottom:0;
  width:100%;
  &-inner{
    @include flex-block;
    @include justify-content(space-between);
    &.reverse{
      .intro-title{
        @include order(2);
      }

    }
  }
}

.row{
  .col-xs-12.col-md-6.col-lg-4.preview{
    padding: 7.5px;
    a:focus,
    a:hover{
      .detail-photo{
        &-bgr{
          @include transform(scale(1.2));
        }
      }
    }
  }
}

.contact-container{
  a{
    font-weight: 700;
  }
}

.person-item{
  margin: 5rem auto;
  @include media(md){
    margin: 3rem auto;
  }
  &-image{
    position: relative;
    margin: 2rem auto;
    width: 20rem;
    height: 20rem;
    @include media(md){
      width: 15rem;
      height: 15rem;
    }
    @include border-radius(100rem);
    &-bgr{
      @include border-radius(100rem);
      position: absolute;
      top:0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: center!important;
      background-repeat: no-repeat!important;
      background-size: cover!important;
    }
    &.man,
    &.woman{
      @include flex-block;
      @include align-items(center);
      @include justify-content(center);
      &:after{
        content: "\e905";
        display: block;
        font-size: 15rem;
        @include media(md){
          font-size: 10rem;
        }
      }
    }
    &.woman{
      &:after{
        content: "\e906";
      }
    }
  }
  &-content{
    a{
      text-decoration: underline;
      &:hover{
        text-decoration: none;
      }
    }
    &-function{
      @include pux-scale-with-min("font-size", $font-size-small, $min-font-size-small);
    }
    &-name{
      @include pux-scale-with-min("font-size", 20px, 16px);
      margin-bottom: 2rem;
    }
    &-name,
    &-function{
      font-weight: bold;
    }
  }
}

.map-container{
  //height: 45rem;
  @include media(md){
    height: 100%;
  }
}

.text-and-submit{
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .personal-detail-text{
    width: 100%;
    @include media(lg){
      width: 50%;
    }
  }
  .btn{
    margin: 2rem auto;
    @include media(lg){
      margin: auto 0;
    }
  }
}

.size-switch{
  cursor: pointer;
  position: fixed;
  top: 25vh;
  right: 0;
  text-align: center;
  @include transition;
  z-index: 999999999;
  display: none;
  @include media($menu-break){
    display: block;
  }
  .small-switch,
  .big-switch{
    @include transition;
    text-align: center;
    background-color: transparent;
    //padding: 1rem 1.5rem;
    @include pux-static-size("width",50px);
    @include pux-static-size("height",50px);
    @include flex-block-important;
    @include align-items(center);
    @include justify-content(center);
  }
  .small-switch{
    @include pux-scale-with-min("font-size", $font-size-small, $min-font-size-small);
    position: relative;
    &.show{
      &:after{
        @include transform(translateX(0));
      }
    }
    &:before{
      content:"\e924";
      @include pux-scale-with-min("font-size", $font-size-perex, $min-font-size-perex);
      line-height:1;
    }
    &:after{
      content: "Zmenšit písmo";
      @include flex-block;
      @include align-items(center);
      @include justify-content(center);
      font-family: $font-family!important;
      position: absolute;
      right: 0;
      width: 25rem;
      @include media(md){
        width: 15rem;
      }
      height: 100%;
      @include pux-scale-with-min("font-size", $font-size-small, $min-font-size-small);
      top: 0;
      @include transition(400ms);
      @include transform(translateX(1000px));
    }
  }
  .big-switch{
    @include pux-scale-with-min("font-size", $font-size-perex, $min-font-size-perex);
    font-weight: 700;
    position: relative;
    &.show{
      &:after{
        @include transform(translateX(0));
      }
    }
    &:before{
      content:"\e923";
      line-height:1;
    }
    &:after{
      @include flex-block;
      @include align-items(center);
      @include justify-content(center);
      content: "Zvětšit písmo";
      font-family: $font-family!important;
      position: absolute;
      right: 0;
      width: 30rem;
      @include media(md){
        width: 20rem;
      }
      height: 100%;
      line-height: 100%;
      @include pux-scale-with-min("font-size", $font-size-perex, $min-font-size-perex);
      font-weight: 700;
      top: 0;
      @include transition(400ms);
      @include transform(translateX(1000px));
    }
  }
}

.floating-label-text{
  height: 115px!important;
  @include media(md){
    height: 150px!important;
  }
}

.floating-label-text,
.floating-label {
    $input-height: 60px;
    $input-padding: 0;
    position: relative;
    width: 100%;
    height: 60px;
    @include transition;
    & > .placeholder {
      opacity: 1;
      pointer-events: none;
      line-height: $input-height;
      transform: translate3d(0,-100%,0);
      padding: $input-padding;
      display: inline-block;
      position: relative;
      z-index: 2;
      margin: 0;
      font-size: 30px;
      &.active {
        opacity: 0;
      }
    }
    & > label {
      position: absolute;
      top: 5px;
      left: 15px;
      display: inline-block;
      transition: all .3s ease;
      opacity: 1;
      z-index:11;
      padding: $input-padding;

      &.is-visible {
        transform: translate3d(0,-15px,0);
        opacity: 1;
        font-size:16px;

      }
    }
    & > input {
      position: relative;
      z-index: 1;
      float: left;
      width: 100%;
      height: $input-height;
      clear: both;
      margin-bottom: 1rem;
      padding: $input-padding;
      background-color: transparent;
    }
    &.active{
        label{
            transform: translate3d(0,-8px,0);
            opacity: 1;
            font-size:10px;
            left:15px;
            @include media(md){
              transform: translate3d(0,-10px,0);
            }
        }
    }
}

.is-visible{
  input{
    padding-top: 2.5rem!important;
    padding-left: 15px!important;
    @include media(md){
      padding-top: 1.25rem!important;
    }
  }
  textarea{
    padding-top: 3rem!important;
    padding-left: 15px;
    @include media(md){
      padding-top: 2rem!important;
    }
  }
}

.select-box{
  max-width: 900px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 2rem auto 3rem;
}

.javascript-hide{
  display: none;
}

.pux-infinite-repeater-options-inner-load-more{
  text-align: center;
}

.big-body{
  .header-menu ul li:before{
    display: none !important;
    @include media(xxl){
      display: block !important;
    }
  }
}
