@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";

// main colors
$color-link: #f8b620;
$main-color: #f8b620;
$second-color: #6a6865;
$color-line: #f1f1f1;
$base-black: #4c4c4c;
$base-white: #fff;
$base-grey: #ebebeb;
$submenu-hover: #2d9056;
$dark-grey: #939393;

// button primary
$btn-primary-background: transparent;
$btn-primary-color: $main-color;

// button default
$btn-default-background: transparent;
$btn-default-color: $second-color;

// ul, li
$ul-bullet: $main-color;
$ol-number: $main-color;

// table
$table-even-background: #f5f5f5;
$table-odd-background: $base-white;
$table-tr-border: $base-grey;

// form
$color-placeholder: #4c4c4c;

// inputs
$border-color: #f1f1f1;
$input-color: $base-black;
$input-background: #f5f5f5;
$focus-border-color: $main-color;
$input-height: 50px;
$textarea-height: $input-height * 3;

// selectric
$select-background: $input-background;
$select-color: $input-color;
$select-border: $input-background;
$select-border-focus: $main-color;
$select-arrow-color: $main-color;
$select-arrow-code: "\e901";
$select-use-triangle: 0;
$select-height: $input-height;

// datepicker
$datepick-today-background: $second-color;
$datepick-today-color:$base-white;
$datepick-active-background: $main-color;
$datepick-active-color: $base-white;

// upload
$upload-background: $main-color;
$upload-color: $base-white;
$file-name-background: $second-color;
$file-name-color: $base-black;

// form radio
$radio-background: #eeeff4;
$radio-border: #eeeff4;
$radio-active-border: $main-color;
$radio-after-background: $main-color;

// form checkbox
$checkbox-background: #eeeff4;
$checkbox-border: #eeeff4;
$checkbox-active-border: $main-color;
$checkbox-after-background: $main-color;

$breadcrumbs-color:#f8b620;
$breadcrumbs-color-active:#f8b620;

$first-gradient-color: #37a163;
$second-gradient-color: #8bb84d;
$gradient-degree: 168deg;
$gradient-text-color: #fff;
$gradient-btn-primary:#f8b620;
$gradient-a-color:#fff;

$header-color: #37a163;
$header-title: #fff;
$header-text: #fff;

$menu-background: #37a163;
$menu-text: #fff;

$footer-link: $base-black;
$footer-link-icon: $main-color;
$social-color:$second-gradient-color;
$social-background:$main-color;

$footer-btn: $footer-link;
$footer-btn-hover: $social-color;

$service-background-color: #f5dba0;
$actuality-background-color: #f5dba0;
$event-background-color: #f5dba0;

$background-gray: #f5f5f5;
$background-dark: #4c4c4c;

$banner-background:$main-color;
$banner-color:#fff;
$banner-style-color:$main-color;

$ico-color: $main-color;

$ico1-code: "\e91a";
$ico1-color: $ico-color;

$ico2-code: "\e91b";
$ico2-color: $ico-color;

$ico3-code: "\e91c";
$ico3-color: $ico-color;

$ico4-code: "\e91d";
$ico4-color: $ico-color;

$ico5-code: "\e91e";
$ico5-color: $ico-color;

$ico6-code: "\e91f";
$ico6-color: $ico-color;
