@import "_mixins/pux/breakpoints.scss";
@import "_mixins/pux/css-rules.scss";
@import "_mixins/pux/scale.scss";
@import "modules/pux-slider.scss";
@import "_variables.scss";

.about-content-btns{
  text-align: center;
  padding:5rem 0 0 0;
  .btn{
    margin-top: 15px;
    @include media(md){
      margin-top: 0;
    }
    margin-left: 7.5px;
    margin-right: 7.5px;
  }
}

.notification-card{
  filter: drop-shadow(0 2rem 7rem rgba(0,0,0,0.07));
  width: 100%;
  a{
    display: block;
    width: 100%;
    padding: 2rem;
    &:focus,
    &:hover{
      text-decoration: none;
    }
  }
}

.circle-btn{
  margin: auto;
  text-align: center;
  position: absolute;
  width: 5rem;
  height: 5rem;
  bottom: -2.5rem;
  z-index: 6;
  left: 50%;
  @include transform(translateX(-50%));
  span{
    position: relative;
    font-size: 3rem;
    display: block;
    width: 5rem;
    height: 5rem;
    @include border-radius(50%);
    @include flex-block;
    @include align-items(center);
    @include justify-content(center);
    line-height: 1;
    box-shadow: 0 1px #dedede;
  }
}

.pux-container,.widget-container{
  position: relative;
}

.big-image{
  width: 100%;
}

.cta-text{
  text-align: center;
  padding:0 0 5rem 0;
  &-content{
    padding:5rem 0;
  }
}

.col-xs-12.col-sm-6.col-lg-4,
.col-xs-12.col-sm-6.col-lg-8{
  padding: 7.5px;
  display: flex;
}

.middle-card,
.big-card,
.small-card{
  padding: 0!important;
  width: 100%;
  .card-item-image{
    position: relative;
    width: 100%;
    overflow: hidden;
    &-bgr{
      @include transition;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: center!important;
      background-repeat: no-repeat!important;
      background-size: cover!important;
    }
  }
  .card-item{
    &-content{
      margin-bottom: 5rem;
    }
  }
}

.big-card{
  height: 100%;
  .card-item{
    height: 100%;
    &-image{
      min-height: 30rem;
      @include media(lg){
        height: 100%!important;
      }
    }
    &-content{
      padding-bottom: 10rem;
      margin-bottom: 0rem;
      @include media(lg){
        padding-bottom: 2rem;
      }
      &-text{
        @include media(lg){
          margin-bottom: 3rem;
        }
      }
    }
  }
}

.middle-card,
.big-card{
  filter: drop-shadow(0 2rem 7rem rgba(0,0,0,0.07));
  .card-item-image{
    height: 30rem;
    &-bgr{
      height: 100%!important;
    }
  }
}

.big-card,
.small-card{
  .card-item{
    @include media(lg){
      display: flex;
      align-items: flex-start;
      flex-direction: row;
    }
    &-image{
      width: 100%;
      @include media(lg){
        width: 50%;
      }
      &-bgr{
        height: 100%;
      }
    }
    &-label{
      padding-left: 0;
    }
    &-content{
      @include media(lg){
        flex: 1;
        position: relative;
      }
      &-ref{
        padding-top: 2rem;
        @include media(lg){
          padding-top: 0rem;
          position: absolute;
          left:3rem;
          bottom: 3rem;
          padding: 0px;
          bottom: 2rem;
        }
      }
    }
  }
}

.small-card{
  margin: 0.75rem 0;
  padding: 0;
  background-color: transparent;
  a{
    &:first-child{
      .card-item{
        margin-top: 0!important;
      }
    }
    &:last-child{
    .card-item{
      margin-bottom: 0!important;
      }
    }
  }

  .card-item{
    min-height: 1rem;
    align-items: normal !important;
    @include media(lg){
      min-height: 15rem;
    }
    .h3{
      padding:0;
      @include media(lg){
      min-height: 60px;
    }
    }
    &-label{
      padding:1rem 0;
      @include media(lg){
      min-height: 50px;
    }
    }
    &-image{
      width: 100%;
      height: 30rem;
      @include media(lg){
        width: 33%;
        height: auto;
      }
      &-bgr{
        height: 100%;
      }
    }
    &-content{
      min-height: 15rem;
      margin-bottom: 0;
      padding:0.5rem 3rem 1.5rem;
      display: flex;
      flex-wrap: wrap;
      @include media(lg){
        min-height: 100%;
      }
      .card-item-label{
        order:2;
        width: 100%;
        @include media(md){
          order:1;
        }
      }
      .card-item-content-title {
        order:1;
        width: 100%;
        padding: 3rem 0rem 1.5rem;
        @include media(md){
          order:2;
          padding: 0rem 0rem 5rem;
        }
      }

      .card-item-content-title {
        order: 2;
      }
    }
  }
}

.col-xs-12.col-lg-8{
  padding: 7.5px;
}

.card-item{
  @include media(md){
    display: flex;
    flex-direction: column;
  }
  &-image{
    order: 1;
    &-label{
      display: none;
    }
  }
  &-label{
    order: 2;
    font-weight: 700;
    padding: 3rem;
    padding-bottom: 1rem;
    @include pux-scale-with-min("font-size", 16px, 16px);
    position: relative;
    @include media(md) {
      padding-bottom: 0;
    }
  }
  &-content{
    order: 3;
    padding: 3rem;
    padding-top: .5rem;
    &-ref{
      padding-top: 2rem;
      @include media(lg){
        padding-top: 0rem;
        position: absolute;
        left:3rem;
        bottom: 3rem;
      }
      font-weight: 700;
      @include pux-scale-with-min("font-size", 16px, 16px);
    }
  }
}

.card-event{
  .card-item{
    &-image{
      order: 2;
      &-label{
        display: block;
        text-align: center;
        padding: 1rem 1rem;
        width: 20%;
        font-weight: bold;
        @include pux-scale-with-min("font-size", 16px, 16px);
        margin: auto;
        position: relative;
      }
    }
    &-label{
      order: 1;
      margin-top: -9px;
      text-align: center;
      padding: 2rem;
      @include pux-scale-with-min("font-size", 20px, 16px);
      font-weight: 700;
    }
    &-content{
      padding-top: 3rem;
      &-date{
        font-weight: 700;
        @include pux-scale-with-min("font-size", 16px, 16px);
      }
      &-ref{
        padding-top: 2rem;
        @include media(lg){
          padding-top: 0rem;
          position: absolute;
          left:3rem;
          bottom: 3rem;
        }
        font-weight: 700;
        @include pux-scale-with-min("font-size", 16px, 16px);
      }
    }
  }
}

.card-project{
  .card-item{
    &-image{
      display: none;
    }
  }
}

.row{
  justify-content: center;
}

.small-card,
.middle-card,
.big-card{
  a:hover,
  a:focus{
    .card-item{
      &-image{
        &-bgr{
          @include transform(scale(1.1));
        }
      }
      &-content{
        &-title,
        &-text{
          text-decoration: none;
        }
        &-ref{
          text-decoration: underline;
        }
      }
    }
    text-decoration: none!important;
  }
}

.card-item-content-ref,
.footer-ref{
  &:after{
    position: relative;
    bottom: 1px;
    display: inline-block;
    content: "\e902";
    vertical-align: middle;
    @include transform(scale(0.5) rotate(270deg));
  }
}

.small-image{
  text-align: center;
  img{
    width: 33%;
    margin: 10rem auto;
  }
}

.big-image{
  img{
    width: 100%;
  }
}

.embed-video {
  position: relative;
  &.small-16 {
    height: 32rem;
    max-width: 80rem;
    margin:0 auto;
  }
  &.small {
    height: 42rem;
    max-width: 80rem;
    margin:0 auto;
  }
  &.middle-16 {
    height: 52rem;
    max-width: 120rem;
    margin:0 auto;
  }
  &.middle {
    height: 62rem;
    max-width: 120rem;
    margin:0 auto;
  }
  &.large-16 {
    height: 74rem;
  }
  &.large{
    height: 84rem;
  }
  iframe {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.embed-video-overflow {
  &.autoplay-1, &.played {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 1000ms 400ms, visibility 0ms 1000ms;
    -moz-transition: opacity 1000ms 400ms, visibility 0ms 1000ms;
    -o-transition: opacity 1000ms 400ms, visibility 0ms 1000ms;
    -ms-transition: opacity 1000ms 400ms, visibility 0ms 1000ms;
    transition: opacity 1000ms 400ms, visibility 0ms 1000ms;
  }
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: cover;
  background-position: center;
  cursor: pointer;
}

.embed-video-icon {
  display: block;
  height: 9rem;
  width: 9rem;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  @include transition(300ms);
  @include transform(translate(-50%, -50%));
  &:before {
    content: "\e903";
    display: inline-block;
    font-size: 3rem;
    width: auto;
    height: auto;
    @include transition(300ms);
    top:33%;
    bottom: 33%;
    left: 40%;
    right: 40%;
    position: absolute;
  }
}

.pux-infinite-repeater-options-inner-load-more{
  padding:5rem 0 0 0;
}

.uppercase-title .about-item {
  h2 {
    text-transform: uppercase;
  }
}


.about-item{
  padding:0 0 5rem 0;
  h2 {
    text-transform: none;
  }
}

.small-style {
  h2 {
    @include pux-scale-with-min("font-size", 32px, 20px);
    @include pux-scale-with-min("line-height", 35px, 25px);
    font-weight: 400;
    text-transform: none;
    padding: 0.5rem 0 4rem 0;
    
  }
}

.quote-style {
  h2 {
    font-style: italic;
    text-transform: none;
    &:before {
      content:'„';
      font-style: italic;
      font-family: $font-family !important;
    }
    &:after {
      content:'“';
      font-style: italic;
      font-family: $font-family !important;
    }
  }
}


.card-event{
  margin-top:10px;
  .card-item{
    position: relative;
  }
  .card-item-blog{
    width: 100%;
    position: relative;
    top:-10px;
  }
}

.news-box{
  @include flex-block;
  @include flex-wrap(wrap);
  margin:0 -2rem;
  width:100%;
  padding:4rem 0 0 0;
  &-left{
    width:100%;
    padding:0 2rem 2rem 2rem;
    @include media(md){
      width:33%;
    }
  }
  &-right{
    width:100%;
    padding:0 2rem 2rem 2rem;
    @include media(md){
      width:67%;
    }
  }
}

.events-box{
  @include flex-block;
  @include flex-wrap(wrap);
  @include media(md){
    margin:0 -2rem;
  }

  .pux-container{
    padding: 0;
    @include media(md){
        padding-left: $container-padding-left-md;
        padding-right: $container-padding-right-md;
    }
  }
  padding:4rem 0 0 0;
  &-left{
    width:100%;
    @include media(md){
      padding:0 2rem 2rem 2rem;
    }
    @include media(xxl){
      width:33%;
    }
    h2.widget-title{
      text-align: left!important;
      @include flex-block;
      @include align-items(center);
      &:before{
        content: "\e90e";
        margin-right: 1rem;
      }
    }
  }
  &-right{
    width:100%;
    @include media(md){
      padding:0 2rem 2rem 2rem;
    }

    @include media(xxl){
      width:67%;
    }
    h2.widget-title{
      text-align: left!important;
      @include flex-block;
      @include align-items(center);
      &:before{
        content: "\e909";
        margin-right: 1rem;
      }
    }
    .card-item-blog,
    .card-item-image-label{
      display: none;
    }

    .card-item-label{
      @include order(2);
      font-weight: 700;
      padding: 3rem;
      padding-bottom: 0;
      position: relative;
      text-align: left;
      @include pux-scale-with-min("font-size", 16px, 16px);
    }

    .card-item-content{
      padding-top: .5rem;
    }

    .card-item-image{
      @include order(1);
    }

    .middle-card.card-event{
      margin: 0;
    }
    .news-box{
      &-left{
        @include media(md){
          width:50%;
        }
      }
      &-right{
        @include media(md){
          width:50%;
        }
      }
    }
  }
  &.event-reverse{
    .events-box-left{
      order: 2;
      .widget-container {
        border-right: none;
        position: relative;
        &:before{
          content:"";
          width: 2px;
          height: 100%;
          position: absolute;
          left:-2rem;
          top:0;
          background: #ebebeb;
        }
      }
    }
    .events-box-right{
      order: 1;
    }
  }
  .banner-col{
    max-width: 100%;
    flex: 0 0 100%;
  }
  .events-box-left{
    .row{
      margin-left: -7.5px !important;
      margin-right: -7.5px !important;
    }
  }
}

.events-upcoming{
  margin-bottom: 2rem;
  width: 100%;
  a{
    @include flex-block;
    @include flex-wrap(nowrap);
    @include align-items(center);
    &:focus,
    &:hover{
      text-decoration: none;
      .events-upcoming-link-right{
        text-decoration: underline;
      }
    }
    .events-upcoming-link-left{
      margin-right: 2rem;
      font-weight: 700;
      min-width: 150px;
      &-date-day{
        width: 100%;
        text-align: center;
        padding: 1rem 2rem;
        @include pux-scale-with-min("font-size", $font-size-perex, $min-font-size-perex);
      }
      &-date-time{
        width: 100%;
        padding: 0.5rem 1rem;
        text-align: center;
        font-weight: 400;
        @include pux-scale-with-min("font-size", $font-size-small, $min-font-size-small);
      }
    }
    .events-upcoming-link-right{
      flex:1;
    }
  }
}

.events-small,
.news-small{
  &-link{
    @include flex-block;
    @include align-items(center);
    margin:0 -1rem;
    @include flex-wrap(wrap);
    @include media(sm){
      @include flex-wrap(nowrap);
    }
    &-left{
      margin:0 1rem 2rem 1rem;
      width:100%;
      overflow: hidden;
      @include media(sm){
        width:25rem;
      }
      &-image{
        width:100%;
        height:30rem;
        @include transition;
        @include media(sm){
          height:12.5rem;
        }
        background-size: cover !important;
        background-position: top center !important;
      }
    }
    &-right{
      padding:0 1rem 2rem 1rem;
      width: 100%;
      text-align: left;
      &-label{
        font-weight: 700;
        padding-bottom: 0;
        @include pux-scale-with-min("font-size", 16px, 16px);
      }
      &-ref{
        font-weight: 700;
        @include pux-scale-with-min("font-size", 16px, 16px);
        &:after{
          position: relative;
          bottom: 1px;
          display: inline-block;
          content: "\e902";
          vertical-align: middle;
          @include transform(scale(0.5) rotate(270deg));
        }
      }
    }
    &:focus,
    &:hover{
      text-decoration: none;
      .news-small-link-left-image,
      .events-small-link-left-image{
        @include transform(scale(1.1));
      }
      .news-small-link-right-ref,
      .events-small-link-right-ref{
        span{
          text-decoration: underline;
        }
      }
    }
  }
}

.header-menu{
  ul{
    li{
        &.menu-about,
        &.menu-home,
        &.menu-services,
        &.menu-life,
        &.menu-contact{
          @include flex-block;
          @include align-items(center);
          @include flex-wrap(wrap);
          &:before{
            width: 30px;
            margin-left: $main-left;
            @include media(md) {
              margin-left: $main-left-md;
            }
            @include media($menu-break){
              width: auto;
              margin-left: 3px;
              display: none;
            }
            @include media(xl){
              display: block;
            }
          }

        }
        &.menu-about{
          &:before{
            content: "\e90a";
          }
        }
        &.menu-home{
          &:before{
            content: "\e90d";
          }
        }
        &.menu-services{
          &:before{
            content: "\e908";
          }
        }
        &.menu-life{
          &:before{
            content: "\e907";
          }
        }
        &.menu-contact{
          &:before{
            content: "\e90c";
          }
      }
    }
  }
}



.pdf,
.docx,
.rtf,
.xls,
.ppt,
.doc{
  display: inline-flex;
  @include flex-wrap(nowrap);
  @include align-items(flex-end);
  span:before{
    margin-right: 10px;
    @include pux-scale-with-min("font-size", 30px, 20px);
    display: inline-block!important;
    text-decoration: none!important;
  }
}

.blank{
  display: inline-flex !important;
  @include flex-wrap(nowrap);
  @include align-items(flex-end);
  span:before{
    @include pux-scale-with-min("font-size", 18px, 16px);
    margin-right: 10px;
    display: inline-block!important;
    text-decoration: none!important;
  }
  & span + span{
    display: none;
  }
}


.pdf{
  span:before{
    content: "\e918";
  }
}
.doc{
  span:before{
    content: "\e917";
  }
}
/*.jpg{
  &:before{
    content: "\e919";
  }
}*/

.ppt{
  span:before{
    content: "\e922";
  }
}

.docx{
  span:before{
    content: "\e920";
  }
}

.xls{
  span:before{
    content: "\e921";
  }
}

.rtf{
  &span:before{
    content: "\e925";
  }
}

main{
  .blank{
    span:before{
      content: "\e92c";
    }
  }
}

.gallery-row{
  margin-left: -7.5px !important;
  margin-right: -7.5px !important;
}

.gallery-widget-row{
  margin-left: -2.25rem !important;
  margin-right: -2.25rem !important;
  [class^="col"]{
    padding: 2.25rem;
  }
  .jpg{
    &:before{
      display: none;
    }
  }
}

.no-ico{
  &:before{
    display: none !important;
  }
}

.gallery-title{
  @include flex-block;
  @include align-items(center);
  position: relative;
  h2{
    text-transform: none;
    margin-right: 2rem;
  }
  &-numbers{
    padding: 2rem 0 2rem 0;
    @include pux-scale-with-min("line-height", 35px, 22px);
    @include pux-scale-with-min("font-size", 22px, 15px);
    font-weight: 700;
  }
  &:before{
    content:"";
    @include pux-scale-with-min("width", 8px, 4px);
    @include pux-scale-with-min("height", 35px, 22px);
    margin:15px;
    /*position: absolute;
    left: -2.25rem;
    top: 2rem;*/
  }
}

.select-box{
  @include flex-block;
  @include justify-content(center);
  @include flex-wrap(wrap);
  .filter-item{
    padding:0 1rem 2rem 1rem;
  }
}

.banner-item{
  &-inner{
    @include flex-block;
    @include flex-direction(row);
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    position: relative;
    &:before{
      content:"";
      position:absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
    }
    &-content{
      @include flex-block;
      @include flex-direction(column);
      @include align-items(center);
      @include justify-content(center);
      min-height: 30rem;
      text-align: center;
      padding:3rem;
      width: 100%;
      position: relative;
    }
  }
}

.banner-item.style-1,
.banner-item.style-2,
.banner-item.style-3,
.banner-item.style-4{
  text-decoration: none;
  display: block;
  position: relative;
  overflow: hidden;
  .banner-item-bgr{
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    @include transition;
  }
  .banner-item-inner{
    min-height: 35rem;
  }
  &:hover,
  &:focus{
    .banner-item-bgr{
      @include transform(scale(1.1));
    }
  }
}

.banner-item.style-1{
  .banner-item-inner{
    &:before{
      display: none;
    }
    &-content{
      &-title{
        padding: 1rem 3rem;
        margin: 0;
      }
      &-btn{
        padding: 1rem 3rem;
        text-transform: uppercase;
        letter-spacing: 2.8px;
        font-size: 16px;
        font-weight: 700;
        @include transition;
      }
    }
  }
}

.banner-item.style-2{
  &:hover,
  &:focus{
    .banner-item-inner-content-row-btn{
      @include transform(translateX(0.5rem));
    }
  }
  .banner-item-inner{
    &-content{
      @include align-items(flex-start);
      @include justify-content(flex-end);
      &-title{
        padding: 1rem 3rem;
        margin: 0;
      }
      &-row{
        @include flex-block;
        @include align-items(center);
        @include justify-content(space-between);
        padding: 2rem 0 0 3rem;
        width: 100%;
        &-perex{
          text-align: left;
          max-width: 50rem;
        }
        &-btn{
          padding: 0 0 0 2rem;
          @include transition;
          &:after{
            content: "\e901";
            display: block;
            @include rotate(-90deg);
            font-size: 2rem;
          }
        }
      }
    }
  }
}

.banner-item.style-3{
  &:hover,
  &:focus{
    .banner-item-inner-content-btn{
      @include transform(translateY(0));
    }
  }
  .banner-item-inner{
    &:before{
      display: none;
    }
    &-content{
      @include flex-direction(column);
      @include justify-content(space-between);
      padding: 0 3rem;
      &-title{
        padding: 1rem 3rem;
        margin: 0;
      }
      &-btn{
        padding: 1.5rem 3rem 2.5rem;
        text-transform: uppercase;
        letter-spacing: 2.8px;
        font-size: 16px;
        font-weight: 700;
        @include transform(translateY(1rem));
        @include transition;
      }
    }
  }
}

.banner-item.style-4{
  &:hover,
  &:focus{
    .banner-item-inner-content-btn{
      @include transform(translate(-50%,-.5rem));
    }
  }
  .banner-item-inner{
    &-content{
      &-title{
        padding: 1rem 3rem;
        margin: 0;
        @include transform(translateY(-4rem));
      }
      &-btn{
        padding: 1.5rem 3rem;
        text-transform: uppercase;
        letter-spacing: 2.8px;
        font-size: 16px;
        font-weight: 700;
        @include transition;
        position: absolute;
        bottom: 0;
        left: 50%;
        @include transform(translate(-50%,0));
      }
    }
  }
}

.banner-col{
  padding: 7.5px!important;
}

.map-box{
  position: relative;
  width:100%;
  text-align: center;
  &-name{
    padding: 1.5rem 3rem;
    @include border-radius(6rem);
    display:inline-block;
    position: relative;
    z-index: 888;
    @include transform(translate(0, 50%));
  }
  #map-canvas{
    width: 100%;
    height:50rem;
  }
}

.map-points{
  display: none;
}

.marker-detail{
  display: none;
}

.marker-detail-inner{
  display: flex;
  img{
    max-width: 150px;
  }
}

.is-image{
  .marker-detail-text{
    padding:0 0 0 15px;
  }
}

.map-box{
  h3{
    padding-bottom: 5px;
  }

}

.marker-detail-service,
.marker-detail-capacity,
.marker-detail-contact,
.marker-detail-mail{
  color: $base-black;
  @include flex-block;
  @include align-items(center);
  line-height: 1.5;
  font-size: 15px;
  &:before{
    padding-right: 10px;
    line-height: 1.5;
  }
}

.marker-detail-content{
  text-align: left;
  line-height: 1.5;
  font-size: 15px;
}

.marker-detail-service{
  &:before{
    content: "\e91f";
  }
}

.marker-detail-capacity{
  &:before{
    content: "\e91d";
  }
}

.marker-detail-contact{
  &:before{
    content: "\e91c";
  }
}

.marker-detail-mail{
  &:before{
    content: "\e92b";
  }
}

.marker-detail-close{
  opacity: 1 !important;
  img{
    display: none !important;
  }
  &:before{
    content:"\03a7";
    font-family: $font-family !important;
  }
}

.insert-image{
  a{
    display: inline-block;
    &:before{
      display: none !important;
    }
  }
}

main{
  .tenders-table{
    table{
      width:100%;
      border-collapse: collapse;
      border-style: hidden;
      thead{
        display: none;
        @include media($respo-table-break){
          display: table-header-group;
        }
        tr{
          th{
            font-weight: 400;
            text-transform: uppercase;
            padding:1rem 0 1.5rem 0;
            @include pux-static-size("font-size", 14px);
          }
        }
      }
      tbody{
        tr{
          display: block;
          border-bottom: none;
          margin:0 0 2rem 0;
          position: relative;
          cursor: pointer;
          @include media($respo-table-break){
            display: table-row;
            margin: 0;
            border: none;
          }
          &:after{
            content:"";
            width: 100%;
            right:0;
            position: absolute;
            height: 2px;
          }
          &:first-child{
            &:after{
              display: none;
            }
          }
          td{
            @include flex-block;
            @include justify-content(space-between);
            width:100%;
            position: relative;
            padding:2.3rem 2.5rem;
            line-height: 1.3;
            text-align: right;
            @include media($respo-table-break){
              display: table-cell;
              width:auto;
              text-align: left;
            }
            span{
              @include media($respo-table-break){
                position: absolute;
                left:0;
                width: 0.6rem;
                height:3.8rem;
                top:50%;
                margin-top:-1.9rem;
              }
            }
            &:before{
                content:attr(data-th);
                display:block;
                text-align:left;
                font-family:$font-family !important;
                margin:0 1.5rem 0 0;
                line-height: 1.3;
                @include media($respo-table-break){
                    display:none;
                }
            }
            &:after{
              content:"";
              width: 2px;
              top:0;
              right:0;
              position: absolute;
              height: 100%;
            }
            &:last-child{
              text-align: center;
              @include justify-content(center);
              &:before{
                display: none;
              }
              &:after{
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.logo-carousel{
  @include slick-arrows;
}

.logo-carousel .slick-arrow.slick-prev:before{
  position: relative;
  left:-11px;
}

.logo-item-box {
  height: 30rem;
  padding: 2rem 6rem;
  outline: none!important;
}
.logo-item{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex: 0 0 auto;
  filter: grayscale(100%);
  @include transition;
  @include opacity(0.5);
  &:hover{
    filter: grayscale(0);
    @include opacity(1);
  }
  img {
    flex: 0 0 auto;
  }
}

.ico-card-item{
  width: 100%;
  height: 100%;
  display: block;
  padding: 3rem;
  //box-shadow: 0 2rem 7rem rgba(0,0,0,0.07);
  color: $base-black;
  &:hover,
  &:focus{
    text-decoration: none;
    color: $base-black;
    .ico-card-item-icon img{
      @include transform(scale(1.075));
    }
  }
  &-icon{
    height: 60px;
    img{
      max-height: 100%;
      @include transition;
    }
  }
  &-title{
    padding-top: 3rem;
    padding-bottom: 2rem;
  }
}

.icons-bgr{
  background: rgba(#eeeeee,0.25);
}

.icons-row{
  max-width: 108rem;
  margin: auto!important;
  .col-xs-12{
    @include media(md){
      padding-right: 2rem;
      padding-left: 2rem;
    }
    &:nth-child(2){
      @include media(md){
        border-left: 1px solid rgba(#eeeeee,0.75);
        border-right: 1px solid rgba(#eeeeee,0.75);
      }
    }
  }
}

.text-container.files,
.text-container.citation{
  padding: 0;
}

.notification-files{
  &-item{
    padding-bottom: 3rem;
    @include flex-block;
  }
}

.citation-item{
  margin: 0 -15px;
  @include media(md){
    margin: 0 -4.5rem;
  }
  @include media(xxl){
    margin: 0 -9rem;
  }
  &-inner{
    padding: 9rem 15px 3.5rem;
    position: relative;
    @include media(md){
      padding: 9rem 4.5rem 3.5rem;
    }
    @include media(xxl){
      padding: 3.5rem 9rem;
    }
    &:before{
      content: "\e928";
      font-size: 3.5rem;
      @include opacity(0.15);
      position: absolute;
      top: 3.5rem;
      left: 3rem;
    }
    &-text{
      font-style: italic;
      @include pux-scale-with-min("font-size", 20px, 16px);
      @include pux-scale-with-min("line-height", 28px, 20px);
      padding-bottom: 3rem;
    }
    &-author{
      @include flex-block;
      @include align-items(center);
      &-image{
        width: 50px;
        height: 50px;
        background-position: center center !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        @include border-radius(50%);
        margin-right: 20px;
      }
      &-content{
        &-name{
          @include pux-scale-with-min("font-size", $font-size-perex, $min-font-size-perex);
          @include pux-scale-with-min("line-height", $line-height-perex, $min-line-height-perex);
          font-weight: 700;
        }
        &-desc{
          text-transform: uppercase;
          letter-spacing: 1.05px;
        }
      }
    }
  }
}

.timeline-box{
  padding: 17rem 0 22rem;
  margin: auto;
  max-width: 830px;
  position: relative;
  &:before{
    content: "";
    width: 4px;
    top: 11rem;
    bottom: 16rem;
    left: 18px;
    position: absolute;
    @include media(lg){
      left: 50%;
      @include transform(translateX(-50%));
    }
  }
  &:after{
    content: "";
    width: 4px;
    height: 200px;
    bottom: 16rem;
    left: 18px;
    position: absolute;
    z-index: 1;
    @include media(lg){
      left: 50%;
      @include transform(translateX(-50%));
      height: 300px;
    }
  }
  &-start,
  &-end{
    width: 6rem;
    height: 6rem;
    @include border-radius(50%);
    position: absolute;
    left: 20px;
    @include transform(translateX(-50%));
    @include flex-block;
    @include align-items(center);
    @include justify-content(center);
    @include media(lg){
      left: 50%;
      width: 50px;
      height: 50px;
    }
  }
  &-start{
    top: 7rem;
    &:after{
      content: "\e92a";
      font-size: 3rem;
      @include transform(translateX(-1px));
      @include media(lg){
        font-size: 30px;
        @include transform(translateX(-1.5px));
      }
    }
  }
  &-end{
    bottom: 11rem;
    &:after{
      content: "\e929";
      font-size: 3rem;
      @include transform(translateX(1px));
      @include media(lg){
        font-size: 30px;
        @include transform(translateX(2.5px));
      }
    }
  }
}

.timeline-item{
  @include flex-block;
  @include flex-wrap(wrap);
  padding: 3rem 0;
  &-image{
    width: 100%;
    padding-left: 10rem;
    @include media(lg){
      width: 50%;
      order: 2;
    }
    &-wrapper{
      height: 245px;
      width: 100%;
      position: relative;
      &-bgr{
        background-position: center center !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }
  }
  &-content{
    width: 100%;
    padding-left: 6.5rem;
    position: relative;
    @include media(lg){
      width: 50%;
      order: 1;
      padding-right: 6.5rem;
      padding-left: 0;
      text-align: right;
    }
    &-title{
      padding-top: 2rem;
      @include media(lg){
        padding-top: 1rem;
      }
    }
    &:after{
      content: "";
      position: absolute;
      border: 5px solid #ffffff;
      @include border-radius(50%);
      width: 30px;
      height: 30px;
      left: 18px;
      top: 2rem;
      z-index: 2;
      @include transform(translate(-13px, -5px));
      @include media(lg){
        right: 0;
        top: 1rem;
        left: auto;
        @include transform(translate(50%, 0px));
      }
    }
    &-perex{
      @include transition(500ms);
      &.collapsed{
        max-height: 145px;
        overflow: hidden;
      }
      &.collapsed.open{
        max-height: 2000px;
      }
    }
    &-btn{
      padding-top: 1.5rem;
      @include flex-block;
      @include align-items(center);
      @include justify-content(flex-end);
      cursor: pointer;
      &.hidden{
        display: none;
      }
      &:after{
        content: "\e901";
        font-size: 4.5px;
        margin-left: 5px;
        @include transition;
      }
      &.open{
        &:after{
          @include rotate(180deg);
        }
      }
    }
  }
  &.reverse{
    .timeline-item-image{
      order: 1;
      @include media(lg){
        padding-left: 0;
        padding-right: 6.5rem;
        text-align: left;
      }
    }
    .timeline-item-content{
      order: 2;
      @include media(lg){
        padding-left: 6.5rem;
        padding-right: 0;
        text-align: left;
      }
      &:after{
        @include media(lg){
          right: auto;
          top: 1rem;
          left: 0;
          @include transform(translate(-50%, 0px));
        }
      }
    }
  }
}

.product-item{
  width: 100%;
  min-height: 240px;
  height: 100%;
  position: relative;
  padding: 100px 6rem 0;
  overflow: hidden;
  @include media(md){
    min-height: 30rem;
    padding: 12rem 6rem 0;
  }
  &:hover,
  &:focus{
    text-decoration: none;
    .product-item-inner-btn{
      @include transform(translateY(0));
    }
    .product-item-bgr{
      @include transform(scale(1.1));
    }
  }
  &-bgr{
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    @include transition;
  }
  &-inner{
    @include flex-block;
    @include flex-direction(column);
    @include justify-content(space-between);
    @include align-items(center);
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 2;
    &-title{
      padding: 1rem 3rem;
      margin: 0;
    }
    &-btn{
      padding: 1.5rem 3rem 2.5rem;
      text-transform: uppercase;
      letter-spacing: 2.8px;
      font-size: 16px;
      font-weight: 700;
      @include transform(translateY(1rem));
      @include transition;
    }
  }
  &-labels{
    position: absolute;
    top: 1rem;
    left: 1rem;
    .category-label{
      padding: 2.5px 5px;
      display: inline-block;
      margin: 1px;
      @include border-radius(4px);
      font-size: 16px;
      text-transform: uppercase;
      letter-spacing: 1.5px;
    }
  }
}

.filter-row{
  @include flex-block;
  @include flex-wrap(wrap);
  margin: 0 -10px;
}

.checkbox-filter{
  padding: 10px;
}

.product-detail-row{
  @include flex-block;
  @include flex-wrap(wrap);
  padding: 10rem 0 0;
  &-image{
    width: 100%;
    min-height: 300px;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    margin-bottom: 3rem;
    @include media(lg){
      width: 40%;
      min-height: 40rem;
      margin-bottom: 0;
    }
  }
  &-content{
    width: 100%;
    @include media(lg){
      width: 60%;
      padding-left: 6rem;
    }
    &-perex{
      padding-bottom: 3rem;
    }
    &-labels{
      margin: 0 -1rem;
      .category-label{
        padding: 1rem 2rem;
        display: inline-block;
        margin: 1rem;
        @include border-radius(4px);
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 16px;
      }
    }
  }
}

.no-before{
  &:before{
    display: none!important;
  }
}

.row.products{
  min-height: 240px;
  @include media(md){
    min-height: 30rem;
  }
}

.row.product-gallery{
  justify-content: flex-start;
  margin: 0 -7.5px;
  [class^="col-"]{
    padding: 7.5px;
  }
}

.row.row-small{
  margin: 0 -7.5px;
}

.text-container.product{
  text-align: center;
  .btn{
    margin: 10px;
  }
}

.page-map{
  display: flex;
  flex-wrap: wrap;
  margin:0 auto;
  max-width: 1000px;
  &-column{
    padding:0 2rem 3rem 2rem;
    width: 33.33%;
    & > ul{
      & >li{
        padding:0;
        width: 100%;
        margin:0 0 2rem 0;;
        &:before{
          display: none;
        }
        & >span,
        & >a{
          color:#000;
          position: relative;
          &:after{
            content:"";
            width: 100%;
            height: 1px;
            display: block;
            position: absolute;
            left:0;
            bottom:0;
          }
          &:focus,
          &:hover{
            text-decoration: none;
          }
        }
        & > ul{
          display: block;
          margin:3px 0 0 0;
          & >li{
            width: 100%;
            margin:5px 0;
            & >a{
              color:#000;
              position: relative;
              &:after{
                content:"";
                width: 100%;
                height: 1px;
                display: block;
                position: absolute;
                left:0;
                bottom:0;
              }
              &:focus,
              &:hover{
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes slideDown{
  from {
    height: calc(auto - 10px);

  }
  to {
    height: auto;
  }
}

.collapsible-item {
  display: flex;
  width: 100%;
  max-width: 78rem;
  margin: 0 auto;
  padding: 0;
  box-shadow: 0px 0px 16px -2px rgba(0,0,0,0.2);
  border-width: 1px;
  border-style: solid;
  border-bottom: none;
  border-color: $base-grey;
  &.with-numbers {
    .collapsible-item-heading {
      padding: 0 2.5rem 0 0;
    }
  }
  &-heading {
    width: 100%;
    padding: 2.5rem;
    cursor: pointer;
    display: flex;
    background-color: $base-white;
    &:after {
      content: '\e901';
      font-size: 13px;
      transition: 0.3s ease all;
      margin-left: auto;
      display: flex;
      align-items: center;
    }
    h2{
      display: flex;
      align-items: center;
      padding:1rem 0;
    }
    h2,
    .collapsible-item-index {
      margin: 0 1.5rem 0 0;
      line-height: 1;
      font-weight: 700;
      text-transform: none;
      @include pux-scale-with-min('font-size', 24px, 22px);
    }
    .collapsible-item-index {
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      width: 50px;
      min-width: 50px;
      min-height: 50px;
      @include media(md) {
        width: 60px;
        min-width: 60px;
        min-height: 60px;
      }
    }
  }
  &-container {
    width: 100%;
    overflow: hidden;
    .collapsible-item-text  {
      transition: 0.3s ease-in-out opacity;
      height: 0;
      opacity: 0;
    }
    &.open {
        height: auto;
      .collapsible-item-text {
        border-top: 1px solid $base-grey;
        height: auto;
        opacity: 1;
        padding: 1rem 2.5rem 2.5rem 2.5rem
      }
      .collapsible-item-heading:after {
        transform: rotate(180deg);
      }
    }
  }
  &-controller {
    width: 7.5rem;
    position: relative;
    cursor: pointer;
    .close, .open {
      font-size: 30px;
      font-weight: 800;
      color: $main-color;
      text-align: center;
      opacity: 1;
      transform: translate(-50%,-50%);
      position: absolute;
      top: 3.7rem;
      left: 50%;
    }
    .close {
      display: inline-block;
    }
    .open {
      display: none;
    }
    &.open {
      .close {
        display: none;
      }
      .open {
        display: inline-block;
      }
    }
  }
  &:last-child{
    border-bottom: 1px solid $base-grey;
  }
}


.smaller-card{
  .card-item-label{
    padding:1rem 2rem;
  }
  .card-item-content{
    padding:1.5rem 3rem;
    margin-bottom: 3rem;
  }
}
