@charset "UTF-8";
@font-face {
  font-family: 'icomoon';
  src: url("fonts/icomoon.eot?f272cl");
  src: url("fonts/icomoon.eot?f272cl#iefix") format("embedded-opentype"), url("fonts/icomoon.ttf?f272cl") format("truetype"), url("fonts/icomoon.woff?f272cl") format("woff"), url("fonts/icomoon.svg?f272cl#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-accessibility:before {
  content: "\e92d"; }

.icon-address:before {
  content: "\e92e"; }

.icon-email:before {
  content: "\e92f"; }

.icon-gdpr:before {
  content: "\e930"; }

.icon-phone:before {
  content: "\e931"; }

.icon-place:before {
  content: "\e932"; }

.icon-signpost:before {
  content: "\e933"; }

.icon-sitemap:before {
  content: "\e934"; }

.icon-zoom-in:before {
  content: "\e923"; }

.icon-zoom-out:before {
  content: "\e924"; }

.icon-rtf:before {
  content: "\e925"; }

.icon-doc1:before {
  content: "\e926"; }

.icon-file-transfer:before {
  content: "\e927"; }

.icon-docx:before {
  content: "\e920"; }

.icon-xls:before {
  content: "\e921"; }

.icon-ppt:before {
  content: "\e922"; }

.icon-akce:before {
  content: "\e91a"; }

.icon-fotky:before {
  content: "\e91b"; }

.icon-kontakt:before {
  content: "\e91c"; }

.icon-o_nas:before {
  content: "\e91d"; }

.icon-upozorneni:before {
  content: "\e91e"; }

.icon-sluzby:before {
  content: "\e91f"; }

.icon-360:before {
  content: "\e900";
  color: #f8b620; }

.icon-arrow-big:before {
  content: "\e901";
  color: #f8b620; }

.icon-arrow-small:before {
  content: "\e902";
  color: #f8b620; }

.icon-play:before {
  content: "\e903";
  color: #f8b620; }

.icon-zastupni-ikona:before {
  content: "\e904";
  color: #f8b620; }

.icon-man:before {
  content: "\e905"; }

.icon-woman:before {
  content: "\e906"; }

.icon-ze_zivota_domova:before {
  content: "\e907"; }

.icon-service:before {
  content: "\e908"; }

.icon-past_event:before {
  content: "\e909"; }

.icon-o_nas1:before {
  content: "\e90a"; }

.icon-notification:before {
  content: "\e90b"; }

.icon-kontakt1:before {
  content: "\e90c"; }

.icon-homepage:before {
  content: "\e90d"; }

.icon-event:before {
  content: "\e90e"; }

.icon-googleplus:before {
  content: "\e90f"; }

.icon-instagram:before {
  content: "\e910"; }

.icon-facebook:before {
  content: "\e911"; }

.icon-linkedin:before {
  content: "\e912"; }

.icon-pinterest:before {
  content: "\e913"; }

.icon-twitter:before {
  content: "\e914"; }

.icon-vimeo:before {
  content: "\e915"; }

.icon-youtube:before {
  content: "\e916"; }

.icon-doc:before {
  content: "\e917"; }

.icon-jpg:before {
  content: "\e919"; }

.icon-pdf:before {
  content: "\e918"; }

.icon-external-link:before {
  content: "\e92c"; }

.icon-right-quote-sign:before {
  content: "\e928"; }

.icon-timeline-hodiny-zlute:before {
  content: "\e929";
  color: #f8b620; }

.icon-timeline-hodiny-bile:before {
  content: "\e92a";
  color: #fff; }

.icon-envelope:before {
  content: "\e92b"; }

.pux-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  max-width: 162rem; }
  .pux-container:after {
    display: block;
    content: "";
    clear: both; }
  @media (min-width: 768px) {
    .pux-container {
      padding-left: 4.5rem;
      padding-right: 4.5rem; } }
  .pux-container .pux-container {
    padding-left: 0;
    padding-right: 0; }

.pux-container .row {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px; }
  @media (min-width: 768px) {
    .pux-container .row {
      margin-left: -4.5rem;
      margin-right: -4.5rem; } }

.pux-container .col-1, .pux-container .col-2, .pux-container .col-3, .pux-container .col-4, .pux-container .col-5, .pux-container .col-6, .pux-container .col-7, .pux-container .col-8, .pux-container .col-9, .pux-container .col-10, .pux-container .col-11, .pux-container .col-12, .pux-container .col,
.pux-container .col-xs-1, .pux-container .col-xs-2, .pux-container .col-xs-3, .pux-container .col-xs-4, .pux-container .col-xs-5, .pux-container .col-xs-6, .pux-container .col-xs-7, .pux-container .col-xs-8, .pux-container .col-xs-9, .pux-container .col-xs-10, .pux-container .col-xs-11, .pux-container .col-xs-12,
.pux-container .col-auto, .pux-container .col-sm-1, .pux-container .col-sm-2, .pux-container .col-sm-3, .pux-container .col-sm-4, .pux-container .col-sm-5, .pux-container .col-sm-6, .pux-container .col-sm-7, .pux-container .col-sm-8, .pux-container .col-sm-9, .pux-container .col-sm-10, .pux-container .col-sm-11, .pux-container .col-sm-12, .pux-container .col-sm,
.pux-container .col-sm-auto, .pux-container .col-md-1, .pux-container .col-md-2, .pux-container .col-md-3, .pux-container .col-md-4, .pux-container .col-md-5, .pux-container .col-md-6, .pux-container .col-md-7, .pux-container .col-md-8, .pux-container .col-md-9, .pux-container .col-md-10, .pux-container .col-md-11, .pux-container .col-md-12, .pux-container .col-md,
.pux-container .col-md-auto, .pux-container .col-lg-1, .pux-container .col-lg-2, .pux-container .col-lg-3, .pux-container .col-lg-4, .pux-container .col-lg-5, .pux-container .col-lg-6, .pux-container .col-lg-7, .pux-container .col-lg-8, .pux-container .col-lg-9, .pux-container .col-lg-10, .pux-container .col-lg-11, .pux-container .col-lg-12, .pux-container .col-lg,
.pux-container .col-lg-auto, .pux-container .col-xl-1, .pux-container .col-xl-2, .pux-container .col-xl-3, .pux-container .col-xl-4, .pux-container .col-xl-5, .pux-container .col-xl-6, .pux-container .col-xl-7, .pux-container .col-xl-8, .pux-container .col-xl-9, .pux-container .col-xl-10, .pux-container .col-xl-11, .pux-container .col-xl-12, .pux-container .col-xl,
.pux-container .col-xl-auto {
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 768px) {
    .pux-container .col-1, .pux-container .col-2, .pux-container .col-3, .pux-container .col-4, .pux-container .col-5, .pux-container .col-6, .pux-container .col-7, .pux-container .col-8, .pux-container .col-9, .pux-container .col-10, .pux-container .col-11, .pux-container .col-12, .pux-container .col,
    .pux-container .col-xs-1, .pux-container .col-xs-2, .pux-container .col-xs-3, .pux-container .col-xs-4, .pux-container .col-xs-5, .pux-container .col-xs-6, .pux-container .col-xs-7, .pux-container .col-xs-8, .pux-container .col-xs-9, .pux-container .col-xs-10, .pux-container .col-xs-11, .pux-container .col-xs-12,
    .pux-container .col-auto, .pux-container .col-sm-1, .pux-container .col-sm-2, .pux-container .col-sm-3, .pux-container .col-sm-4, .pux-container .col-sm-5, .pux-container .col-sm-6, .pux-container .col-sm-7, .pux-container .col-sm-8, .pux-container .col-sm-9, .pux-container .col-sm-10, .pux-container .col-sm-11, .pux-container .col-sm-12, .pux-container .col-sm,
    .pux-container .col-sm-auto, .pux-container .col-md-1, .pux-container .col-md-2, .pux-container .col-md-3, .pux-container .col-md-4, .pux-container .col-md-5, .pux-container .col-md-6, .pux-container .col-md-7, .pux-container .col-md-8, .pux-container .col-md-9, .pux-container .col-md-10, .pux-container .col-md-11, .pux-container .col-md-12, .pux-container .col-md,
    .pux-container .col-md-auto, .pux-container .col-lg-1, .pux-container .col-lg-2, .pux-container .col-lg-3, .pux-container .col-lg-4, .pux-container .col-lg-5, .pux-container .col-lg-6, .pux-container .col-lg-7, .pux-container .col-lg-8, .pux-container .col-lg-9, .pux-container .col-lg-10, .pux-container .col-lg-11, .pux-container .col-lg-12, .pux-container .col-lg,
    .pux-container .col-lg-auto, .pux-container .col-xl-1, .pux-container .col-xl-2, .pux-container .col-xl-3, .pux-container .col-xl-4, .pux-container .col-xl-5, .pux-container .col-xl-6, .pux-container .col-xl-7, .pux-container .col-xl-8, .pux-container .col-xl-9, .pux-container .col-xl-10, .pux-container .col-xl-11, .pux-container .col-xl-12, .pux-container .col-xl,
    .pux-container .col-xl-auto {
      padding-left: 4.5rem;
      padding-right: 4.5rem; } }

.pux-fluid-container {
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 768px) {
    .pux-fluid-container {
      padding-left: 4.5rem;
      padding-right: 4.5rem; } }
  @media (min-width: 1200px) {
    .pux-fluid-container {
      padding-left: 12rem;
      padding-right: 12rem; } }

.small-container {
  max-width: 1099px;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 768px) {
    .small-container {
      padding-left: 4.5rem;
      padding-right: 4.5rem; } }

.pux-container .small-container {
  max-width: 1000px;
  padding-left: 0;
  padding-right: 0; }

header {
  position: fixed;
  width: 100%;
  z-index: 8888;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  header .header {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: space-between;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    height: 8rem; }
    @media (min-width: 320px) and (max-width: 1199px) {
      header .header {
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center; } }
    @media (min-width: 992px) {
      header .header {
        height: 7rem; } }
    header .header-logo {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center; }
      header .header-logo a {
        display: inline-block; }
        header .header-logo a span {
          display: none; }
        header .header-logo a img {
          max-width: none;
          height: 5rem !important;
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms; }
    header .header-menu {
      display: none;
      position: absolute;
      top: 100%;
      z-index: 77;
      left: 0;
      width: 100%;
      max-height: -webkit-calc(100vh - 5rem);
      max-height: -moz-calc(100vh - 5rem);
      max-height: calc(100vh - 5rem);
      overflow-y: auto;
      min-height: 12.5rem; }
      @media (min-width: 992px) {
        header .header-menu {
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          position: static;
          background: none;
          width: 100%;
          min-height: 7rem;
          overflow-y: visible;
          justify-content: flex-end;
          align-self: stretch; } }
      header .header-menu ul {
        margin: 0;
        padding: 0;
        list-style: none; }
        @media (min-width: 992px) {
          header .header-menu ul {
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex; } }
        header .header-menu ul li {
          display: block;
          position: relative;
          margin: 2rem 0;
          outline: none; }
          @media (min-width: 992px) {
            header .header-menu ul li {
              margin: 0;
              display: -ms-flexbox;
              display: -webkit-flex;
              display: flex;
              -webkit-box-align: center;
              -moz-box-align: center;
              -ms-flex-align: center;
              -webkit-align-items: center;
              align-items: center; } }
          @media (min-width: 1200px) {
            header .header-menu ul li {
              margin: 0 0.5rem; } }
          @media (min-width: 1400px) {
            header .header-menu ul li {
              margin: 0 0.5rem; } }
          header .header-menu ul li span:not(.menu-openner),
          header .header-menu ul li a {
            display: block;
            padding: 1.5rem 1rem; }
            @media (min-width: 1200px) {
              header .header-menu ul li span:not(.menu-openner),
              header .header-menu ul li a {
                padding: 1.5rem 1.5rem 1.5rem 1rem; } }
            @media (min-width: 1400px) {
              header .header-menu ul li span:not(.menu-openner),
              header .header-menu ul li a {
                padding: 1.5rem 1.5rem; } }
            header .header-menu ul li span:not(.menu-openner):focus, header .header-menu ul li span:not(.menu-openner):hover,
            header .header-menu ul li a:focus,
            header .header-menu ul li a:hover {
              text-decoration: none; }
          header .header-menu ul li span {
            outline: none; }
          header .header-menu ul li span:not(.menu-openner) {
            cursor: default; }
          header .header-menu ul li.menu-360 {
            display: flex; }
            header .header-menu ul li.menu-360:before {
              content: "\e900";
              align-self: center;
              width: 30px;
              margin-left: 15px; }
              @media (min-width: 768px) {
                header .header-menu ul li.menu-360:before {
                  margin-left: 4.5rem; } }
              @media (min-width: 992px) {
                header .header-menu ul li.menu-360:before {
                  width: auto;
                  margin-left: 3px;
                  display: none; } }
              @media (min-width: 1200px) {
                header .header-menu ul li.menu-360:before {
                  display: block; } }
          header .header-menu ul li ul {
            display: none;
            width: 100%;
            opacity: 1;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
            filter: alpha(opacity=100);
            visibility: visible;
            -moz-transition: all ease-in-out 200ms;
            -o-transition: all ease-in-out 200ms;
            -webkit-transition: all ease-in-out 200ms;
            transition: all ease-in-out 200ms; }
            @media (min-width: 992px) {
              header .header-menu ul li ul {
                position: absolute;
                top: 100%;
                width: 25rem;
                left: 50%;
                right: 50%;
                transform: translateX(-11.5rem);
                visibility: hidden;
                opacity: 0;
                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
                filter: alpha(opacity=0);
                display: block;
                padding: 1rem 0rem; } }
            @media (min-width: 992px) {
              header .header-menu ul li ul li {
                margin: 0 !important; } }
            header .header-menu ul li ul a {
              padding-left: 15px;
              padding-right: 15px; }
              @media (min-width: 768px) {
                header .header-menu ul li ul a {
                  padding-left: 4.5rem;
                  padding-right: 4.5rem; } }
              @media (min-width: 992px) {
                header .header-menu ul li ul a {
                  padding: 1rem 1.5rem;
                  display: block;
                  width: 100%; } }
              @media (min-width: 1200px) {
                header .header-menu ul li ul a {
                  padding: 1rem 2.5rem; } }
          @media (min-width: 992px) {
            header .header-menu ul li.scroll ul {
              overflow: auto; }
              header .header-menu ul li.scroll ul::-webkit-scrollbar {
                width: 1em; }
              header .header-menu ul li.scroll ul::-webkit-scrollbar-button:start:decrement, header .header-menu ul li.scroll ul::-webkit-scrollbar-button:end:increment {
                display: block;
                border-style: solid;
                height: 13px;
                width: 16px; }
              header .header-menu ul li.scroll ul::-webkit-scrollbar-thumb {
                border-top: 2px;
                border-bottom: 2px; }
              header .header-menu ul li.scroll ul::-webkit-scrollbar-button:start:decrement {
                border-width: 0 7px 7px 7px; }
              header .header-menu ul li.scroll ul::-webkit-scrollbar-button:end:increment {
                border-width: 7px 7px 0 7px; } }
          header .header-menu ul li:hover > ul, header .header-menu ul li:focus > ul, header .header-menu ul li.focused > ul {
            visibility: visible;
            opacity: 1;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
            filter: alpha(opacity=100); }
          header .header-menu ul li.open .menu-openner:after {
            -ms-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
          header .header-menu ul li.open ul {
            display: block; }
          @media (min-width: 992px) {
            header .header-menu ul li.has-ul {
              margin: 0 0.5rem 0 0; } }
          @media (min-width: 1200px) {
            header .header-menu ul li.has-ul {
              margin: 0 0.5rem; } }
          @media (min-width: 1400px) {
            header .header-menu ul li.has-ul {
              margin: 0 0.5rem; } }
          @media (min-width: 992px) {
            header .header-menu ul li.has-ul span:not(.menu-openner),
            header .header-menu ul li.has-ul a {
              padding: 1.5rem 2rem 1.5rem 1rem; } }
          @media (min-width: 1200px) {
            header .header-menu ul li.has-ul span:not(.menu-openner),
            header .header-menu ul li.has-ul a {
              padding: 1.5rem 2rem 1.5rem 1.5rem; } }
      @media (min-width: 992px) {
        header .header-menu > ul {
          height: 100%;
          justify-content: flex-end; } }
      header .header-menu > ul > li:last-child {
        margin-right: 0; }
        @media (min-width: 992px) {
          header .header-menu > ul > li:last-child a {
            padding-right: 0; } }
      header .header-menu .menu-openner {
        position: absolute;
        top: 0;
        right: 15px;
        width: 6rem;
        height: 6rem; }
        @media (min-width: 768px) {
          header .header-menu .menu-openner {
            right: 4.5rem;
            width: 5rem;
            height: 5rem; } }
        @media (min-width: 992px) {
          header .header-menu .menu-openner {
            position: absolute;
            width: auto;
            height: auto;
            background: transparent;
            bottom: .5rem;
            right: 1rem; } }
        header .header-menu .menu-openner:after, header .header-menu .menu-openner:before {
          content: "";
          width: 70%;
          height: 2px;
          position: absolute;
          left: 15%;
          top: 50%;
          margin-top: -1px; }
          @media (min-width: 992px) {
            header .header-menu .menu-openner:after, header .header-menu .menu-openner:before {
              content: "\e902";
              background: transparent;
              height: auto;
              width: auto;
              font-size: 6px; } }
        header .header-menu .menu-openner:after {
          -ms-transform: rotate(90deg);
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms; }
          @media (min-width: 992px) {
            header .header-menu .menu-openner:after {
              display: none; } }
      header .header-menu .menu-openner-full-width {
        position: absolute;
        width: 100%;
        height: 100%;
        display: block; }
    header .header-hamburger {
      margin-right: -5px; }
      @media (min-width: 768px) {
        header .header-hamburger {
          margin-right: -2px; } }
      @media (min-width: 992px) {
        header .header-hamburger {
          display: none; } }
      header .header-hamburger .open-menu {
        width: 6rem;
        height: 6rem;
        position: relative;
        display: block; }
        @media (min-width: 768px) {
          header .header-hamburger .open-menu {
            width: 5rem; } }
        header .header-hamburger .open-menu span {
          width: 5rem;
          position: absolute;
          left: 0.5rem;
          height: 3px;
          margin-top: -1.5px;
          top: 50%;
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms; }
          header .header-hamburger .open-menu span:before, header .header-hamburger .open-menu span:after {
            content: "";
            width: 5rem;
            left: 0;
            height: 3px;
            position: absolute;
            -moz-transition: all ease-in-out 200ms;
            -o-transition: all ease-in-out 200ms;
            -webkit-transition: all ease-in-out 200ms;
            transition: all ease-in-out 200ms; }
          header .header-hamburger .open-menu span:before {
            top: -8px; }
          header .header-hamburger .open-menu span:after {
            bottom: -8px; }
    header .header.open .header-hamburger .open-menu span {
      background: transparent; }
      header .header.open .header-hamburger .open-menu span:before {
        top: 0;
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg); }
      header .header.open .header-hamburger .open-menu span:after {
        top: 0;
        -ms-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg); }
    header .header.open .header-menu {
      display: block; }

.EditMode header,
.DesignMode header {
  position: static; }

footer {
  border-top: 1px solid #f1f1f1; }
  @media (min-width: 320px) {
    footer {
      font-size: 3.2rem; } }
  @media (min-width: 480px) {
    footer {
      font-size: 2.90909rem; } }
  @media (min-width: 545px) {
    footer {
      font-size: 2.66667rem; } }
  @media (min-width: 768px) {
    footer {
      font-size: 2.13333rem; } }
  @media (min-width: 992px) {
    footer {
      font-size: 2rem; } }
  @media (min-width: 1200px) {
    footer {
      font-size: 1.8rem; } }
  @media (min-width: 320px) {
    footer {
      line-height: 5rem; } }
  @media (min-width: 480px) {
    footer {
      line-height: 4.54545rem; } }
  @media (min-width: 545px) {
    footer {
      line-height: 4.16667rem; } }
  @media (min-width: 768px) {
    footer {
      line-height: 3.33333rem; } }
  @media (min-width: 992px) {
    footer {
      line-height: 3.125rem; } }
  @media (min-width: 1200px) {
    footer {
      line-height: 2.77778rem; } }
  @media (min-width: 1400px) {
    footer {
      line-height: 2.5rem; } }
  footer .footer-top {
    line-height: 1.8;
    padding: 6rem 0 2rem 0; }
    footer .footer-top div[class^=col] {
      padding: 0 2rem; }
    footer .footer-top.row {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
      -moz-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      padding-left: 0;
      padding-right: 0;
      margin-left: -2rem;
      margin-right: -2rem; }
    footer .footer-top div[class^=col].row {
      padding: 0;
      margin: 0; }
    footer .footer-top a {
      display: block;
      text-decoration: underline;
      font-weight: 700; }
      footer .footer-top a:focus, footer .footer-top a:hover {
        text-decoration: none; }
    footer .footer-top .footer-links > a,
    footer .footer-top .footer-map-link,
    footer .footer-top .insert-phone,
    footer .footer-top .insert-mail {
      display: block;
      position: relative;
      padding-left: 25px; }
      footer .footer-top .footer-links > a:before,
      footer .footer-top .footer-map-link:before,
      footer .footer-top .insert-phone:before,
      footer .footer-top .insert-mail:before {
        position: absolute;
        left: 0;
        top: calc(50% - 0.5em);
        text-decoration: underline;
        display: inline-block; }
      footer .footer-top .footer-links > a::before, footer .footer-top .footer-links > a::before:hover,
      footer .footer-top .footer-map-link::before,
      footer .footer-top .footer-map-link::before:hover,
      footer .footer-top .insert-phone::before,
      footer .footer-top .insert-phone::before:hover,
      footer .footer-top .insert-mail::before,
      footer .footer-top .insert-mail::before:hover {
        text-decoration: none; }
    footer .footer-top .footer-contacts {
      margin: 0 0 2rem 0; }
      @media (min-width: 1400px) {
        footer .footer-top .footer-contacts {
          margin: 4rem 0 2rem 0; } }
    footer .footer-top .col-12.footer-logo {
      margin-top: 2rem; }
      @media (min-width: 1200px) {
        footer .footer-top .col-12.footer-logo {
          margin-top: 0; } }
      footer .footer-top .col-12.footer-logo img {
        max-width: 150px; }
    footer .footer-top .col-12.footer-map {
      padding-top: 3rem; }
      @media (min-width: 768px) {
        footer .footer-top .col-12.footer-map {
          padding-top: 0;
          padding-left: 0;
          padding-right: 0; } }
      footer .footer-top .col-12.footer-map img {
        max-width: 150px; }
    @media (min-width: 545px) {
      footer .footer-top .col-12.footer-map {
        padding-top: 0; } }
  footer .footer-bottom {
    padding: 3rem 0 2.5rem 0; }
    @media (min-width: 320px) {
      footer .footer-bottom {
        font-size: 3rem; } }
    @media (min-width: 480px) {
      footer .footer-bottom {
        font-size: 2.72727rem; } }
    @media (min-width: 545px) {
      footer .footer-bottom {
        font-size: 2.5rem; } }
    @media (min-width: 768px) {
      footer .footer-bottom {
        font-size: 2rem; } }
    @media (min-width: 992px) {
      footer .footer-bottom {
        font-size: 1.875rem; } }
    @media (min-width: 1200px) {
      footer .footer-bottom {
        font-size: 1.7rem; } }
    footer .footer-bottom div[class^=col] {
      display: flex;
      align-items: center;
      flex-wrap: wrap; }
    @media (min-width: 768px) {
      footer .footer-bottom div[class^=col]:last-child {
        justify-content: flex-end;
        flex-wrap: nowrap;
        white-space: nowrap; } }
  footer .footer-address-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 2rem; }
  footer .footer-address {
    padding-left: 25px;
    display: block;
    position: relative;
    padding-left: 25px;
    flex-shrink: 1; }
    footer .footer-address-box {
      max-width: 100%;
      flex: 100%;
      padding-right: 2rem; }
      @media (min-width: 480px) {
        footer .footer-address-box {
          flex: auto;
          flex-grow: 0; } }
    footer .footer-address:before {
      position: absolute;
      left: 0;
      top: 0.3em;
      display: inline-block;
      content: "\e92e"; }
  footer .footer-map {
    max-width: 100%;
    flex: 100%; }
    @media (min-width: 480px) {
      footer .footer-map {
        flex: auto;
        flex-grow: 1; } }
  footer .footer-social {
    margin: 2rem 0 2rem 0; }
    footer .footer-social ul {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      margin: 0;
      padding: 0;
      list-style: none; }
      footer .footer-social ul li {
        padding: 0 1.5rem 0 0; }
        footer .footer-social ul li:before {
          display: none; }
        footer .footer-social ul li a {
          position: relative;
          font-size: 0;
          display: block;
          text-decoration: none;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          border-radius: 50%;
          background: #f8b620;
          border: none;
          color: #8bb84d;
          text-align: center;
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms; }
          @media (min-width: 320px) {
            footer .footer-social ul li a {
              width: 5rem; } }
          @media (min-width: 320px) {
            footer .footer-social ul li a {
              height: 5rem; } }
          footer .footer-social ul li a:focus, footer .footer-social ul li a:hover {
            background: #de9c07;
            color: #8bb84d;
            border: none;
            text-decoration: none; }
          footer .footer-social ul li a span {
            display: none; }
    footer .footer-social .facebook {
      background: #3b5998; }
      footer .footer-social .facebook:before {
        position: absolute;
        content: "";
        color: #ffffff;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
        @media (min-width: 320px) {
          footer .footer-social .facebook:before {
            font-size: 3rem; } }
        @media (min-width: 480px) {
          footer .footer-social .facebook:before {
            font-size: 2.72727rem; } }
        @media (min-width: 545px) {
          footer .footer-social .facebook:before {
            font-size: 2.5rem; } }
      footer .footer-social .facebook:focus, footer .footer-social .facebook:hover {
        background: #2d4373; }
      footer .footer-social .facebook:focus, footer .footer-social .facebook:hover {
        color: #3b5998;
        border-color: #3b5998; }
    footer .footer-social .twitter {
      background: #00aced; }
      footer .footer-social .twitter:before {
        position: absolute;
        content: "";
        color: #ffffff;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
        @media (min-width: 320px) {
          footer .footer-social .twitter:before {
            font-size: 3rem; } }
        @media (min-width: 480px) {
          footer .footer-social .twitter:before {
            font-size: 2.72727rem; } }
        @media (min-width: 545px) {
          footer .footer-social .twitter:before {
            font-size: 2.5rem; } }
      footer .footer-social .twitter:focus, footer .footer-social .twitter:hover {
        background: #0087ba; }
      footer .footer-social .twitter:focus, footer .footer-social .twitter:hover {
        color: #00aced;
        border-color: #00aced; }
    footer .footer-social .linkedin {
      background: #0d77b7; }
      footer .footer-social .linkedin:before {
        position: absolute;
        content: "";
        color: #ffffff;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
        @media (min-width: 320px) {
          footer .footer-social .linkedin:before {
            font-size: 3rem; } }
        @media (min-width: 480px) {
          footer .footer-social .linkedin:before {
            font-size: 2.72727rem; } }
        @media (min-width: 545px) {
          footer .footer-social .linkedin:before {
            font-size: 2.5rem; } }
      footer .footer-social .linkedin:focus, footer .footer-social .linkedin:hover {
        background: #0a5887; }
      footer .footer-social .linkedin:focus, footer .footer-social .linkedin:hover {
        color: #0d77b7;
        border-color: #0d77b7; }
    footer .footer-social .youtube {
      background: #cc181e; }
      footer .footer-social .youtube:before {
        position: absolute;
        content: "";
        color: #ffffff;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
        @media (min-width: 320px) {
          footer .footer-social .youtube:before {
            font-size: 3rem; } }
        @media (min-width: 480px) {
          footer .footer-social .youtube:before {
            font-size: 2.72727rem; } }
        @media (min-width: 545px) {
          footer .footer-social .youtube:before {
            font-size: 2.5rem; } }
      footer .footer-social .youtube:focus, footer .footer-social .youtube:hover {
        background: #9e1317; }
      footer .footer-social .youtube:focus, footer .footer-social .youtube:hover {
        color: #cc181e;
        border-color: #cc181e; }
    footer .footer-social .instagram {
      background: #8a3ab9; }
      footer .footer-social .instagram:before {
        position: absolute;
        content: "";
        color: #ffffff;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
        @media (min-width: 320px) {
          footer .footer-social .instagram:before {
            font-size: 3rem; } }
        @media (min-width: 480px) {
          footer .footer-social .instagram:before {
            font-size: 2.72727rem; } }
        @media (min-width: 545px) {
          footer .footer-social .instagram:before {
            font-size: 2.5rem; } }
      footer .footer-social .instagram:focus, footer .footer-social .instagram:hover {
        background: #6d2e92; }
      footer .footer-social .instagram:focus, footer .footer-social .instagram:hover {
        color: #8a3ab9;
        border-color: #8a3ab9; }
    footer .footer-social .pinterest {
      background: #cd2029; }
      footer .footer-social .pinterest:before {
        position: absolute;
        content: "";
        color: #ffffff;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
        @media (min-width: 320px) {
          footer .footer-social .pinterest:before {
            font-size: 3rem; } }
        @media (min-width: 480px) {
          footer .footer-social .pinterest:before {
            font-size: 2.72727rem; } }
        @media (min-width: 545px) {
          footer .footer-social .pinterest:before {
            font-size: 2.5rem; } }
      footer .footer-social .pinterest:focus, footer .footer-social .pinterest:hover {
        background: #a11920; }
      footer .footer-social .pinterest:focus, footer .footer-social .pinterest:hover {
        color: #cd2029;
        border-color: #cd2029; }
    footer .footer-social .google {
      background: #d34836; }
      footer .footer-social .google:before {
        position: absolute;
        content: "";
        color: #ffffff;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
        @media (min-width: 320px) {
          footer .footer-social .google:before {
            font-size: 3rem; } }
        @media (min-width: 480px) {
          footer .footer-social .google:before {
            font-size: 2.72727rem; } }
        @media (min-width: 545px) {
          footer .footer-social .google:before {
            font-size: 2.5rem; } }
      footer .footer-social .google:focus, footer .footer-social .google:hover {
        background: #b03626; }
      footer .footer-social .google:focus, footer .footer-social .google:hover {
        color: #d34836;
        border-color: #d34836; }
    footer .footer-social .vimeo {
      background: #45bbff; }
      footer .footer-social .vimeo:before {
        position: absolute;
        content: "";
        color: #ffffff;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
        @media (min-width: 320px) {
          footer .footer-social .vimeo:before {
            font-size: 3rem; } }
        @media (min-width: 480px) {
          footer .footer-social .vimeo:before {
            font-size: 2.72727rem; } }
        @media (min-width: 545px) {
          footer .footer-social .vimeo:before {
            font-size: 2.5rem; } }
      footer .footer-social .vimeo:focus, footer .footer-social .vimeo:hover {
        background: #12a8ff; }
      footer .footer-social .vimeo:focus, footer .footer-social .vimeo:hover {
        color: #45bbff;
        border-color: #45bbff; }
    @media (min-width: 1400px) {
      footer .footer-social {
        margin: 4rem 0 2rem 0; } }
  footer .icon-footer-copyright-second:before,
  footer .icon-footer-copyright-first:before {
    display: inline-block;
    content: '';
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center; }
  footer .icon-footer-copyright-first:before {
    height: 35px;
    width: 75px;
    background-image: url("../img/footer1.png");
    margin: 0 1.5rem; }
  footer .icon-footer-copyright-second:before {
    height: 24px;
    width: 136px;
    background-image: url("../img/footer2.png");
    margin: 0 0 0 1.5rem; }
  footer .footer-map-link:before {
    content: "\e932"; }
  footer .insert-phone:before {
    content: "\e931"; }
  footer .insert-mail:before {
    content: "\e92f"; }
  footer .footer-gdpr:before {
    content: "\e930"; }
  footer .footer-accessibility:before {
    content: "\e92d"; }
  footer .footer-sitemap:before {
    content: "\e934"; }
  footer .footer-signpost:before {
    content: "\e933"; }

.InternetExplorer .footer-logo {
  display: block !important; }

.is-xxl {
  display: none; }
  @media (min-width: 1400px) {
    .is-xxl {
      display: block; } }

.slick-track {
  position: relative; }

.intro-container {
  position: relative;
  height: 100%; }
  .intro-container .bgr-image {
    height: 100%;
    width: 100%;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    position: relative;
    /*&:after{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 75vh;
      background: #000;
      @include opacity(0.15);
    }*/ }
  .intro-container .pux-container {
    position: relative; }
    .intro-container .pux-container .intro-title {
      margin: 0;
      padding: 1.5rem 3rem; }
      @media (min-width: 768px) {
        .intro-container .pux-container .intro-title {
          left: 4.5rem; } }

.slider-wrapper {
  position: relative;
  height: 75vh; }

.dots-wrapper {
  position: absolute;
  width: 100%;
  top: 2rem;
  left: 0; }
  @media (min-width: 768px) {
    .dots-wrapper {
      top: 0; } }
  .dots-wrapper .pux-container {
    text-align: right; }

.slider-placeholder {
  width: 15rem; }

.slider-init {
  height: 100%; }
  .slider-init .slick-list {
    height: 100%; }
    .slider-init .slick-list .slick-track {
      height: 100%; }

.founder-wrapper {
  position: absolute;
  bottom: 0;
  z-index: 5;
  left: 0;
  width: 100%; }
  .founder-wrapper .pux-container {
    text-align: right; }
    .founder-wrapper .pux-container .founder-item {
      position: relative; }
    .founder-wrapper .pux-container img {
      width: 14rem;
      position: absolute;
      bottom: 0;
      right: 0; }
      @media (min-width: 992px) {
        .founder-wrapper .pux-container img {
          width: 12rem; } }
      @media (min-width: 1400px) {
        .founder-wrapper .pux-container img {
          width: 10rem; } }
    .founder-wrapper .pux-container span {
      background: #fff;
      width: 14rem;
      text-align: center;
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 0.5rem;
      -webkit-transform: translateY(100%);
      -moz-transform: translateY(100%);
      -ms-transform: translateY(100%);
      -o-transform: translateY(100%);
      transform: translateY(100%); }
      @media (min-width: 992px) {
        .founder-wrapper .pux-container span {
          width: 12rem; } }
      @media (min-width: 1400px) {
        .founder-wrapper .pux-container span {
          width: 10rem; } }
  .founder-wrapper.wide-logo {
    position: absolute;
    padding: 2rem 0;
    top: 0; }
    @media (min-width: 768px) {
      .founder-wrapper.wide-logo {
        position: absolute;
        padding: 0;
        top: auto;
        bottom: 0; } }
    .founder-wrapper.wide-logo .founder-item {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center; }
      @media (min-width: 768px) {
        .founder-wrapper.wide-logo .founder-item {
          display: block; } }
      .founder-wrapper.wide-logo .founder-item img {
        order: 2;
        width: auto;
        height: 5.25rem !important; }
        @media (min-width: 768px) {
          .founder-wrapper.wide-logo .founder-item img {
            height: auto !important; } }
      .founder-wrapper.wide-logo .founder-item span {
        order: 1;
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        width: 18rem; }
        @media (min-width: 768px) {
          .founder-wrapper.wide-logo .founder-item span {
            -webkit-transform: translateY(100%);
            -moz-transform: translateY(100%);
            -ms-transform: translateY(100%);
            -o-transform: translateY(100%);
            transform: translateY(100%); } }
      .founder-wrapper.wide-logo .founder-item img, .founder-wrapper.wide-logo .founder-item span {
        position: static; }
        @media (min-width: 768px) {
          .founder-wrapper.wide-logo .founder-item img, .founder-wrapper.wide-logo .founder-item span {
            width: 165px;
            position: absolute; } }
        @media (min-width: 992px) {
          .founder-wrapper.wide-logo .founder-item img, .founder-wrapper.wide-logo .founder-item span {
            width: 175px; } }
        @media (min-width: 1200px) {
          .founder-wrapper.wide-logo .founder-item img, .founder-wrapper.wide-logo .founder-item span {
            width: 200px; } }
        @media (min-width: 1400px) {
          .founder-wrapper.wide-logo .founder-item img, .founder-wrapper.wide-logo .founder-item span {
            width: 220px; } }

.slick-dots {
  margin: auto;
  top: 0;
  right: 15px;
  padding: 3rem 0; }
  @media (min-width: 768px) {
    .slick-dots {
      right: 4.5rem; } }
  .slick-dots.reverse {
    right: auto;
    left: 15px; }
    @media (min-width: 768px) {
      .slick-dots.reverse {
        right: auto;
        left: 4.5rem; } }
  .slick-dots li {
    display: inline-block;
    margin: 0 2rem;
    padding: 0; }
    .slick-dots li:first-child:last-child {
      display: none; }
    .slick-dots li:first-child {
      margin-left: 0; }
    .slick-dots li:last-child {
      margin-right: 0; }
    .slick-dots li button {
      cursor: pointer;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms;
      height: 20px;
      width: 20px;
      -webkit-border-radius: 20rem;
      -moz-border-radius: 20rem;
      -ms-border-radius: 20rem;
      border-radius: 20rem;
      border: 0;
      font-size: 0; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: 0 !important; }
    .slick-dots li:before {
      width: 0;
      height: 0;
      display: none; }

.breadcrumbs {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  padding-top: 1rem; }
  .breadcrumbs .home {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    font-size: 0; }
    .breadcrumbs .home:after {
      content: "\e90d";
      display: block; }
      @media (min-width: 320px) {
        .breadcrumbs .home:after {
          font-size: 3.2rem; } }
      @media (min-width: 480px) {
        .breadcrumbs .home:after {
          font-size: 2.90909rem; } }
      @media (min-width: 545px) {
        .breadcrumbs .home:after {
          font-size: 2.66667rem; } }
      @media (min-width: 768px) {
        .breadcrumbs .home:after {
          font-size: 2.13333rem; } }
      @media (min-width: 992px) {
        .breadcrumbs .home:after {
          font-size: 2rem; } }
      @media (min-width: 1200px) {
        .breadcrumbs .home:after {
          font-size: 1.77778rem; } }
      @media (min-width: 1400px) {
        .breadcrumbs .home:after {
          font-size: 1.6rem; } }
    .breadcrumbs .home:hover, .breadcrumbs .home:focus {
      text-decoration: none; }
      .breadcrumbs .home:hover:after, .breadcrumbs .home:focus:after {
        text-decoration: none; }
  .breadcrumbs .breadcrumbs-separator:after {
    position: relative;
    top: 0px;
    display: inline-block;
    content: "\e902";
    vertical-align: middle;
    -webkit-transform: scale(0.25) rotate(270deg);
    -moz-transform: scale(0.25) rotate(270deg);
    -ms-transform: scale(0.25) rotate(270deg);
    -o-transform: scale(0.25) rotate(270deg);
    transform: scale(0.25) rotate(270deg);
    margin: auto .5rem; }

.header-helper {
  height: 8rem; }
  @media (min-width: 992px) {
    .header-helper {
      height: 7rem; } }

.header-intro {
  padding-top: 1rem;
  padding-bottom: 4rem;
  text-align: center;
  position: relative; }
  .header-intro .bgr-image {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.2;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important; }
  .header-intro h1 {
    font-weight: 600;
    padding: 2rem 0 2rem 0;
    margin: 0; }
  .header-intro-bread, .header-intro-title, .header-intro-subtitle {
    position: relative;
    z-index: 3; }
  .header-intro-title .h1 {
    padding: 0;
    margin: 3rem 0 1rem; }
  .header-intro-subtitle {
    font-weight: 700;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center; }
    @media (min-width: 320px) {
      .header-intro-subtitle {
        font-size: 2.6rem; } }
    @media (min-width: 480px) {
      .header-intro-subtitle {
        font-size: 2.36364rem; } }
    @media (min-width: 545px) {
      .header-intro-subtitle {
        font-size: 2.16667rem; } }
    @media (min-width: 768px) {
      .header-intro-subtitle {
        font-size: 1.73333rem; } }
    @media (min-width: 992px) {
      .header-intro-subtitle {
        font-size: 1.625rem; } }
    @media (min-width: 1200px) {
      .header-intro-subtitle {
        font-size: 1.44444rem; } }
    @media (min-width: 1400px) {
      .header-intro-subtitle {
        font-size: 1.3rem; } }
    .header-intro-subtitle:before {
      content: "\e90e";
      margin-right: 1rem; }

.text-container {
  padding: 5rem 0;
  margin: auto;
  max-width: 900px; }

.detail-photo {
  padding: 7.5px;
  position: relative;
  height: 30rem;
  width: 100%;
  overflow: hidden;
  display: block; }
  .detail-photo-bgr {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .detail-photo:focus, .detail-photo:hover {
    text-decoration: none; }
    .detail-photo:focus .detail-photo-bgr, .detail-photo:hover .detail-photo-bgr {
      -webkit-transform: scale(1.2);
      -moz-transform: scale(1.2);
      -ms-transform: scale(1.2);
      -o-transform: scale(1.2);
      transform: scale(1.2); }

.detail-photo-item {
  text-align: center; }
  .detail-photo-item-image {
    padding: 7.5px;
    position: relative;
    height: 30rem;
    width: 100%;
    overflow: hidden;
    text-align: center; }
  .detail-photo-item-bgr {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .detail-photo-item-text {
    margin: 1rem 0 2rem 0; }
    .detail-photo-item-text h4 {
      transition: 0.3s ease all; }
  .detail-photo-item:focus, .detail-photo-item:hover {
    text-decoration: none; }
    .detail-photo-item:focus .detail-photo-item-bgr, .detail-photo-item:hover .detail-photo-item-bgr {
      -webkit-transform: scale(1.2);
      -moz-transform: scale(1.2);
      -ms-transform: scale(1.2);
      -o-transform: scale(1.2);
      transform: scale(1.2); }

.intro-botttom {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%; }
  .intro-botttom-inner {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: space-between;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between; }
    .intro-botttom-inner.reverse .intro-title {
      -webkit-box-ordinal-group: 2;
      -moz-box-ordinal-group: 2;
      -ms-box-ordinal-group: 2;
      -ms-flex-order: 2;
      -webkit-order: 2;
      -moz-order: 2;
      -ms-order: 2;
      order: 2; }

.row .col-xs-12.col-md-6.col-lg-4.preview {
  padding: 7.5px; }
  .row .col-xs-12.col-md-6.col-lg-4.preview a:focus .detail-photo-bgr,
  .row .col-xs-12.col-md-6.col-lg-4.preview a:hover .detail-photo-bgr {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2); }

.contact-container a {
  font-weight: 700; }

.person-item {
  margin: 5rem auto; }
  @media (min-width: 768px) {
    .person-item {
      margin: 3rem auto; } }
  .person-item-image {
    position: relative;
    margin: 2rem auto;
    width: 20rem;
    height: 20rem;
    -webkit-border-radius: 100rem;
    -moz-border-radius: 100rem;
    -ms-border-radius: 100rem;
    border-radius: 100rem; }
    @media (min-width: 768px) {
      .person-item-image {
        width: 15rem;
        height: 15rem; } }
    .person-item-image-bgr {
      -webkit-border-radius: 100rem;
      -moz-border-radius: 100rem;
      -ms-border-radius: 100rem;
      border-radius: 100rem;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: center !important;
      background-repeat: no-repeat !important;
      background-size: cover !important; }
    .person-item-image.man, .person-item-image.woman {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      -webkit-box-pack: center;
      -moz-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center; }
      .person-item-image.man:after, .person-item-image.woman:after {
        content: "\e905";
        display: block;
        font-size: 15rem; }
        @media (min-width: 768px) {
          .person-item-image.man:after, .person-item-image.woman:after {
            font-size: 10rem; } }
    .person-item-image.woman:after {
      content: "\e906"; }
  .person-item-content a {
    text-decoration: underline; }
    .person-item-content a:hover {
      text-decoration: none; }
  @media (min-width: 320px) {
    .person-item-content-function {
      font-size: 2.8rem; } }
  @media (min-width: 480px) {
    .person-item-content-function {
      font-size: 2.54545rem; } }
  @media (min-width: 545px) {
    .person-item-content-function {
      font-size: 2.33333rem; } }
  @media (min-width: 768px) {
    .person-item-content-function {
      font-size: 1.86667rem; } }
  @media (min-width: 992px) {
    .person-item-content-function {
      font-size: 1.75rem; } }
  @media (min-width: 1200px) {
    .person-item-content-function {
      font-size: 1.55556rem; } }
  @media (min-width: 1400px) {
    .person-item-content-function {
      font-size: 1.4rem; } }
  .person-item-content-name {
    margin-bottom: 2rem; }
    @media (min-width: 320px) {
      .person-item-content-name {
        font-size: 3.2rem; } }
    @media (min-width: 480px) {
      .person-item-content-name {
        font-size: 2.90909rem; } }
    @media (min-width: 545px) {
      .person-item-content-name {
        font-size: 2.66667rem; } }
    @media (min-width: 768px) {
      .person-item-content-name {
        font-size: 2.13333rem; } }
    @media (min-width: 992px) {
      .person-item-content-name {
        font-size: 2rem; } }
  .person-item-content-name, .person-item-content-function {
    font-weight: bold; }

@media (min-width: 768px) {
  .map-container {
    height: 100%; } }

.text-and-submit {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .text-and-submit .personal-detail-text {
    width: 100%; }
    @media (min-width: 992px) {
      .text-and-submit .personal-detail-text {
        width: 50%; } }
  .text-and-submit .btn {
    margin: 2rem auto; }
    @media (min-width: 992px) {
      .text-and-submit .btn {
        margin: auto 0; } }

.size-switch {
  cursor: pointer;
  position: fixed;
  top: 25vh;
  right: 0;
  text-align: center;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  z-index: 999999999;
  display: none; }
  @media (min-width: 992px) {
    .size-switch {
      display: block; } }
  .size-switch .small-switch,
  .size-switch .big-switch {
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    text-align: center;
    background-color: transparent;
    width: 10rem;
    height: 10rem;
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center; }
    @media (min-width: 480px) {
      .size-switch .small-switch,
      .size-switch .big-switch {
        width: 9.09091rem; } }
    @media (min-width: 545px) {
      .size-switch .small-switch,
      .size-switch .big-switch {
        width: 8.33333rem; } }
    @media (min-width: 768px) {
      .size-switch .small-switch,
      .size-switch .big-switch {
        width: 6.66667rem; } }
    @media (min-width: 992px) {
      .size-switch .small-switch,
      .size-switch .big-switch {
        width: 6.25rem; } }
    @media (min-width: 1200px) {
      .size-switch .small-switch,
      .size-switch .big-switch {
        width: 5.55556rem; } }
    @media (min-width: 1400px) {
      .size-switch .small-switch,
      .size-switch .big-switch {
        width: 5rem; } }
    @media (min-width: 480px) {
      .size-switch .small-switch,
      .size-switch .big-switch {
        height: 9.09091rem; } }
    @media (min-width: 545px) {
      .size-switch .small-switch,
      .size-switch .big-switch {
        height: 8.33333rem; } }
    @media (min-width: 768px) {
      .size-switch .small-switch,
      .size-switch .big-switch {
        height: 6.66667rem; } }
    @media (min-width: 992px) {
      .size-switch .small-switch,
      .size-switch .big-switch {
        height: 6.25rem; } }
    @media (min-width: 1200px) {
      .size-switch .small-switch,
      .size-switch .big-switch {
        height: 5.55556rem; } }
    @media (min-width: 1400px) {
      .size-switch .small-switch,
      .size-switch .big-switch {
        height: 5rem; } }
  .size-switch .small-switch {
    position: relative; }
    @media (min-width: 320px) {
      .size-switch .small-switch {
        font-size: 2.8rem; } }
    @media (min-width: 480px) {
      .size-switch .small-switch {
        font-size: 2.54545rem; } }
    @media (min-width: 545px) {
      .size-switch .small-switch {
        font-size: 2.33333rem; } }
    @media (min-width: 768px) {
      .size-switch .small-switch {
        font-size: 1.86667rem; } }
    @media (min-width: 992px) {
      .size-switch .small-switch {
        font-size: 1.75rem; } }
    @media (min-width: 1200px) {
      .size-switch .small-switch {
        font-size: 1.55556rem; } }
    @media (min-width: 1400px) {
      .size-switch .small-switch {
        font-size: 1.4rem; } }
    .size-switch .small-switch.show:after {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0); }
    .size-switch .small-switch:before {
      content: "\e924";
      line-height: 1; }
      @media (min-width: 320px) {
        .size-switch .small-switch:before {
          font-size: 3.6rem; } }
      @media (min-width: 480px) {
        .size-switch .small-switch:before {
          font-size: 3.27273rem; } }
      @media (min-width: 545px) {
        .size-switch .small-switch:before {
          font-size: 3rem; } }
      @media (min-width: 768px) {
        .size-switch .small-switch:before {
          font-size: 2.4rem; } }
      @media (min-width: 992px) {
        .size-switch .small-switch:before {
          font-size: 2.25rem; } }
      @media (min-width: 1200px) {
        .size-switch .small-switch:before {
          font-size: 2rem; } }
    .size-switch .small-switch:after {
      content: "Zmenšit písmo";
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      -webkit-box-pack: center;
      -moz-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      font-family: "proxima-nova", sans-serif !important;
      position: absolute;
      right: 0;
      width: 25rem;
      height: 100%;
      top: 0;
      -moz-transition: all ease-in-out 400ms;
      -o-transition: all ease-in-out 400ms;
      -webkit-transition: all ease-in-out 400ms;
      transition: all ease-in-out 400ms;
      -webkit-transform: translateX(1000px);
      -moz-transform: translateX(1000px);
      -ms-transform: translateX(1000px);
      -o-transform: translateX(1000px);
      transform: translateX(1000px); }
      @media (min-width: 768px) {
        .size-switch .small-switch:after {
          width: 15rem; } }
      @media (min-width: 320px) {
        .size-switch .small-switch:after {
          font-size: 2.8rem; } }
      @media (min-width: 480px) {
        .size-switch .small-switch:after {
          font-size: 2.54545rem; } }
      @media (min-width: 545px) {
        .size-switch .small-switch:after {
          font-size: 2.33333rem; } }
      @media (min-width: 768px) {
        .size-switch .small-switch:after {
          font-size: 1.86667rem; } }
      @media (min-width: 992px) {
        .size-switch .small-switch:after {
          font-size: 1.75rem; } }
      @media (min-width: 1200px) {
        .size-switch .small-switch:after {
          font-size: 1.55556rem; } }
      @media (min-width: 1400px) {
        .size-switch .small-switch:after {
          font-size: 1.4rem; } }
  .size-switch .big-switch {
    font-weight: 700;
    position: relative; }
    @media (min-width: 320px) {
      .size-switch .big-switch {
        font-size: 3.6rem; } }
    @media (min-width: 480px) {
      .size-switch .big-switch {
        font-size: 3.27273rem; } }
    @media (min-width: 545px) {
      .size-switch .big-switch {
        font-size: 3rem; } }
    @media (min-width: 768px) {
      .size-switch .big-switch {
        font-size: 2.4rem; } }
    @media (min-width: 992px) {
      .size-switch .big-switch {
        font-size: 2.25rem; } }
    @media (min-width: 1200px) {
      .size-switch .big-switch {
        font-size: 2rem; } }
    .size-switch .big-switch.show:after {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
      transform: translateX(0); }
    .size-switch .big-switch:before {
      content: "\e923";
      line-height: 1; }
    .size-switch .big-switch:after {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      -webkit-box-pack: center;
      -moz-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      content: "Zvětšit písmo";
      font-family: "proxima-nova", sans-serif !important;
      position: absolute;
      right: 0;
      width: 30rem;
      height: 100%;
      line-height: 100%;
      font-weight: 700;
      top: 0;
      -moz-transition: all ease-in-out 400ms;
      -o-transition: all ease-in-out 400ms;
      -webkit-transition: all ease-in-out 400ms;
      transition: all ease-in-out 400ms;
      -webkit-transform: translateX(1000px);
      -moz-transform: translateX(1000px);
      -ms-transform: translateX(1000px);
      -o-transform: translateX(1000px);
      transform: translateX(1000px); }
      @media (min-width: 768px) {
        .size-switch .big-switch:after {
          width: 20rem; } }
      @media (min-width: 320px) {
        .size-switch .big-switch:after {
          font-size: 3.6rem; } }
      @media (min-width: 480px) {
        .size-switch .big-switch:after {
          font-size: 3.27273rem; } }
      @media (min-width: 545px) {
        .size-switch .big-switch:after {
          font-size: 3rem; } }
      @media (min-width: 768px) {
        .size-switch .big-switch:after {
          font-size: 2.4rem; } }
      @media (min-width: 992px) {
        .size-switch .big-switch:after {
          font-size: 2.25rem; } }
      @media (min-width: 1200px) {
        .size-switch .big-switch:after {
          font-size: 2rem; } }

.floating-label-text {
  height: 115px !important; }
  @media (min-width: 768px) {
    .floating-label-text {
      height: 150px !important; } }

.floating-label-text,
.floating-label {
  position: relative;
  width: 100%;
  height: 60px;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .floating-label-text > .placeholder,
  .floating-label > .placeholder {
    opacity: 1;
    pointer-events: none;
    line-height: 60px;
    transform: translate3d(0, -100%, 0);
    padding: 0;
    display: inline-block;
    position: relative;
    z-index: 2;
    margin: 0;
    font-size: 30px; }
    .floating-label-text > .placeholder.active,
    .floating-label > .placeholder.active {
      opacity: 0; }
  .floating-label-text > label,
  .floating-label > label {
    position: absolute;
    top: 5px;
    left: 15px;
    display: inline-block;
    transition: all .3s ease;
    opacity: 1;
    z-index: 11;
    padding: 0; }
    .floating-label-text > label.is-visible,
    .floating-label > label.is-visible {
      transform: translate3d(0, -15px, 0);
      opacity: 1;
      font-size: 16px; }
  .floating-label-text > input,
  .floating-label > input {
    position: relative;
    z-index: 1;
    float: left;
    width: 100%;
    height: 60px;
    clear: both;
    margin-bottom: 1rem;
    padding: 0;
    background-color: transparent; }
  .floating-label-text.active label,
  .floating-label.active label {
    transform: translate3d(0, -8px, 0);
    opacity: 1;
    font-size: 10px;
    left: 15px; }
    @media (min-width: 768px) {
      .floating-label-text.active label,
      .floating-label.active label {
        transform: translate3d(0, -10px, 0); } }

.is-visible input {
  padding-top: 2.5rem !important;
  padding-left: 15px !important; }
  @media (min-width: 768px) {
    .is-visible input {
      padding-top: 1.25rem !important; } }

.is-visible textarea {
  padding-top: 3rem !important;
  padding-left: 15px; }
  @media (min-width: 768px) {
    .is-visible textarea {
      padding-top: 2rem !important; } }

.select-box {
  max-width: 900px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 2rem auto 3rem; }

.javascript-hide {
  display: none; }

.pux-infinite-repeater-options-inner-load-more {
  text-align: center; }

.big-body .header-menu ul li:before {
  display: none !important; }
  @media (min-width: 1400px) {
    .big-body .header-menu ul li:before {
      display: block !important; } }

.cms-bootstrap [class^="icon-"]:before, .cms-bootstrap [class*=" icon-"]:before {
  font-family: 'Core-icons' !important; }

*:before,
*:after {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.helper-4k {
  max-width: 200rem;
  margin: auto; }

.hide {
  display: none !important; }

.show {
  display: block !important; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.uppercase {
  text-transform: uppercase; }

html {
  font-size: 5.5px; }
  @media (min-width: 480px) {
    html {
      font-size: 6.05px; } }
  @media (min-width: 545px) {
    html {
      font-size: 6.6px; } }
  @media (min-width: 768px) {
    html {
      font-size: 8.25px; } }
  @media (min-width: 992px) {
    html {
      font-size: 8px; } }
  @media (min-width: 1200px) {
    html {
      font-size: 9px; } }
  @media (min-width: 1400px) {
    html {
      font-size: 10px; } }
  html.big-body {
    font-size: 5.5px; }
    @media (min-width: 480px) {
      html.big-body {
        font-size: 6.05px; } }
    @media (min-width: 545px) {
      html.big-body {
        font-size: 6.6px; } }
    @media (min-width: 768px) {
      html.big-body {
        font-size: 8.25px; } }
    @media (min-width: 992px) {
      html.big-body {
        font-size: 8.8px; } }
    @media (min-width: 1200px) {
      html.big-body {
        font-size: 9.9px; } }
    @media (min-width: 1400px) {
      html.big-body {
        font-size: 11px; } }
    html.big-body .floating-label {
      height: 60px !important; }
      @media (min-width: 768px) {
        html.big-body .floating-label {
          height: 75px !important; } }
    html.big-body .floating-label-text {
      height: 120px !important; }
      @media (min-width: 768px) {
        html.big-body .floating-label-text {
          height: 180px !important; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1.25), only screen and (-o-min-device-pixel-ratio: 5 / 4), only screen and (min-resolution: 120dpi), only screen and (min-resolution: 1.25dppx) {
  html {
    font-size: 5.5px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 480px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 480px), only screen and (min-resolution: 120dpi) and (min-width: 480px), only screen and (min-resolution: 1.25dppx) and (min-width: 480px) {
    html {
      font-size: 6.05px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 545px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 545px), only screen and (min-resolution: 120dpi) and (min-width: 545px), only screen and (min-resolution: 1.25dppx) and (min-width: 545px) {
    html {
      font-size: 6.6px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 768px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 768px), only screen and (min-resolution: 120dpi) and (min-width: 768px), only screen and (min-resolution: 1.25dppx) and (min-width: 768px) {
    html {
      font-size: 8.25px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 992px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 992px), only screen and (min-resolution: 120dpi) and (min-width: 992px), only screen and (min-resolution: 1.25dppx) and (min-width: 992px) {
    html {
      font-size: 8px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 1200px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 1200px), only screen and (min-resolution: 120dpi) and (min-width: 1200px), only screen and (min-resolution: 1.25dppx) and (min-width: 1200px) {
    html {
      font-size: 7.2px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 1400px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 1400px), only screen and (min-resolution: 120dpi) and (min-width: 1400px), only screen and (min-resolution: 1.25dppx) and (min-width: 1400px) {
    html {
      font-size: 8px; } }

form,
html,
body {
  font-family: "proxima-nova", sans-serif;
  height: 100%;
  color: #4c4c4c; }

@media (min-width: 320px) {
  body {
    font-size: 3.2rem; } }

@media (min-width: 480px) {
  body {
    font-size: 2.90909rem; } }

@media (min-width: 545px) {
  body {
    font-size: 2.66667rem; } }

@media (min-width: 768px) {
  body {
    font-size: 2.13333rem; } }

@media (min-width: 992px) {
  body {
    font-size: 2rem; } }

@media (min-width: 1200px) {
  body {
    font-size: 1.8rem; } }

img {
  max-width: 100%;
  height: auto !important; }

@media (min-width: 320px) {
  .pt-5 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-5 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-5 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-5 {
    padding-top: 2.66667rem; } }

@media (min-width: 992px) {
  .pt-5 {
    padding-top: 2.5rem; } }

@media (min-width: 1200px) {
  .pt-5 {
    padding-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .pt-5 {
    padding-top: 2rem; } }

@media (min-width: 320px) {
  .pb-5 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-5 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-5 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-5 {
    padding-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .pb-5 {
    padding-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .pb-5 {
    padding-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .pb-5 {
    padding-bottom: 2rem; } }

@media (min-width: 320px) {
  .mt-5 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-5 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-5 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-5 {
    margin-top: 2.66667rem; } }

@media (min-width: 992px) {
  .mt-5 {
    margin-top: 2.5rem; } }

@media (min-width: 1200px) {
  .mt-5 {
    margin-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .mt-5 {
    margin-top: 2rem; } }

@media (min-width: 320px) {
  .mb-5 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-5 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-5 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-5 {
    margin-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .mb-5 {
    margin-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .mb-5 {
    margin-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .mb-5 {
    margin-bottom: 2rem; } }

@media (min-width: 320px) {
  .pt-10 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-10 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-10 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-10 {
    padding-top: 2.66667rem; } }

@media (min-width: 992px) {
  .pt-10 {
    padding-top: 2.5rem; } }

@media (min-width: 1200px) {
  .pt-10 {
    padding-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .pt-10 {
    padding-top: 2rem; } }

@media (min-width: 320px) {
  .pb-10 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-10 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-10 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-10 {
    padding-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .pb-10 {
    padding-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .pb-10 {
    padding-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .pb-10 {
    padding-bottom: 2rem; } }

@media (min-width: 320px) {
  .mt-10 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-10 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-10 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-10 {
    margin-top: 2.66667rem; } }

@media (min-width: 992px) {
  .mt-10 {
    margin-top: 2.5rem; } }

@media (min-width: 1200px) {
  .mt-10 {
    margin-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .mt-10 {
    margin-top: 2rem; } }

@media (min-width: 320px) {
  .mb-10 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-10 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-10 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-10 {
    margin-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .mb-10 {
    margin-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .mb-10 {
    margin-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .mb-10 {
    margin-bottom: 2rem; } }

@media (min-width: 320px) {
  .pt-15 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-15 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-15 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-15 {
    padding-top: 2.66667rem; } }

@media (min-width: 992px) {
  .pt-15 {
    padding-top: 2.5rem; } }

@media (min-width: 1200px) {
  .pt-15 {
    padding-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .pt-15 {
    padding-top: 2rem; } }

@media (min-width: 320px) {
  .pb-15 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-15 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-15 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-15 {
    padding-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .pb-15 {
    padding-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .pb-15 {
    padding-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .pb-15 {
    padding-bottom: 2rem; } }

@media (min-width: 320px) {
  .mt-15 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-15 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-15 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-15 {
    margin-top: 2.66667rem; } }

@media (min-width: 992px) {
  .mt-15 {
    margin-top: 2.5rem; } }

@media (min-width: 1200px) {
  .mt-15 {
    margin-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .mt-15 {
    margin-top: 2rem; } }

@media (min-width: 320px) {
  .mb-15 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-15 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-15 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-15 {
    margin-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .mb-15 {
    margin-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .mb-15 {
    margin-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .mb-15 {
    margin-bottom: 2rem; } }

@media (min-width: 320px) {
  .pt-20 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-20 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-20 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-20 {
    padding-top: 2.66667rem; } }

@media (min-width: 992px) {
  .pt-20 {
    padding-top: 2.5rem; } }

@media (min-width: 1200px) {
  .pt-20 {
    padding-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .pt-20 {
    padding-top: 2rem; } }

@media (min-width: 320px) {
  .pb-20 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-20 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-20 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-20 {
    padding-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .pb-20 {
    padding-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .pb-20 {
    padding-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .pb-20 {
    padding-bottom: 2rem; } }

@media (min-width: 320px) {
  .mt-20 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-20 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-20 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-20 {
    margin-top: 2.66667rem; } }

@media (min-width: 992px) {
  .mt-20 {
    margin-top: 2.5rem; } }

@media (min-width: 1200px) {
  .mt-20 {
    margin-top: 2.22222rem; } }

@media (min-width: 1400px) {
  .mt-20 {
    margin-top: 2rem; } }

@media (min-width: 320px) {
  .mb-20 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-20 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-20 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-20 {
    margin-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .mb-20 {
    margin-bottom: 2.5rem; } }

@media (min-width: 1200px) {
  .mb-20 {
    margin-bottom: 2.22222rem; } }

@media (min-width: 1400px) {
  .mb-20 {
    margin-bottom: 2rem; } }

@media (min-width: 320px) {
  .pt-25 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-25 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-25 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-25 {
    padding-top: 2.66667rem; } }

@media (min-width: 992px) {
  .pt-25 {
    padding-top: 2.5rem; } }

@media (min-width: 320px) {
  .pb-25 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-25 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-25 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-25 {
    padding-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .pb-25 {
    padding-bottom: 2.5rem; } }

@media (min-width: 320px) {
  .mt-25 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-25 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-25 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-25 {
    margin-top: 2.66667rem; } }

@media (min-width: 992px) {
  .mt-25 {
    margin-top: 2.5rem; } }

@media (min-width: 320px) {
  .mb-25 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-25 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-25 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-25 {
    margin-bottom: 2.66667rem; } }

@media (min-width: 992px) {
  .mb-25 {
    margin-bottom: 2.5rem; } }

@media (min-width: 320px) {
  .pt-30 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-30 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-30 {
    padding-top: 3.33333rem; } }

@media (min-width: 768px) {
  .pt-30 {
    padding-top: 3rem; } }

@media (min-width: 320px) {
  .pb-30 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-30 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-30 {
    padding-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .pb-30 {
    padding-bottom: 3rem; } }

@media (min-width: 320px) {
  .mt-30 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-30 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-30 {
    margin-top: 3.33333rem; } }

@media (min-width: 768px) {
  .mt-30 {
    margin-top: 3rem; } }

@media (min-width: 320px) {
  .mb-30 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-30 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-30 {
    margin-bottom: 3.33333rem; } }

@media (min-width: 768px) {
  .mb-30 {
    margin-bottom: 3rem; } }

@media (min-width: 320px) {
  .pt-35 {
    padding-top: 4rem; } }

@media (min-width: 480px) {
  .pt-35 {
    padding-top: 3.63636rem; } }

@media (min-width: 545px) {
  .pt-35 {
    padding-top: 3.5rem; } }

@media (min-width: 320px) {
  .pb-35 {
    padding-bottom: 4rem; } }

@media (min-width: 480px) {
  .pb-35 {
    padding-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .pb-35 {
    padding-bottom: 3.5rem; } }

@media (min-width: 320px) {
  .mt-35 {
    margin-top: 4rem; } }

@media (min-width: 480px) {
  .mt-35 {
    margin-top: 3.63636rem; } }

@media (min-width: 545px) {
  .mt-35 {
    margin-top: 3.5rem; } }

@media (min-width: 320px) {
  .mb-35 {
    margin-bottom: 4rem; } }

@media (min-width: 480px) {
  .mb-35 {
    margin-bottom: 3.63636rem; } }

@media (min-width: 545px) {
  .mb-35 {
    margin-bottom: 3.5rem; } }

@media (min-width: 320px) {
  .pt-40 {
    padding-top: 4rem; } }

@media (min-width: 320px) {
  .pb-40 {
    padding-bottom: 4rem; } }

@media (min-width: 320px) {
  .mt-40 {
    margin-top: 4rem; } }

@media (min-width: 320px) {
  .mb-40 {
    margin-bottom: 4rem; } }

@media (min-width: 320px) {
  .pt-45 {
    padding-top: 4.5rem; } }

@media (min-width: 320px) {
  .pb-45 {
    padding-bottom: 4.5rem; } }

@media (min-width: 320px) {
  .mt-45 {
    margin-top: 4.5rem; } }

@media (min-width: 320px) {
  .mb-45 {
    margin-bottom: 4.5rem; } }

@media (min-width: 320px) {
  .pt-50 {
    padding-top: 5rem; } }

@media (min-width: 320px) {
  .pb-50 {
    padding-bottom: 5rem; } }

@media (min-width: 320px) {
  .mt-50 {
    margin-top: 5rem; } }

@media (min-width: 320px) {
  .mb-50 {
    margin-bottom: 5rem; } }

@media (min-width: 320px) {
  .pt-55 {
    padding-top: 5.5rem; } }

@media (min-width: 320px) {
  .pb-55 {
    padding-bottom: 5.5rem; } }

@media (min-width: 320px) {
  .mt-55 {
    margin-top: 5.5rem; } }

@media (min-width: 320px) {
  .mb-55 {
    margin-bottom: 5.5rem; } }

@media (min-width: 320px) {
  .pt-60 {
    padding-top: 6rem; } }

@media (min-width: 320px) {
  .pb-60 {
    padding-bottom: 6rem; } }

@media (min-width: 320px) {
  .mt-60 {
    margin-top: 6rem; } }

@media (min-width: 320px) {
  .mb-60 {
    margin-bottom: 6rem; } }

@media (min-width: 320px) {
  .pt-65 {
    padding-top: 6.5rem; } }

@media (min-width: 320px) {
  .pb-65 {
    padding-bottom: 6.5rem; } }

@media (min-width: 320px) {
  .mt-65 {
    margin-top: 6.5rem; } }

@media (min-width: 320px) {
  .mb-65 {
    margin-bottom: 6.5rem; } }

@media (min-width: 320px) {
  .pt-70 {
    padding-top: 7rem; } }

@media (min-width: 320px) {
  .pb-70 {
    padding-bottom: 7rem; } }

@media (min-width: 320px) {
  .mt-70 {
    margin-top: 7rem; } }

@media (min-width: 320px) {
  .mb-70 {
    margin-bottom: 7rem; } }

@media (min-width: 320px) {
  .pt-75 {
    padding-top: 7.5rem; } }

@media (min-width: 320px) {
  .pb-75 {
    padding-bottom: 7.5rem; } }

@media (min-width: 320px) {
  .mt-75 {
    margin-top: 7.5rem; } }

@media (min-width: 320px) {
  .mb-75 {
    margin-bottom: 7.5rem; } }

@media (min-width: 320px) {
  .pt-80 {
    padding-top: 8rem; } }

@media (min-width: 320px) {
  .pb-80 {
    padding-bottom: 8rem; } }

@media (min-width: 320px) {
  .mt-80 {
    margin-top: 8rem; } }

@media (min-width: 320px) {
  .mb-80 {
    margin-bottom: 8rem; } }

@media (min-width: 320px) {
  .pt-85 {
    padding-top: 8.5rem; } }

@media (min-width: 320px) {
  .pb-85 {
    padding-bottom: 8.5rem; } }

@media (min-width: 320px) {
  .mt-85 {
    margin-top: 8.5rem; } }

@media (min-width: 320px) {
  .mb-85 {
    margin-bottom: 8.5rem; } }

@media (min-width: 320px) {
  .pt-90 {
    padding-top: 9rem; } }

@media (min-width: 320px) {
  .pb-90 {
    padding-bottom: 9rem; } }

@media (min-width: 320px) {
  .mt-90 {
    margin-top: 9rem; } }

@media (min-width: 320px) {
  .mb-90 {
    margin-bottom: 9rem; } }

@media (min-width: 320px) {
  .pt-95 {
    padding-top: 9.5rem; } }

@media (min-width: 320px) {
  .pb-95 {
    padding-bottom: 9.5rem; } }

@media (min-width: 320px) {
  .mt-95 {
    margin-top: 9.5rem; } }

@media (min-width: 320px) {
  .mb-95 {
    margin-bottom: 9.5rem; } }

@media (min-width: 320px) {
  .pt-100 {
    padding-top: 10rem; } }

@media (min-width: 320px) {
  .pb-100 {
    padding-bottom: 10rem; } }

@media (min-width: 320px) {
  .mt-100 {
    margin-top: 10rem; } }

@media (min-width: 320px) {
  .mb-100 {
    margin-bottom: 10rem; } }

h1,
.h1 {
  padding: 5rem 0; }
  @media (min-width: 320px) {
    h1,
    .h1 {
      font-size: 5rem; } }
  @media (min-width: 480px) {
    h1,
    .h1 {
      font-size: 4.8rem; } }
  @media (min-width: 320px) {
    h1,
    .h1 {
      line-height: 5rem; } }

h2,
.h2 {
  font-weight: 800;
  text-transform: uppercase;
  padding: 2rem 0; }
  @media (min-width: 320px) {
    h2,
    .h2 {
      font-size: 4.4rem; } }
  @media (min-width: 480px) {
    h2,
    .h2 {
      font-size: 4rem; } }
  @media (min-width: 545px) {
    h2,
    .h2 {
      font-size: 3.66667rem; } }
  @media (min-width: 768px) {
    h2,
    .h2 {
      font-size: 3.3rem; } }
  @media (min-width: 320px) {
    h2,
    .h2 {
      line-height: 5.6rem; } }
  @media (min-width: 480px) {
    h2,
    .h2 {
      line-height: 5.09091rem; } }
  @media (min-width: 545px) {
    h2,
    .h2 {
      line-height: 4.66667rem; } }
  @media (min-width: 768px) {
    h2,
    .h2 {
      line-height: 3.73333rem; } }
  @media (min-width: 992px) {
    h2,
    .h2 {
      line-height: 3.7rem; } }

h3,
.h3 {
  font-weight: 700;
  padding: 1rem 0 2rem; }
  @media (min-width: 320px) {
    h3,
    .h3 {
      font-size: 4rem; } }
  @media (min-width: 480px) {
    h3,
    .h3 {
      font-size: 3.63636rem; } }
  @media (min-width: 545px) {
    h3,
    .h3 {
      font-size: 3.33333rem; } }
  @media (min-width: 768px) {
    h3,
    .h3 {
      font-size: 2.66667rem; } }
  @media (min-width: 992px) {
    h3,
    .h3 {
      font-size: 2.5rem; } }
  @media (min-width: 1200px) {
    h3,
    .h3 {
      font-size: 2.4rem; } }
  @media (min-width: 320px) {
    h3,
    .h3 {
      line-height: 4rem; } }
  @media (min-width: 480px) {
    h3,
    .h3 {
      line-height: 3.63636rem; } }
  @media (min-width: 545px) {
    h3,
    .h3 {
      line-height: 3.33333rem; } }
  @media (min-width: 768px) {
    h3,
    .h3 {
      line-height: 2.8rem; } }

h4,
.h4 {
  font-weight: 700; }
  @media (min-width: 320px) {
    h4,
    .h4 {
      font-size: 3.6rem; } }
  @media (min-width: 480px) {
    h4,
    .h4 {
      font-size: 3.27273rem; } }
  @media (min-width: 545px) {
    h4,
    .h4 {
      font-size: 3rem; } }
  @media (min-width: 768px) {
    h4,
    .h4 {
      font-size: 2.4rem; } }
  @media (min-width: 992px) {
    h4,
    .h4 {
      font-size: 2.25rem; } }
  @media (min-width: 1200px) {
    h4,
    .h4 {
      font-size: 2.2rem; } }
  @media (min-width: 320px) {
    h4,
    .h4 {
      line-height: 4rem; } }
  @media (min-width: 480px) {
    h4,
    .h4 {
      line-height: 3.63636rem; } }
  @media (min-width: 545px) {
    h4,
    .h4 {
      line-height: 3.33333rem; } }
  @media (min-width: 768px) {
    h4,
    .h4 {
      line-height: 2.8rem; } }

@media (min-width: 320px) {
  big,
  .perex {
    font-size: 3.6rem; } }

@media (min-width: 480px) {
  big,
  .perex {
    font-size: 3.27273rem; } }

@media (min-width: 545px) {
  big,
  .perex {
    font-size: 3rem; } }

@media (min-width: 768px) {
  big,
  .perex {
    font-size: 2.4rem; } }

@media (min-width: 992px) {
  big,
  .perex {
    font-size: 2.25rem; } }

@media (min-width: 1200px) {
  big,
  .perex {
    font-size: 2rem; } }

@media (min-width: 320px) {
  big,
  .perex {
    line-height: 5rem; } }

@media (min-width: 480px) {
  big,
  .perex {
    line-height: 4.54545rem; } }

@media (min-width: 545px) {
  big,
  .perex {
    line-height: 4.16667rem; } }

@media (min-width: 768px) {
  big,
  .perex {
    line-height: 3.33333rem; } }

@media (min-width: 992px) {
  big,
  .perex {
    line-height: 3.125rem; } }

@media (min-width: 1200px) {
  big,
  .perex {
    line-height: 3rem; } }

@media (min-width: 320px) {
  small,
  .text-small {
    font-size: 2.8rem; } }

@media (min-width: 480px) {
  small,
  .text-small {
    font-size: 2.54545rem; } }

@media (min-width: 545px) {
  small,
  .text-small {
    font-size: 2.33333rem; } }

@media (min-width: 768px) {
  small,
  .text-small {
    font-size: 1.86667rem; } }

@media (min-width: 992px) {
  small,
  .text-small {
    font-size: 1.75rem; } }

@media (min-width: 1200px) {
  small,
  .text-small {
    font-size: 1.55556rem; } }

@media (min-width: 1400px) {
  small,
  .text-small {
    font-size: 1.4rem; } }

@media (min-width: 320px) {
  small,
  .text-small {
    line-height: 5rem; } }

@media (min-width: 480px) {
  small,
  .text-small {
    line-height: 4.54545rem; } }

@media (min-width: 545px) {
  small,
  .text-small {
    line-height: 4.16667rem; } }

@media (min-width: 768px) {
  small,
  .text-small {
    line-height: 3.33333rem; } }

@media (min-width: 992px) {
  small,
  .text-small {
    line-height: 3.125rem; } }

@media (min-width: 1200px) {
  small,
  .text-small {
    line-height: 2.77778rem; } }

@media (min-width: 1400px) {
  small,
  .text-small {
    line-height: 2.5rem; } }

a {
  cursor: pointer;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  a:focus, a:hover {
    outline: none;
    text-decoration: underline; }

.btn-download {
  font-size: 0; }
  .btn-download:before {
    content: "\e927"; }
    @media (min-width: 320px) {
      .btn-download:before {
        font-size: 4rem; } }
    @media (min-width: 480px) {
      .btn-download:before {
        font-size: 3.63636rem; } }
    @media (min-width: 545px) {
      .btn-download:before {
        font-size: 3.33333rem; } }
    @media (min-width: 768px) {
      .btn-download:before {
        font-size: 2.66667rem; } }
    @media (min-width: 992px) {
      .btn-download:before {
        font-size: 2.5rem; } }
  .btn-download:focus, .btn-download:hover {
    text-decoration: none; }

.btn {
  cursor: pointer;
  display: inline-block;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  border: none;
  padding: 1.5rem 6rem;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-transform: uppercase;
  font-weight: 700; }
  @media (min-width: 320px) {
    .btn {
      font-size: 3rem; } }
  @media (min-width: 480px) {
    .btn {
      font-size: 2.72727rem; } }
  @media (min-width: 545px) {
    .btn {
      font-size: 2.5rem; } }
  @media (min-width: 768px) {
    .btn {
      font-size: 2rem; } }
  @media (min-width: 992px) {
    .btn {
      font-size: 1.875rem; } }
  @media (min-width: 1200px) {
    .btn {
      font-size: 1.66667rem; } }
  @media (min-width: 1400px) {
    .btn {
      font-size: 1.5rem; } }
  .btn:focus, .btn:hover {
    text-decoration: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }

.image-top-center {
  position: top center !important; }

.image-top-left {
  position: top left !important; }

.image-top-right {
  position: top left !important; }

.image-bottom-center {
  position: bottom center !important; }

.image-bottom-left {
  position: bottom left !important; }

.image-bottom-right {
  position: bottom right !important; }

main {
  min-height: 40rem; }
  main ol {
    margin: 0;
    padding: 0;
    list-style: none;
    counter-reset: my-ol; }
    main ol li {
      position: relative;
      margin: 0 0 1.5rem 0;
      padding: 0 0 0 30px; }
      main ol li:before {
        content: counter(my-ol) ".";
        counter-increment: my-ol;
        position: absolute;
        left: 0;
        top: 3px;
        font-family: "proxima-nova", sans-serif !important;
        font-weight: bold; }
  main ul {
    margin: 0;
    padding: 0;
    list-style: none; }
    main ul li {
      margin: 0 0 1.5rem 0;
      padding: 0 0 0 30px;
      position: relative; }
      main ul li:before {
        content: "";
        width: 6px;
        height: 6px;
        position: absolute;
        top: 7px;
        left: 0;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%; }
  main table {
    width: 100%; }
    main table tr td {
      padding: 1rem 1.5rem; }
  main .respo-table table {
    width: 100%; }
    main .respo-table table thead {
      display: none; }
      @media (min-width: 768px) {
        main .respo-table table thead {
          display: table-header-group; } }
      main .respo-table table thead tr th {
        font-weight: bold;
        padding: 1rem 1.5rem; }
    main .respo-table table tbody tr {
      display: block;
      border-bottom: none;
      margin: 0 0 2rem 0; }
      @media (min-width: 768px) {
        main .respo-table table tbody tr {
          display: table-row;
          margin: 0;
          border: none; } }
      main .respo-table table tbody tr td {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: space-between;
        -moz-box-pack: space-between;
        -ms-flex-pack: space-between;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        width: 100%; }
        @media (min-width: 768px) {
          main .respo-table table tbody tr td {
            display: table-cell;
            border: none;
            width: auto; } }
        main .respo-table table tbody tr td:before {
          content: attr(data-th);
          display: block;
          text-align: left;
          font-family: "proxima-nova", sans-serif !important;
          margin: 0 1.5rem 0 0; }
          @media (min-width: 768px) {
            main .respo-table table tbody tr td:before {
              display: none; } }

.label-form,
label {
  display: block;
  margin: 1rem 0 0.5rem 0;
  font-size: 3.2rem; }
  @media (min-width: 480px) {
    .label-form,
    label {
      font-size: 2.90909rem; } }
  @media (min-width: 545px) {
    .label-form,
    label {
      font-size: 2.66667rem; } }
  @media (min-width: 768px) {
    .label-form,
    label {
      font-size: 2.13333rem; } }
  @media (min-width: 992px) {
    .label-form,
    label {
      font-size: 2rem; } }
  @media (min-width: 1200px) {
    .label-form,
    label {
      font-size: 1.77778rem; } }
  @media (min-width: 1400px) {
    .label-form,
    label {
      font-size: 1.6rem; } }

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type="number"] {
  -moz-appearance: textfield; }

.form-control,
textarea,
input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"] {
  width: 100%;
  padding: 0 1.5rem;
  font-size: 3.2rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  border-radius: 0.5rem; }
  @media (min-width: 320px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      height: 8rem; } }
  @media (min-width: 480px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      height: 7.27273rem; } }
  @media (min-width: 545px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      height: 6.66667rem; } }
  @media (min-width: 768px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      height: 5.33333rem; } }
  @media (min-width: 992px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      height: 5rem; } }
  @media (min-width: 480px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 2.90909rem; } }
  @media (min-width: 545px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 2.66667rem; } }
  @media (min-width: 768px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 2.13333rem; } }
  @media (min-width: 992px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 2rem; } }
  @media (min-width: 1200px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 1.77778rem; } }
  @media (min-width: 1400px) {
    .form-control,
    textarea,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 1.6rem; } }
  .form-control:focus,
  textarea:focus,
  input[type="text"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: 0; }

textarea.form-control,
textarea {
  padding: 1.5rem; }
  @media (min-width: 320px) {
    textarea.form-control,
    textarea {
      height: 20rem; } }
  @media (min-width: 480px) {
    textarea.form-control,
    textarea {
      height: 18.18182rem; } }
  @media (min-width: 545px) {
    textarea.form-control,
    textarea {
      height: 16.66667rem; } }
  @media (min-width: 768px) {
    textarea.form-control,
    textarea {
      height: 15rem; } }

input[type="submit"] {
  cursor: pointer; }

.required label:before {
  content: "*";
  display: inline-block;
  vertical-align: 0.6rem;
  margin: 0 2px 0 0;
  font-size: 2rem; }
  @media (min-width: 480px) {
    .required label:before {
      font-size: 1.81818rem; } }
  @media (min-width: 545px) {
    .required label:before {
      font-size: 1.66667rem; } }
  @media (min-width: 768px) {
    .required label:before {
      font-size: 1.33333rem; } }
  @media (min-width: 992px) {
    .required label:before {
      font-size: 1.25rem; } }
  @media (min-width: 1200px) {
    .required label:before {
      font-size: 1.11111rem; } }
  @media (min-width: 1400px) {
    .required label:before {
      font-size: 1rem; } }

label.required:after {
  content: "*";
  display: inline-block;
  vertical-align: 0.6rem;
  margin: 0 2px 0 0;
  font-size: 2rem; }
  @media (min-width: 480px) {
    label.required:after {
      font-size: 1.81818rem; } }
  @media (min-width: 545px) {
    label.required:after {
      font-size: 1.66667rem; } }
  @media (min-width: 768px) {
    label.required:after {
      font-size: 1.33333rem; } }
  @media (min-width: 992px) {
    label.required:after {
      font-size: 1.25rem; } }
  @media (min-width: 1200px) {
    label.required:after {
      font-size: 1.11111rem; } }
  @media (min-width: 1400px) {
    label.required:after {
      font-size: 1rem; } }

.file .uploader-upload {
  display: none; }

.file label {
  display: inline-block;
  padding: 1rem 2rem;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 3.2rem;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  max-width: 24rem;
  margin: 0;
  width: 100%;
  text-align: center;
  cursor: pointer;
  letter-spacing: 1px;
  outline: 0 !important; }
  @media (min-width: 480px) {
    .file label {
      font-size: 2.90909rem; } }
  @media (min-width: 545px) {
    .file label {
      font-size: 2.66667rem; } }
  @media (min-width: 768px) {
    .file label {
      font-size: 2.13333rem; } }
  @media (min-width: 992px) {
    .file label {
      font-size: 2rem; } }
  @media (min-width: 1200px) {
    .file label {
      font-size: 1.77778rem; } }
  @media (min-width: 1400px) {
    .file label {
      font-size: 1.6rem; } }

.file .file-name {
  padding: 0.5rem; }

.form-validation-error select,
.form-validation-error .selectric,
.form-validation-error input[type="text"],
.form-validation-error input[type="password"],
.form-validation-error input[type="email"],
.form-validation-error input[type="number"],
.form-validation-error textarea,
.Error:not(.form-validation-valid) select,
.Error:not(.form-validation-valid) .selectric,
.Error:not(.form-validation-valid) input[type="text"],
.Error:not(.form-validation-valid) input[type="password"],
.Error:not(.form-validation-valid) input[type="email"],
.Error:not(.form-validation-valid) input[type="number"],
.Error:not(.form-validation-valid) textarea {
  border: 1px solid #d40511 !important;
  -webkit-box-shadow: inset 0 0 0 1px #d40511;
  -moz-box-shadow: inset 0 0 0 1px #d40511;
  box-shadow: inset 0 0 0 1px #d40511;
  -webkit-animation-name: blink;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: ease;
  -webkit-animation-iteration-count: 2;
  -webkit-animation-direction: normal;
  -moz-animation-name: blink;
  -moz-animation-duration: 1s;
  -moz-animation-timing-function: ease;
  -moz-animation-iteration-count: 2;
  -moz-animation-direction: normal;
  -o-animation-name: blink;
  -o-animation-duration: 1s;
  -o-animation-timing-function: ease;
  -o-animation-iteration-count: 2;
  -o-animation-direction: normal;
  animation-name: blink;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-iteration-count: 2;
  animation-direction: normal; }

.form-validation-error select,
.form-validation-error .selectric,
.form-validation-error input[type="text"],
.form-validation-error input[type="password"],
.form-validation-error input[type="email"],
.form-validation-error input[type="number"],
.form-validation-error textarea {
  padding-right: 6rem; }

@-webkit-keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

@-moz-keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

@-ms-keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

@keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

.error-span {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  background: #ebebeb;
  position: absolute;
  top: 1rem;
  right: 1rem;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%; }
  @media (min-width: 320px) {
    .error-span {
      height: 6rem; } }
  @media (min-width: 480px) {
    .error-span {
      height: 5.45455rem; } }
  @media (min-width: 545px) {
    .error-span {
      height: 5rem; } }
  @media (min-width: 768px) {
    .error-span {
      height: 4rem; } }
  @media (min-width: 320px) {
    .error-span {
      width: 6rem; } }
  @media (min-width: 480px) {
    .error-span {
      width: 5.45455rem; } }
  @media (min-width: 545px) {
    .error-span {
      width: 5rem; } }
  @media (min-width: 768px) {
    .error-span {
      width: 4rem; } }
  @media (min-width: 480px) {
    .error-span {
      top: 0.90909rem; } }
  @media (min-width: 545px) {
    .error-span {
      top: 0.83333rem; } }
  @media (min-width: 768px) {
    .error-span {
      top: 0.66667rem; } }
  @media (min-width: 992px) {
    .error-span {
      top: 0.625rem; } }
  @media (min-width: 1200px) {
    .error-span {
      top: 0.55556rem; } }
  @media (min-width: 1400px) {
    .error-span {
      top: 0.5rem; } }
  @media (min-width: 480px) {
    .error-span {
      right: 0.90909rem; } }
  @media (min-width: 545px) {
    .error-span {
      right: 0.83333rem; } }
  @media (min-width: 768px) {
    .error-span {
      right: 0.66667rem; } }
  @media (min-width: 992px) {
    .error-span {
      right: 0.625rem; } }
  @media (min-width: 1200px) {
    .error-span {
      right: 0.55556rem; } }
  @media (min-width: 1400px) {
    .error-span {
      right: 0.5rem; } }

div.form-validation-error .error-span:before {
  content: "\2715";
  color: red; }

div.form-validation-valid .FormErrorLabel,
div.form-validation-valid .EditingFormErrorLabel {
  display: none; }

div.form-validation-valid .error-span:before {
  content: "\2713";
  color: green; }

div.form-validation-error,
div.form-validation-valid {
  position: relative; }

.ErrorLabel,
.form-control-error,
div.form-validation-error,
.EditingFormErrorLabel {
  font-size: 3.2rem;
  color: #d40511;
  display: block;
  margin: 0.5rem 0 0 0; }
  @media (min-width: 480px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 2.90909rem; } }
  @media (min-width: 545px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 2.66667rem; } }
  @media (min-width: 768px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 2.13333rem; } }
  @media (min-width: 992px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 2rem; } }
  @media (min-width: 1200px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 1.77778rem; } }
  @media (min-width: 1400px) {
    .ErrorLabel,
    .form-control-error,
    div.form-validation-error,
    .EditingFormErrorLabel {
      font-size: 1.6rem; } }

.radio input[type="radio"] {
  display: none; }
  .radio input[type="radio"] + label {
    position: relative;
    padding: 0 0 1rem 25px;
    display: inline-block;
    margin: 0; }
    .radio input[type="radio"] + label:before {
      content: "";
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      display: block;
      position: absolute;
      left: 0;
      top: 3px;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
    .radio input[type="radio"] + label:after {
      content: "";
      display: block;
      left: 5px;
      top: 8px;
      width: 6px;
      height: 6px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      position: absolute;
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
  .radio input[type="radio"]:checked + label:after {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }

.checkbox input[type="checkbox"] {
  display: none; }
  .checkbox input[type="checkbox"] + label {
    position: relative;
    padding: 0 0 1rem 25px;
    margin: 0;
    display: inline-block; }
    .checkbox input[type="checkbox"] + label:before {
      content: "";
      width: 16px;
      height: 16px;
      display: block;
      position: absolute;
      left: 0;
      top: 3px;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
    .checkbox input[type="checkbox"] + label:after {
      content: "";
      display: block;
      left: 5px;
      top: 8px;
      width: 6px;
      height: 6px;
      position: absolute;
      opacity: 0;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
  .checkbox input[type="checkbox"]:checked + label:after {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }

.row-form {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -1rem; }

.row-form [class^="col"] {
  padding-left: 1rem;
  padding-right: 1rem; }

#request-form .selectric {
  width: 100%; }

.selectric-wrapper {
  position: relative;
  cursor: pointer;
  margin-bottom: 0; }

.selectric-responsive {
  width: 100%; }

.selectric {
  position: relative;
  margin: 0 1rem 0 0;
  width: 40rem;
  overflow: hidden;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  border-radius: 0.5rem; }
  .selectric .label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: none;
    padding: 0 2rem 0 2rem; }
    @media (min-width: 320px) {
      .selectric .label {
        line-height: 8rem; } }
    @media (min-width: 480px) {
      .selectric .label {
        line-height: 7.27273rem; } }
    @media (min-width: 545px) {
      .selectric .label {
        line-height: 6.66667rem; } }
    @media (min-width: 768px) {
      .selectric .label {
        line-height: 5.33333rem; } }
    @media (min-width: 992px) {
      .selectric .label {
        line-height: 5rem; } }
    @media (min-width: 320px) {
      .selectric .label {
        height: 8rem; } }
    @media (min-width: 480px) {
      .selectric .label {
        height: 7.27273rem; } }
    @media (min-width: 545px) {
      .selectric .label {
        height: 6.66667rem; } }
    @media (min-width: 768px) {
      .selectric .label {
        height: 5.33333rem; } }
    @media (min-width: 992px) {
      .selectric .label {
        height: 5rem; } }
  .selectric .button {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 5rem;
    height: 100%;
    text-align: center;
    transition: all ease 200ms;
    font-size: 0;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center; }
    .selectric .button:after {
      font-size: 1.6rem;
      content: ""; }
      @media (min-width: 480px) {
        .selectric .button:after {
          font-size: 1.45455rem; } }
      @media (min-width: 545px) {
        .selectric .button:after {
          font-size: 1.33333rem; } }
      @media (min-width: 768px) {
        .selectric .button:after {
          font-size: 1.06667rem; } }
      @media (min-width: 992px) {
        .selectric .button:after {
          font-size: 1rem; } }
      @media (min-width: 1200px) {
        .selectric .button:after {
          font-size: 0.88889rem; } }
      @media (min-width: 1400px) {
        .selectric .button:after {
          font-size: 0.8rem; } }

.selectric-open {
  z-index: 9999; }
  .selectric-open .selectric .button {
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); }
  .selectric-open .selectric-items {
    display: block; }

.selectric-disabled {
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
  cursor: default;
  user-select: none; }

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0; }
  .selectric-hide-select select {
    position: absolute;
    left: -100%; }
  .selectric-hide-select.selectric-is-native {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10; }
    .selectric-hide-select.selectric-is-native select {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      border: none;
      z-index: 1;
      box-sizing: border-box;
      opacity: 0; }

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important; }

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important; }

/* Items box */
.selectric-items {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  overflow: hidden;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  border-top: 1px;
  z-index: -1; }
  .selectric-items .selectric-scroll {
    height: 100%;
    overflow: auto; }
  .selectric-above .selectric-items {
    top: auto;
    bottom: 100%; }
  .selectric-items ul, .selectric-items li {
    list-style: none;
    padding: 0;
    margin: 0;
    line-height: 2rem;
    min-height: 2rem; }
  .selectric-items li {
    display: block;
    padding: 1rem 2rem;
    cursor: pointer;
    transition: all ease 200ms; }
    .selectric-items li:before {
      display: none; }
  .selectric-items .disabled {
    opacity: 0.5;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
    cursor: default !important;
    background: none !important;
    user-select: none; }
  .selectric-items .selectric-group .selectric-group-label {
    font-weight: bold;
    padding-left: 1rem;
    cursor: default;
    user-select: none;
    background: none; }
  .selectric-items .selectric-group.disabled li {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }
  .selectric-items .selectric-group li {
    padding-left: 2.5rem; }

/*!
 * Datepicker for Bootstrap v1.8.0 (https://github.com/uxsolutions/bootstrap-datepicker)
 *
 * Licensed under the Apache License v2.0 (http://www.apache.org/licenses/LICENSE-2.0)
 */
.datepicker {
  padding: 0.4rem;
  -webkit-border-radius: 0.4rem;
  -moz-border-radius: 0.4rem;
  -ms-border-radius: 0.4rem;
  border-radius: 0.4rem;
  direction: ltr;
  font-size: 2.4rem; }
  @media (min-width: 480px) {
    .datepicker {
      font-size: 2.18182rem; } }
  @media (min-width: 545px) {
    .datepicker {
      font-size: 2rem; } }
  @media (min-width: 768px) {
    .datepicker {
      font-size: 1.6rem; } }
  @media (min-width: 992px) {
    .datepicker {
      font-size: 1.5rem; } }
  @media (min-width: 1200px) {
    .datepicker {
      font-size: 1.33333rem; } }
  @media (min-width: 1400px) {
    .datepicker {
      font-size: 1.2rem; } }
  .datepicker-inline {
    width: 22rem; }
  .datepicker-rtl {
    direction: rtl; }
    .datepicker-rtl.dropdown-menu {
      left: auto; }
    .datepicker-rtl table tr td span {
      float: right; }
  .datepicker-dropdown {
    top: 0;
    left: 0; }
    .datepicker-dropdown:before {
      content: '';
      display: inline-block;
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid #999;
      border-top: 0;
      border-bottom-color: rgba(0, 0, 0, 0.2);
      position: absolute; }
    .datepicker-dropdown:after {
      content: '';
      display: inline-block;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #fff;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #999;
      border-top: 0;
      position: absolute; }

.datepicker-dropdown.datepicker-orient-left:before {
  left: 6px; }

.datepicker-dropdown.datepicker-orient-left:after {
  left: 7px; }

.datepicker-dropdown.datepicker-orient-right:before {
  right: 6px; }

.datepicker-dropdown.datepicker-orient-right:after {
  right: 7px; }

.datepicker-dropdown.datepicker-orient-bottom:before {
  top: -7px; }

.datepicker-dropdown.datepicker-orient-bottom:after {
  top: -6px; }

.datepicker-dropdown.datepicker-orient-top:before {
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid #999; }

.datepicker-dropdown.datepicker-orient-top:after {
  top: -6px;
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid #fff; }

.datepicker table {
  margin: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.datepicker td,
.datepicker th {
  text-align: center;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 0.4rem;
  -moz-border-radius: 0.4rem;
  -ms-border-radius: 0.4rem;
  border-radius: 0.4rem;
  border: none; }

.table-striped .datepicker table tr td,
.table-striped .datepicker table tr th {
  background-color: transparent; }

.datepicker table tr td.day:hover,
.datepicker table tr td.day.focused {
  background: #eee;
  cursor: pointer; }

.datepicker table tr td.old,
.datepicker table tr td.new {
  color: #999; }

.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
  background: none;
  color: #999;
  cursor: default; }

.datepicker table tr td.highlighted {
  background: #d9edf7;
  border-radius: 0; }

.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
  background-color: #4c4c4c;
  color: #fff;
  background-image: -webkit-linear-gradient(top, #4c4c4c 0%, #333333 100%);
  background-image: -o-linear-gradient(top, #4c4c4c 0%, #333333 100%);
  background-image: linear-gradient(to bottom, #4c4c4c 0%, #333333 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF4C4C4C', endColorstr='#FF333333', GradientType=0); }

.datepicker table tr td.today:hover,
.datepicker table tr td.today:hover:hover,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today.disabled:hover:hover,
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today:hover.disabled,
.datepicker table tr td.today.disabled.disabled,
.datepicker table tr td.today.disabled:hover.disabled,
.datepicker table tr td.today[disabled],
.datepicker table tr td.today:hover[disabled],
.datepicker table tr td.today.disabled[disabled],
.datepicker table tr td.today.disabled:hover[disabled] {
  background-color: #fdf59a; }

.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active {
  background-color: #fbf069 \9; }

.datepicker table tr td.today:hover:hover {
  color: #fff; }

.datepicker table tr td.today.active:hover {
  color: #fff; }

.datepicker table tr td.range,
.datepicker table tr td.range:hover,
.datepicker table tr td.range.disabled,
.datepicker table tr td.range.disabled:hover {
  background: #eee;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }

.datepicker table tr td.range.today,
.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.disabled:hover {
  background-color: #f3d17a;
  background-image: -moz-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: -ms-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f3c17a), to(#f3e97a));
  background-image: -webkit-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: -o-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3c17a', endColorstr='#f3e97a', GradientType=0);
  border-color: #f3e97a #f3e97a #edde34;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }

.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today:hover:hover,
.datepicker table tr td.range.today.disabled:hover,
.datepicker table tr td.range.today.disabled:hover:hover,
.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover:active,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled:hover.active,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today:hover.disabled,
.datepicker table tr td.range.today.disabled.disabled,
.datepicker table tr td.range.today.disabled:hover.disabled,
.datepicker table tr td.range.today[disabled],
.datepicker table tr td.range.today:hover[disabled],
.datepicker table tr td.range.today.disabled[disabled],
.datepicker table tr td.range.today.disabled:hover[disabled] {
  background-color: #f3e97a; }

.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover:active,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled:hover.active {
  background-color: #efe24b \9; }

.datepicker table tr td.selected,
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled:hover {
  background-color: #9e9e9e;
  background-image: -moz-linear-gradient(to bottom, #b3b3b3, #808080);
  background-image: -ms-linear-gradient(to bottom, #b3b3b3, #808080);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#b3b3b3), to(#808080));
  background-image: -webkit-linear-gradient(to bottom, #b3b3b3, #808080);
  background-image: -o-linear-gradient(to bottom, #b3b3b3, #808080);
  background-image: linear-gradient(to bottom, #b3b3b3, #808080);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3b3b3', endColorstr='#808080', GradientType=0);
  border-color: #808080 #808080 #595959;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25); }

.datepicker table tr td.selected:hover,
.datepicker table tr td.selected:hover:hover,
.datepicker table tr td.selected.disabled:hover,
.datepicker table tr td.selected.disabled:hover:hover,
.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover:active,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected.active,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled:hover.active,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected:hover.disabled,
.datepicker table tr td.selected.disabled.disabled,
.datepicker table tr td.selected.disabled:hover.disabled,
.datepicker table tr td.selected[disabled],
.datepicker table tr td.selected:hover[disabled],
.datepicker table tr td.selected.disabled[disabled],
.datepicker table tr td.selected.disabled:hover[disabled] {
  background-color: #808080; }

.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover:active,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected.active,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled:hover.active {
  background-color: #666666 \9; }

.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
  background-color: #f8b620;
  background-image: -webkit-linear-gradient(top, #f8b620 0%, #de9c07 100%);
  background-image: -o-linear-gradient(top, #f8b620 0%, #de9c07 100%);
  background-image: linear-gradient(to bottom, #f8b620 0%, #de9c07 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFF8B620', endColorstr='#FFDE9C07', GradientType=0);
  color: #fff; }

.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active[disabled],
.datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active.disabled:hover[disabled] {
  background-color: #f8b620;
  background-image: -webkit-linear-gradient(top, #f8b620 0%, #de9c07 100%);
  background-image: -o-linear-gradient(top, #f8b620 0%, #de9c07 100%);
  background-image: linear-gradient(to bottom, #f8b620 0%, #de9c07 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFF8B620', endColorstr='#FFDE9C07', GradientType=0);
  color: #fff; }

.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active {
  background-color: #f8b620;
  background-image: -webkit-linear-gradient(top, #f8b620 0%, #de9c07 100%);
  background-image: -o-linear-gradient(top, #f8b620 0%, #de9c07 100%);
  background-image: linear-gradient(to bottom, #f8b620 0%, #de9c07 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFF8B620', endColorstr='#FFDE9C07', GradientType=0);
  color: #fff; }

.datepicker table tr td span {
  display: block;
  width: 23%;
  height: 54px;
  line-height: 54px;
  float: left;
  margin: 1%;
  cursor: pointer;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px; }

.datepicker table tr td span:hover {
  background-color: #eee; }

.datepicker table tr td span.focused {
  background-color: #f8b620;
  background-image: -webkit-linear-gradient(top, #f8b620 0%, #de9c07 100%);
  background-image: -o-linear-gradient(top, #f8b620 0%, #de9c07 100%);
  background-image: linear-gradient(to bottom, #f8b620 0%, #de9c07 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFF8B620', endColorstr='#FFDE9C07', GradientType=0);
  color: #fff; }

.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover {
  background: none;
  color: #999;
  cursor: default; }

.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
  background-color: #f8b620;
  background-image: -webkit-linear-gradient(top, #f8b620 0%, #de9c07 100%);
  background-image: -o-linear-gradient(top, #f8b620 0%, #de9c07 100%);
  background-image: linear-gradient(to bottom, #f8b620 0%, #de9c07 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFF8B620', endColorstr='#FFDE9C07', GradientType=0);
  color: #fff; }

.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active:hover.disabled,
.datepicker table tr td span.active.disabled.disabled,
.datepicker table tr td span.active.disabled:hover.disabled,
.datepicker table tr td span.active[disabled],
.datepicker table tr td span.active:hover[disabled],
.datepicker table tr td span.active.disabled[disabled],
.datepicker table tr td span.active.disabled:hover[disabled] {
  background-color: #4c4c4c;
  color: #fff;
  background-image: -webkit-linear-gradient(top, #4c4c4c 0%, #333333 100%);
  background-image: -o-linear-gradient(top, #4c4c4c 0%, #333333 100%);
  background-image: linear-gradient(to bottom, #4c4c4c 0%, #333333 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF4C4C4C', endColorstr='#FF333333', GradientType=0); }

.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active {
  background-color: #4c4c4c;
  color: #fff;
  background-image: -webkit-linear-gradient(top, #4c4c4c 0%, #333333 100%);
  background-image: -o-linear-gradient(top, #4c4c4c 0%, #333333 100%);
  background-image: linear-gradient(to bottom, #4c4c4c 0%, #333333 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF4C4C4C', endColorstr='#FF333333', GradientType=0); }

.datepicker table tr td span.old,
.datepicker table tr td span.new {
  color: #999; }

.datepicker .datepicker-switch {
  width: 145px; }

.datepicker .datepicker-switch,
.datepicker .prev,
.datepicker .next,
.datepicker tfoot tr th {
  cursor: pointer; }

.datepicker .datepicker-switch:hover,
.datepicker .prev:hover,
.datepicker .next:hover,
.datepicker tfoot tr th:hover {
  background: #eee; }

.datepicker .prev.disabled,
.datepicker .next.disabled {
  visibility: hidden; }

.datepicker .cw {
  font-size: 10px;
  width: 12px;
  padding: 0 2px 0 5px;
  vertical-align: middle; }

.input-append.date .add-on,
.input-prepend.date .add-on {
  cursor: pointer; }

.input-append.date .add-on i,
.input-prepend.date .add-on i {
  margin-top: 3px; }

.input-daterange input {
  text-align: center; }

.input-daterange input:first-child {
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px; }

.input-daterange input:last-child {
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0; }

.input-daterange .add-on {
  display: inline-block;
  width: auto;
  min-width: 16px;
  height: 18px;
  padding: 4px 5px;
  font-weight: normal;
  line-height: 18px;
  text-align: center;
  text-shadow: 0 1px 0 #fff;
  vertical-align: middle;
  background-color: #eee;
  border: 1px solid #ccc;
  margin-left: -5px;
  margin-right: -5px; }

/*# sourceMappingURL=bootstrap-datepicker.css.map */
.slider-init .main-slider {
  width: 100%;
  background-repeat: no-repeat !important;
  -webkit-background-size: cover !important;
  background-size: cover !important;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row; }
  .slider-init .main-slider-inner {
    width: 100%;
    padding: 6rem 0 6rem 0;
    min-height: -webkit-calc(100vh - 7rem);
    min-height: -moz-calc(100vh - 7rem);
    min-height: calc(100vh - 7rem);
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center; }
    .slider-init .main-slider-inner-content {
      max-width: 75rem; }
      .slider-init .main-slider-inner-content-text {
        color: #fff; }
        .slider-init .main-slider-inner-content-text h1,
        .slider-init .main-slider-inner-content-text h2 {
          color: #fff; }
      .slider-init .main-slider-inner-content-btn {
        padding: 3rem 0 0 0; }
    .slider-init .main-slider-inner .pux-container {
      width: 100%; }
  .slider-init .main-slider.top-center {
    background-position: top center !important; }
  .slider-init .main-slider.top-left {
    background-position: top left !important; }
  .slider-init .main-slider.top-right {
    background-position: top right !important; }
  .slider-init .main-slider.center-center {
    background-position: center center !important; }
  .slider-init .main-slider.bottom-center {
    background-position: bottom center !important; }
  .slider-init .main-slider.bottom-left {
    background-position: bottom left !important; }
  .slider-init .main-slider.bottom-right {
    background-position: bottom right !important; }

.slider-init .slick-arrow {
  position: absolute;
  top: 50%;
  margin-top: -2rem;
  width: 4rem;
  height: 4rem;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  border: none;
  font-size: 0;
  background: transparent;
  outline: 0;
  z-index: 22;
  display: flex;
  align-items: center;
  cursor: pointer; }
  .slider-init .slick-arrow:before {
    font-size: 20px; }
  .slider-init .slick-arrow.slick-prev {
    left: 2rem; }
    .slider-init .slick-arrow.slick-prev:before {
      content: "";
      display: block;
      opacity: 0.5;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
      filter: alpha(opacity=50);
      -ms-transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg); }
  .slider-init .slick-arrow.slick-next {
    right: 2rem; }
    .slider-init .slick-arrow.slick-next:before {
      content: "";
      display: block;
      -ms-transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
      opacity: 0.5;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
      filter: alpha(opacity=50); }

.slider-init .slick-dots {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  position: relative;
  top: -11rem;
  left: 0;
  width: 100%;
  max-width: 169rem;
  margin: auto;
  bottom: 8rem;
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 768px) {
    .slider-init .slick-dots {
      padding-left: 4.5rem;
      padding-right: 4.5rem; } }
  .slider-init .slick-dots li {
    margin: 0;
    padding: 0 2rem 0 0; }
    .slider-init .slick-dots li:before {
      display: none; }
    .slider-init .slick-dots li:last-child {
      padding: 0; }
    .slider-init .slick-dots li button {
      font-size: 0;
      outline: 0;
      width: 6rem;
      height: 6rem;
      border: none;
      background: rgba(30, 30, 30, 0.2);
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      padding: 0;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      -webkit-box-pack: center;
      -moz-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center; }
      @media (min-width: 480px) {
        .slider-init .slick-dots li button {
          width: 5.45455rem; } }
      @media (min-width: 545px) {
        .slider-init .slick-dots li button {
          width: 5rem; } }
      @media (min-width: 768px) {
        .slider-init .slick-dots li button {
          width: 4rem; } }
      @media (min-width: 992px) {
        .slider-init .slick-dots li button {
          width: 3.75rem; } }
      @media (min-width: 1200px) {
        .slider-init .slick-dots li button {
          width: 3.33333rem; } }
      @media (min-width: 1400px) {
        .slider-init .slick-dots li button {
          width: 3rem; } }
      @media (min-width: 480px) {
        .slider-init .slick-dots li button {
          height: 5.45455rem; } }
      @media (min-width: 545px) {
        .slider-init .slick-dots li button {
          height: 5rem; } }
      @media (min-width: 768px) {
        .slider-init .slick-dots li button {
          height: 4rem; } }
      @media (min-width: 992px) {
        .slider-init .slick-dots li button {
          height: 3.75rem; } }
      @media (min-width: 1200px) {
        .slider-init .slick-dots li button {
          height: 3.33333rem; } }
      @media (min-width: 1400px) {
        .slider-init .slick-dots li button {
          height: 3rem; } }
      .slider-init .slick-dots li button:before {
        content: "";
        width: 1.2rem;
        height: 1.2rem;
        background: #fff;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%; }
        @media (min-width: 480px) {
          .slider-init .slick-dots li button:before {
            width: 1.09091rem; } }
        @media (min-width: 545px) {
          .slider-init .slick-dots li button:before {
            width: 1rem; } }
        @media (min-width: 768px) {
          .slider-init .slick-dots li button:before {
            width: 0.8rem; } }
        @media (min-width: 992px) {
          .slider-init .slick-dots li button:before {
            width: 0.75rem; } }
        @media (min-width: 1200px) {
          .slider-init .slick-dots li button:before {
            width: 0.66667rem; } }
        @media (min-width: 1400px) {
          .slider-init .slick-dots li button:before {
            width: 0.6rem; } }
        @media (min-width: 480px) {
          .slider-init .slick-dots li button:before {
            height: 1.09091rem; } }
        @media (min-width: 545px) {
          .slider-init .slick-dots li button:before {
            height: 1rem; } }
        @media (min-width: 768px) {
          .slider-init .slick-dots li button:before {
            height: 0.8rem; } }
        @media (min-width: 992px) {
          .slider-init .slick-dots li button:before {
            height: 0.75rem; } }
        @media (min-width: 1200px) {
          .slider-init .slick-dots li button:before {
            height: 0.66667rem; } }
        @media (min-width: 1400px) {
          .slider-init .slick-dots li button:before {
            height: 0.6rem; } }
    .slider-init .slick-dots li.slick-active button {
      background: #1e1e1e; }

.about-content-btns {
  text-align: center;
  padding: 5rem 0 0 0; }
  .about-content-btns .btn {
    margin-top: 15px;
    margin-left: 7.5px;
    margin-right: 7.5px; }
    @media (min-width: 768px) {
      .about-content-btns .btn {
        margin-top: 0; } }

.notification-card {
  filter: drop-shadow(0 2rem 7rem rgba(0, 0, 0, 0.07));
  width: 100%; }
  .notification-card a {
    display: block;
    width: 100%;
    padding: 2rem; }
    .notification-card a:focus, .notification-card a:hover {
      text-decoration: none; }

.circle-btn {
  margin: auto;
  text-align: center;
  position: absolute;
  width: 5rem;
  height: 5rem;
  bottom: -2.5rem;
  z-index: 6;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%); }
  .circle-btn span {
    position: relative;
    font-size: 3rem;
    display: block;
    width: 5rem;
    height: 5rem;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    line-height: 1;
    box-shadow: 0 1px #dedede; }

.pux-container, .widget-container {
  position: relative; }

.big-image {
  width: 100%; }

.cta-text {
  text-align: center;
  padding: 0 0 5rem 0; }
  .cta-text-content {
    padding: 5rem 0; }

.col-xs-12.col-sm-6.col-lg-4,
.col-xs-12.col-sm-6.col-lg-8 {
  padding: 7.5px;
  display: flex; }

.middle-card,
.big-card,
.small-card {
  padding: 0 !important;
  width: 100%; }
  .middle-card .card-item-image,
  .big-card .card-item-image,
  .small-card .card-item-image {
    position: relative;
    width: 100%;
    overflow: hidden; }
    .middle-card .card-item-image-bgr,
    .big-card .card-item-image-bgr,
    .small-card .card-item-image-bgr {
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: center !important;
      background-repeat: no-repeat !important;
      background-size: cover !important; }
  .middle-card .card-item-content,
  .big-card .card-item-content,
  .small-card .card-item-content {
    margin-bottom: 5rem; }

.big-card {
  height: 100%; }
  .big-card .card-item {
    height: 100%; }
    .big-card .card-item-image {
      min-height: 30rem; }
      @media (min-width: 992px) {
        .big-card .card-item-image {
          height: 100% !important; } }
    .big-card .card-item-content {
      padding-bottom: 10rem;
      margin-bottom: 0rem; }
      @media (min-width: 992px) {
        .big-card .card-item-content {
          padding-bottom: 2rem; } }
      @media (min-width: 992px) {
        .big-card .card-item-content-text {
          margin-bottom: 3rem; } }

.middle-card,
.big-card {
  filter: drop-shadow(0 2rem 7rem rgba(0, 0, 0, 0.07)); }
  .middle-card .card-item-image,
  .big-card .card-item-image {
    height: 30rem; }
    .middle-card .card-item-image-bgr,
    .big-card .card-item-image-bgr {
      height: 100% !important; }

@media (min-width: 992px) {
  .big-card .card-item,
  .small-card .card-item {
    display: flex;
    align-items: flex-start;
    flex-direction: row; } }

.big-card .card-item-image,
.small-card .card-item-image {
  width: 100%; }
  @media (min-width: 992px) {
    .big-card .card-item-image,
    .small-card .card-item-image {
      width: 50%; } }
  .big-card .card-item-image-bgr,
  .small-card .card-item-image-bgr {
    height: 100%; }

.big-card .card-item-label,
.small-card .card-item-label {
  padding-left: 0; }

@media (min-width: 992px) {
  .big-card .card-item-content,
  .small-card .card-item-content {
    flex: 1;
    position: relative; } }

.big-card .card-item-content-ref,
.small-card .card-item-content-ref {
  padding-top: 2rem; }
  @media (min-width: 992px) {
    .big-card .card-item-content-ref,
    .small-card .card-item-content-ref {
      padding-top: 0rem;
      position: absolute;
      left: 3rem;
      bottom: 3rem;
      padding: 0px;
      bottom: 2rem; } }

.small-card {
  margin: 0.75rem 0;
  padding: 0;
  background-color: transparent; }
  .small-card a:first-child .card-item {
    margin-top: 0 !important; }
  .small-card a:last-child .card-item {
    margin-bottom: 0 !important; }
  .small-card .card-item {
    min-height: 1rem;
    align-items: normal !important; }
    @media (min-width: 992px) {
      .small-card .card-item {
        min-height: 15rem; } }
    .small-card .card-item .h3 {
      padding: 0; }
      @media (min-width: 992px) {
        .small-card .card-item .h3 {
          min-height: 60px; } }
    .small-card .card-item-label {
      padding: 1rem 0; }
      @media (min-width: 992px) {
        .small-card .card-item-label {
          min-height: 50px; } }
    .small-card .card-item-image {
      width: 100%;
      height: 30rem; }
      @media (min-width: 992px) {
        .small-card .card-item-image {
          width: 33%;
          height: auto; } }
      .small-card .card-item-image-bgr {
        height: 100%; }
    .small-card .card-item-content {
      min-height: 15rem;
      margin-bottom: 0;
      padding: 0.5rem 3rem 1.5rem;
      display: flex;
      flex-wrap: wrap; }
      @media (min-width: 992px) {
        .small-card .card-item-content {
          min-height: 100%; } }
      .small-card .card-item-content .card-item-label {
        order: 2;
        width: 100%; }
        @media (min-width: 768px) {
          .small-card .card-item-content .card-item-label {
            order: 1; } }
      .small-card .card-item-content .card-item-content-title {
        order: 1;
        width: 100%;
        padding: 3rem 0rem 1.5rem; }
        @media (min-width: 768px) {
          .small-card .card-item-content .card-item-content-title {
            order: 2;
            padding: 0rem 0rem 5rem; } }
      .small-card .card-item-content .card-item-content-title {
        order: 2; }

.col-xs-12.col-lg-8 {
  padding: 7.5px; }

@media (min-width: 768px) {
  .card-item {
    display: flex;
    flex-direction: column; } }

.card-item-image {
  order: 1; }
  .card-item-image-label {
    display: none; }

.card-item-label {
  order: 2;
  font-weight: 700;
  padding: 3rem;
  padding-bottom: 1rem;
  position: relative; }
  @media (min-width: 320px) {
    .card-item-label {
      font-size: 3.2rem; } }
  @media (min-width: 480px) {
    .card-item-label {
      font-size: 2.90909rem; } }
  @media (min-width: 545px) {
    .card-item-label {
      font-size: 2.66667rem; } }
  @media (min-width: 768px) {
    .card-item-label {
      font-size: 2.13333rem; } }
  @media (min-width: 992px) {
    .card-item-label {
      font-size: 2rem; } }
  @media (min-width: 1200px) {
    .card-item-label {
      font-size: 1.77778rem; } }
  @media (min-width: 1400px) {
    .card-item-label {
      font-size: 1.6rem; } }
  @media (min-width: 768px) {
    .card-item-label {
      padding-bottom: 0; } }

.card-item-content {
  order: 3;
  padding: 3rem;
  padding-top: .5rem; }
  .card-item-content-ref {
    padding-top: 2rem;
    font-weight: 700; }
    @media (min-width: 992px) {
      .card-item-content-ref {
        padding-top: 0rem;
        position: absolute;
        left: 3rem;
        bottom: 3rem; } }
    @media (min-width: 320px) {
      .card-item-content-ref {
        font-size: 3.2rem; } }
    @media (min-width: 480px) {
      .card-item-content-ref {
        font-size: 2.90909rem; } }
    @media (min-width: 545px) {
      .card-item-content-ref {
        font-size: 2.66667rem; } }
    @media (min-width: 768px) {
      .card-item-content-ref {
        font-size: 2.13333rem; } }
    @media (min-width: 992px) {
      .card-item-content-ref {
        font-size: 2rem; } }
    @media (min-width: 1200px) {
      .card-item-content-ref {
        font-size: 1.77778rem; } }
    @media (min-width: 1400px) {
      .card-item-content-ref {
        font-size: 1.6rem; } }

.card-event .card-item-image {
  order: 2; }
  .card-event .card-item-image-label {
    display: block;
    text-align: center;
    padding: 1rem 1rem;
    width: 20%;
    font-weight: bold;
    margin: auto;
    position: relative; }
    @media (min-width: 320px) {
      .card-event .card-item-image-label {
        font-size: 3.2rem; } }
    @media (min-width: 480px) {
      .card-event .card-item-image-label {
        font-size: 2.90909rem; } }
    @media (min-width: 545px) {
      .card-event .card-item-image-label {
        font-size: 2.66667rem; } }
    @media (min-width: 768px) {
      .card-event .card-item-image-label {
        font-size: 2.13333rem; } }
    @media (min-width: 992px) {
      .card-event .card-item-image-label {
        font-size: 2rem; } }
    @media (min-width: 1200px) {
      .card-event .card-item-image-label {
        font-size: 1.77778rem; } }
    @media (min-width: 1400px) {
      .card-event .card-item-image-label {
        font-size: 1.6rem; } }

.card-event .card-item-label {
  order: 1;
  margin-top: -9px;
  text-align: center;
  padding: 2rem;
  font-weight: 700; }
  @media (min-width: 320px) {
    .card-event .card-item-label {
      font-size: 3.2rem; } }
  @media (min-width: 480px) {
    .card-event .card-item-label {
      font-size: 2.90909rem; } }
  @media (min-width: 545px) {
    .card-event .card-item-label {
      font-size: 2.66667rem; } }
  @media (min-width: 768px) {
    .card-event .card-item-label {
      font-size: 2.13333rem; } }
  @media (min-width: 992px) {
    .card-event .card-item-label {
      font-size: 2rem; } }

.card-event .card-item-content {
  padding-top: 3rem; }
  .card-event .card-item-content-date {
    font-weight: 700; }
    @media (min-width: 320px) {
      .card-event .card-item-content-date {
        font-size: 3.2rem; } }
    @media (min-width: 480px) {
      .card-event .card-item-content-date {
        font-size: 2.90909rem; } }
    @media (min-width: 545px) {
      .card-event .card-item-content-date {
        font-size: 2.66667rem; } }
    @media (min-width: 768px) {
      .card-event .card-item-content-date {
        font-size: 2.13333rem; } }
    @media (min-width: 992px) {
      .card-event .card-item-content-date {
        font-size: 2rem; } }
    @media (min-width: 1200px) {
      .card-event .card-item-content-date {
        font-size: 1.77778rem; } }
    @media (min-width: 1400px) {
      .card-event .card-item-content-date {
        font-size: 1.6rem; } }
  .card-event .card-item-content-ref {
    padding-top: 2rem;
    font-weight: 700; }
    @media (min-width: 992px) {
      .card-event .card-item-content-ref {
        padding-top: 0rem;
        position: absolute;
        left: 3rem;
        bottom: 3rem; } }
    @media (min-width: 320px) {
      .card-event .card-item-content-ref {
        font-size: 3.2rem; } }
    @media (min-width: 480px) {
      .card-event .card-item-content-ref {
        font-size: 2.90909rem; } }
    @media (min-width: 545px) {
      .card-event .card-item-content-ref {
        font-size: 2.66667rem; } }
    @media (min-width: 768px) {
      .card-event .card-item-content-ref {
        font-size: 2.13333rem; } }
    @media (min-width: 992px) {
      .card-event .card-item-content-ref {
        font-size: 2rem; } }
    @media (min-width: 1200px) {
      .card-event .card-item-content-ref {
        font-size: 1.77778rem; } }
    @media (min-width: 1400px) {
      .card-event .card-item-content-ref {
        font-size: 1.6rem; } }

.card-project .card-item-image {
  display: none; }

.row {
  justify-content: center; }

.small-card a:hover,
.small-card a:focus,
.middle-card a:hover,
.middle-card a:focus,
.big-card a:hover,
.big-card a:focus {
  text-decoration: none !important; }
  .small-card a:hover .card-item-image-bgr,
  .small-card a:focus .card-item-image-bgr,
  .middle-card a:hover .card-item-image-bgr,
  .middle-card a:focus .card-item-image-bgr,
  .big-card a:hover .card-item-image-bgr,
  .big-card a:focus .card-item-image-bgr {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1); }
  .small-card a:hover .card-item-content-title, .small-card a:hover .card-item-content-text,
  .small-card a:focus .card-item-content-title,
  .small-card a:focus .card-item-content-text,
  .middle-card a:hover .card-item-content-title,
  .middle-card a:hover .card-item-content-text,
  .middle-card a:focus .card-item-content-title,
  .middle-card a:focus .card-item-content-text,
  .big-card a:hover .card-item-content-title,
  .big-card a:hover .card-item-content-text,
  .big-card a:focus .card-item-content-title,
  .big-card a:focus .card-item-content-text {
    text-decoration: none; }
  .small-card a:hover .card-item-content-ref,
  .small-card a:focus .card-item-content-ref,
  .middle-card a:hover .card-item-content-ref,
  .middle-card a:focus .card-item-content-ref,
  .big-card a:hover .card-item-content-ref,
  .big-card a:focus .card-item-content-ref {
    text-decoration: underline; }

.card-item-content-ref:after,
.footer-ref:after {
  position: relative;
  bottom: 1px;
  display: inline-block;
  content: "\e902";
  vertical-align: middle;
  -webkit-transform: scale(0.5) rotate(270deg);
  -moz-transform: scale(0.5) rotate(270deg);
  -ms-transform: scale(0.5) rotate(270deg);
  -o-transform: scale(0.5) rotate(270deg);
  transform: scale(0.5) rotate(270deg); }

.small-image {
  text-align: center; }
  .small-image img {
    width: 33%;
    margin: 10rem auto; }

.big-image img {
  width: 100%; }

.embed-video {
  position: relative; }
  .embed-video.small-16 {
    height: 32rem;
    max-width: 80rem;
    margin: 0 auto; }
  .embed-video.small {
    height: 42rem;
    max-width: 80rem;
    margin: 0 auto; }
  .embed-video.middle-16 {
    height: 52rem;
    max-width: 120rem;
    margin: 0 auto; }
  .embed-video.middle {
    height: 62rem;
    max-width: 120rem;
    margin: 0 auto; }
  .embed-video.large-16 {
    height: 74rem; }
  .embed-video.large {
    height: 84rem; }
  .embed-video iframe {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0; }

.embed-video-overflow {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-size: cover;
  background-position: center;
  cursor: pointer; }
  .embed-video-overflow.autoplay-1, .embed-video-overflow.played {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 1000ms 400ms, visibility 0ms 1000ms;
    -moz-transition: opacity 1000ms 400ms, visibility 0ms 1000ms;
    -o-transition: opacity 1000ms 400ms, visibility 0ms 1000ms;
    -ms-transition: opacity 1000ms 400ms, visibility 0ms 1000ms;
    transition: opacity 1000ms 400ms, visibility 0ms 1000ms; }

.embed-video-icon {
  display: block;
  height: 9rem;
  width: 9rem;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  -moz-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  -webkit-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }
  .embed-video-icon:before {
    content: "\e903";
    display: inline-block;
    font-size: 3rem;
    width: auto;
    height: auto;
    -moz-transition: all ease-in-out 300ms;
    -o-transition: all ease-in-out 300ms;
    -webkit-transition: all ease-in-out 300ms;
    transition: all ease-in-out 300ms;
    top: 33%;
    bottom: 33%;
    left: 40%;
    right: 40%;
    position: absolute; }

.pux-infinite-repeater-options-inner-load-more {
  padding: 5rem 0 0 0; }

.uppercase-title .about-item h2 {
  text-transform: uppercase; }

.about-item {
  padding: 0 0 5rem 0; }
  .about-item h2 {
    text-transform: none; }

.small-style h2 {
  font-weight: 400;
  text-transform: none;
  padding: 0.5rem 0 4rem 0; }
  @media (min-width: 320px) {
    .small-style h2 {
      font-size: 4rem; } }
  @media (min-width: 480px) {
    .small-style h2 {
      font-size: 3.63636rem; } }
  @media (min-width: 545px) {
    .small-style h2 {
      font-size: 3.33333rem; } }
  @media (min-width: 768px) {
    .small-style h2 {
      font-size: 3.2rem; } }
  @media (min-width: 320px) {
    .small-style h2 {
      line-height: 5rem; } }
  @media (min-width: 480px) {
    .small-style h2 {
      line-height: 4.54545rem; } }
  @media (min-width: 545px) {
    .small-style h2 {
      line-height: 4.16667rem; } }
  @media (min-width: 768px) {
    .small-style h2 {
      line-height: 3.5rem; } }

.quote-style h2 {
  font-style: italic;
  text-transform: none; }
  .quote-style h2:before {
    content: '„';
    font-style: italic;
    font-family: "proxima-nova", sans-serif !important; }
  .quote-style h2:after {
    content: '“';
    font-style: italic;
    font-family: "proxima-nova", sans-serif !important; }

.card-event {
  margin-top: 10px; }
  .card-event .card-item {
    position: relative; }
  .card-event .card-item-blog {
    width: 100%;
    position: relative;
    top: -10px; }

.news-box {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -2rem;
  width: 100%;
  padding: 4rem 0 0 0; }
  .news-box-left {
    width: 100%;
    padding: 0 2rem 2rem 2rem; }
    @media (min-width: 768px) {
      .news-box-left {
        width: 33%; } }
  .news-box-right {
    width: 100%;
    padding: 0 2rem 2rem 2rem; }
    @media (min-width: 768px) {
      .news-box-right {
        width: 67%; } }

.events-box {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 4rem 0 0 0; }
  @media (min-width: 768px) {
    .events-box {
      margin: 0 -2rem; } }
  .events-box .pux-container {
    padding: 0; }
    @media (min-width: 768px) {
      .events-box .pux-container {
        padding-left: 4.5rem;
        padding-right: 4.5rem; } }
  .events-box-left {
    width: 100%; }
    @media (min-width: 768px) {
      .events-box-left {
        padding: 0 2rem 2rem 2rem; } }
    @media (min-width: 1400px) {
      .events-box-left {
        width: 33%; } }
    .events-box-left h2.widget-title {
      text-align: left !important;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center; }
      .events-box-left h2.widget-title:before {
        content: "\e90e";
        margin-right: 1rem; }
  .events-box-right {
    width: 100%; }
    @media (min-width: 768px) {
      .events-box-right {
        padding: 0 2rem 2rem 2rem; } }
    @media (min-width: 1400px) {
      .events-box-right {
        width: 67%; } }
    .events-box-right h2.widget-title {
      text-align: left !important;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center; }
      .events-box-right h2.widget-title:before {
        content: "\e909";
        margin-right: 1rem; }
    .events-box-right .card-item-blog,
    .events-box-right .card-item-image-label {
      display: none; }
    .events-box-right .card-item-label {
      -webkit-box-ordinal-group: 2;
      -moz-box-ordinal-group: 2;
      -ms-box-ordinal-group: 2;
      -ms-flex-order: 2;
      -webkit-order: 2;
      -moz-order: 2;
      -ms-order: 2;
      order: 2;
      font-weight: 700;
      padding: 3rem;
      padding-bottom: 0;
      position: relative;
      text-align: left; }
      @media (min-width: 320px) {
        .events-box-right .card-item-label {
          font-size: 3.2rem; } }
      @media (min-width: 480px) {
        .events-box-right .card-item-label {
          font-size: 2.90909rem; } }
      @media (min-width: 545px) {
        .events-box-right .card-item-label {
          font-size: 2.66667rem; } }
      @media (min-width: 768px) {
        .events-box-right .card-item-label {
          font-size: 2.13333rem; } }
      @media (min-width: 992px) {
        .events-box-right .card-item-label {
          font-size: 2rem; } }
      @media (min-width: 1200px) {
        .events-box-right .card-item-label {
          font-size: 1.77778rem; } }
      @media (min-width: 1400px) {
        .events-box-right .card-item-label {
          font-size: 1.6rem; } }
    .events-box-right .card-item-content {
      padding-top: .5rem; }
    .events-box-right .card-item-image {
      -webkit-box-ordinal-group: 1;
      -moz-box-ordinal-group: 1;
      -ms-box-ordinal-group: 1;
      -ms-flex-order: 1;
      -webkit-order: 1;
      -moz-order: 1;
      -ms-order: 1;
      order: 1; }
    .events-box-right .middle-card.card-event {
      margin: 0; }
    @media (min-width: 768px) {
      .events-box-right .news-box-left {
        width: 50%; } }
    @media (min-width: 768px) {
      .events-box-right .news-box-right {
        width: 50%; } }
  .events-box.event-reverse .events-box-left {
    order: 2; }
    .events-box.event-reverse .events-box-left .widget-container {
      border-right: none;
      position: relative; }
      .events-box.event-reverse .events-box-left .widget-container:before {
        content: "";
        width: 2px;
        height: 100%;
        position: absolute;
        left: -2rem;
        top: 0;
        background: #ebebeb; }
  .events-box.event-reverse .events-box-right {
    order: 1; }
  .events-box .banner-col {
    max-width: 100%;
    flex: 0 0 100%; }
  .events-box .events-box-left .row {
    margin-left: -7.5px !important;
    margin-right: -7.5px !important; }

.events-upcoming {
  margin-bottom: 2rem;
  width: 100%; }
  .events-upcoming a {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -moz-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center; }
    .events-upcoming a:focus, .events-upcoming a:hover {
      text-decoration: none; }
      .events-upcoming a:focus .events-upcoming-link-right, .events-upcoming a:hover .events-upcoming-link-right {
        text-decoration: underline; }
    .events-upcoming a .events-upcoming-link-left {
      margin-right: 2rem;
      font-weight: 700;
      min-width: 150px; }
      .events-upcoming a .events-upcoming-link-left-date-day {
        width: 100%;
        text-align: center;
        padding: 1rem 2rem; }
        @media (min-width: 320px) {
          .events-upcoming a .events-upcoming-link-left-date-day {
            font-size: 3.6rem; } }
        @media (min-width: 480px) {
          .events-upcoming a .events-upcoming-link-left-date-day {
            font-size: 3.27273rem; } }
        @media (min-width: 545px) {
          .events-upcoming a .events-upcoming-link-left-date-day {
            font-size: 3rem; } }
        @media (min-width: 768px) {
          .events-upcoming a .events-upcoming-link-left-date-day {
            font-size: 2.4rem; } }
        @media (min-width: 992px) {
          .events-upcoming a .events-upcoming-link-left-date-day {
            font-size: 2.25rem; } }
        @media (min-width: 1200px) {
          .events-upcoming a .events-upcoming-link-left-date-day {
            font-size: 2rem; } }
      .events-upcoming a .events-upcoming-link-left-date-time {
        width: 100%;
        padding: 0.5rem 1rem;
        text-align: center;
        font-weight: 400; }
        @media (min-width: 320px) {
          .events-upcoming a .events-upcoming-link-left-date-time {
            font-size: 2.8rem; } }
        @media (min-width: 480px) {
          .events-upcoming a .events-upcoming-link-left-date-time {
            font-size: 2.54545rem; } }
        @media (min-width: 545px) {
          .events-upcoming a .events-upcoming-link-left-date-time {
            font-size: 2.33333rem; } }
        @media (min-width: 768px) {
          .events-upcoming a .events-upcoming-link-left-date-time {
            font-size: 1.86667rem; } }
        @media (min-width: 992px) {
          .events-upcoming a .events-upcoming-link-left-date-time {
            font-size: 1.75rem; } }
        @media (min-width: 1200px) {
          .events-upcoming a .events-upcoming-link-left-date-time {
            font-size: 1.55556rem; } }
        @media (min-width: 1400px) {
          .events-upcoming a .events-upcoming-link-left-date-time {
            font-size: 1.4rem; } }
    .events-upcoming a .events-upcoming-link-right {
      flex: 1; }

.events-small-link,
.news-small-link {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 0 -1rem;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  @media (min-width: 545px) {
    .events-small-link,
    .news-small-link {
      -webkit-flex-wrap: nowrap;
      -moz-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap; } }
  .events-small-link-left,
  .news-small-link-left {
    margin: 0 1rem 2rem 1rem;
    width: 100%;
    overflow: hidden; }
    @media (min-width: 545px) {
      .events-small-link-left,
      .news-small-link-left {
        width: 25rem; } }
    .events-small-link-left-image,
    .news-small-link-left-image {
      width: 100%;
      height: 30rem;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms;
      background-size: cover !important;
      background-position: top center !important; }
      @media (min-width: 545px) {
        .events-small-link-left-image,
        .news-small-link-left-image {
          height: 12.5rem; } }
  .events-small-link-right,
  .news-small-link-right {
    padding: 0 1rem 2rem 1rem;
    width: 100%;
    text-align: left; }
    .events-small-link-right-label,
    .news-small-link-right-label {
      font-weight: 700;
      padding-bottom: 0; }
      @media (min-width: 320px) {
        .events-small-link-right-label,
        .news-small-link-right-label {
          font-size: 3.2rem; } }
      @media (min-width: 480px) {
        .events-small-link-right-label,
        .news-small-link-right-label {
          font-size: 2.90909rem; } }
      @media (min-width: 545px) {
        .events-small-link-right-label,
        .news-small-link-right-label {
          font-size: 2.66667rem; } }
      @media (min-width: 768px) {
        .events-small-link-right-label,
        .news-small-link-right-label {
          font-size: 2.13333rem; } }
      @media (min-width: 992px) {
        .events-small-link-right-label,
        .news-small-link-right-label {
          font-size: 2rem; } }
      @media (min-width: 1200px) {
        .events-small-link-right-label,
        .news-small-link-right-label {
          font-size: 1.77778rem; } }
      @media (min-width: 1400px) {
        .events-small-link-right-label,
        .news-small-link-right-label {
          font-size: 1.6rem; } }
    .events-small-link-right-ref,
    .news-small-link-right-ref {
      font-weight: 700; }
      @media (min-width: 320px) {
        .events-small-link-right-ref,
        .news-small-link-right-ref {
          font-size: 3.2rem; } }
      @media (min-width: 480px) {
        .events-small-link-right-ref,
        .news-small-link-right-ref {
          font-size: 2.90909rem; } }
      @media (min-width: 545px) {
        .events-small-link-right-ref,
        .news-small-link-right-ref {
          font-size: 2.66667rem; } }
      @media (min-width: 768px) {
        .events-small-link-right-ref,
        .news-small-link-right-ref {
          font-size: 2.13333rem; } }
      @media (min-width: 992px) {
        .events-small-link-right-ref,
        .news-small-link-right-ref {
          font-size: 2rem; } }
      @media (min-width: 1200px) {
        .events-small-link-right-ref,
        .news-small-link-right-ref {
          font-size: 1.77778rem; } }
      @media (min-width: 1400px) {
        .events-small-link-right-ref,
        .news-small-link-right-ref {
          font-size: 1.6rem; } }
      .events-small-link-right-ref:after,
      .news-small-link-right-ref:after {
        position: relative;
        bottom: 1px;
        display: inline-block;
        content: "\e902";
        vertical-align: middle;
        -webkit-transform: scale(0.5) rotate(270deg);
        -moz-transform: scale(0.5) rotate(270deg);
        -ms-transform: scale(0.5) rotate(270deg);
        -o-transform: scale(0.5) rotate(270deg);
        transform: scale(0.5) rotate(270deg); }
  .events-small-link:focus, .events-small-link:hover,
  .news-small-link:focus,
  .news-small-link:hover {
    text-decoration: none; }
    .events-small-link:focus .news-small-link-left-image,
    .events-small-link:focus .events-small-link-left-image, .events-small-link:hover .news-small-link-left-image,
    .events-small-link:hover .events-small-link-left-image,
    .news-small-link:focus .news-small-link-left-image,
    .news-small-link:focus .events-small-link-left-image,
    .news-small-link:hover .news-small-link-left-image,
    .news-small-link:hover .events-small-link-left-image {
      -webkit-transform: scale(1.1);
      -moz-transform: scale(1.1);
      -ms-transform: scale(1.1);
      -o-transform: scale(1.1);
      transform: scale(1.1); }
    .events-small-link:focus .news-small-link-right-ref span,
    .events-small-link:focus .events-small-link-right-ref span, .events-small-link:hover .news-small-link-right-ref span,
    .events-small-link:hover .events-small-link-right-ref span,
    .news-small-link:focus .news-small-link-right-ref span,
    .news-small-link:focus .events-small-link-right-ref span,
    .news-small-link:hover .news-small-link-right-ref span,
    .news-small-link:hover .events-small-link-right-ref span {
      text-decoration: underline; }

.header-menu ul li.menu-about, .header-menu ul li.menu-home, .header-menu ul li.menu-services, .header-menu ul li.menu-life, .header-menu ul li.menu-contact {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .header-menu ul li.menu-about:before, .header-menu ul li.menu-home:before, .header-menu ul li.menu-services:before, .header-menu ul li.menu-life:before, .header-menu ul li.menu-contact:before {
    width: 30px;
    margin-left: 15px; }
    @media (min-width: 768px) {
      .header-menu ul li.menu-about:before, .header-menu ul li.menu-home:before, .header-menu ul li.menu-services:before, .header-menu ul li.menu-life:before, .header-menu ul li.menu-contact:before {
        margin-left: 4.5rem; } }
    @media (min-width: 992px) {
      .header-menu ul li.menu-about:before, .header-menu ul li.menu-home:before, .header-menu ul li.menu-services:before, .header-menu ul li.menu-life:before, .header-menu ul li.menu-contact:before {
        width: auto;
        margin-left: 3px;
        display: none; } }
    @media (min-width: 1200px) {
      .header-menu ul li.menu-about:before, .header-menu ul li.menu-home:before, .header-menu ul li.menu-services:before, .header-menu ul li.menu-life:before, .header-menu ul li.menu-contact:before {
        display: block; } }

.header-menu ul li.menu-about:before {
  content: "\e90a"; }

.header-menu ul li.menu-home:before {
  content: "\e90d"; }

.header-menu ul li.menu-services:before {
  content: "\e908"; }

.header-menu ul li.menu-life:before {
  content: "\e907"; }

.header-menu ul li.menu-contact:before {
  content: "\e90c"; }

.pdf,
.docx,
.rtf,
.xls,
.ppt,
.doc {
  display: inline-flex;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: flex-end;
  -moz-box-align: flex-end;
  -ms-flex-align: flex-end;
  -webkit-align-items: flex-end;
  align-items: flex-end; }
  .pdf span:before,
  .docx span:before,
  .rtf span:before,
  .xls span:before,
  .ppt span:before,
  .doc span:before {
    margin-right: 10px;
    display: inline-block !important;
    text-decoration: none !important; }
    @media (min-width: 320px) {
      .pdf span:before,
      .docx span:before,
      .rtf span:before,
      .xls span:before,
      .ppt span:before,
      .doc span:before {
        font-size: 4rem; } }
    @media (min-width: 480px) {
      .pdf span:before,
      .docx span:before,
      .rtf span:before,
      .xls span:before,
      .ppt span:before,
      .doc span:before {
        font-size: 3.63636rem; } }
    @media (min-width: 545px) {
      .pdf span:before,
      .docx span:before,
      .rtf span:before,
      .xls span:before,
      .ppt span:before,
      .doc span:before {
        font-size: 3.33333rem; } }
    @media (min-width: 768px) {
      .pdf span:before,
      .docx span:before,
      .rtf span:before,
      .xls span:before,
      .ppt span:before,
      .doc span:before {
        font-size: 3rem; } }

.blank {
  display: inline-flex !important;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: flex-end;
  -moz-box-align: flex-end;
  -ms-flex-align: flex-end;
  -webkit-align-items: flex-end;
  align-items: flex-end; }
  .blank span:before {
    margin-right: 10px;
    display: inline-block !important;
    text-decoration: none !important; }
    @media (min-width: 320px) {
      .blank span:before {
        font-size: 3.2rem; } }
    @media (min-width: 480px) {
      .blank span:before {
        font-size: 2.90909rem; } }
    @media (min-width: 545px) {
      .blank span:before {
        font-size: 2.66667rem; } }
    @media (min-width: 768px) {
      .blank span:before {
        font-size: 2.13333rem; } }
    @media (min-width: 992px) {
      .blank span:before {
        font-size: 2rem; } }
    @media (min-width: 1200px) {
      .blank span:before {
        font-size: 1.8rem; } }
  .blank span + span {
    display: none; }

.pdf span:before {
  content: "\e918"; }

.doc span:before {
  content: "\e917"; }

/*.jpg{
  &:before{
    content: "\e919";
  }
}*/
.ppt span:before {
  content: "\e922"; }

.docx span:before {
  content: "\e920"; }

.xls span:before {
  content: "\e921"; }

.rtfspan:before {
  content: "\e925"; }

main .blank span:before {
  content: "\e92c"; }

.gallery-row {
  margin-left: -7.5px !important;
  margin-right: -7.5px !important; }

.gallery-widget-row {
  margin-left: -2.25rem !important;
  margin-right: -2.25rem !important; }
  .gallery-widget-row [class^="col"] {
    padding: 2.25rem; }
  .gallery-widget-row .jpg:before {
    display: none; }

.no-ico:before {
  display: none !important; }

.gallery-title {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  position: relative; }
  .gallery-title h2 {
    text-transform: none;
    margin-right: 2rem; }
  .gallery-title-numbers {
    padding: 2rem 0 2rem 0;
    font-weight: 700; }
    @media (min-width: 320px) {
      .gallery-title-numbers {
        line-height: 4.4rem; } }
    @media (min-width: 480px) {
      .gallery-title-numbers {
        line-height: 4rem; } }
    @media (min-width: 545px) {
      .gallery-title-numbers {
        line-height: 3.66667rem; } }
    @media (min-width: 768px) {
      .gallery-title-numbers {
        line-height: 3.5rem; } }
    @media (min-width: 320px) {
      .gallery-title-numbers {
        font-size: 3rem; } }
    @media (min-width: 480px) {
      .gallery-title-numbers {
        font-size: 2.72727rem; } }
    @media (min-width: 545px) {
      .gallery-title-numbers {
        font-size: 2.5rem; } }
    @media (min-width: 768px) {
      .gallery-title-numbers {
        font-size: 2.2rem; } }
  .gallery-title:before {
    content: "";
    margin: 15px;
    /*position: absolute;
    left: -2.25rem;
    top: 2rem;*/ }
    @media (min-width: 320px) {
      .gallery-title:before {
        width: 0.8rem; } }
    @media (min-width: 320px) {
      .gallery-title:before {
        height: 4.4rem; } }
    @media (min-width: 480px) {
      .gallery-title:before {
        height: 4rem; } }
    @media (min-width: 545px) {
      .gallery-title:before {
        height: 3.66667rem; } }
    @media (min-width: 768px) {
      .gallery-title:before {
        height: 3.5rem; } }

.select-box {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }
  .select-box .filter-item {
    padding: 0 1rem 2rem 1rem; }

.banner-item-inner {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  position: relative; }
  .banner-item-inner:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .banner-item-inner-content {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    min-height: 30rem;
    text-align: center;
    padding: 3rem;
    width: 100%;
    position: relative; }

.banner-item.style-1,
.banner-item.style-2,
.banner-item.style-3,
.banner-item.style-4 {
  text-decoration: none;
  display: block;
  position: relative;
  overflow: hidden; }
  .banner-item.style-1 .banner-item-bgr,
  .banner-item.style-2 .banner-item-bgr,
  .banner-item.style-3 .banner-item-bgr,
  .banner-item.style-4 .banner-item-bgr {
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .banner-item.style-1 .banner-item-inner,
  .banner-item.style-2 .banner-item-inner,
  .banner-item.style-3 .banner-item-inner,
  .banner-item.style-4 .banner-item-inner {
    min-height: 35rem; }
  .banner-item.style-1:hover .banner-item-bgr, .banner-item.style-1:focus .banner-item-bgr,
  .banner-item.style-2:hover .banner-item-bgr,
  .banner-item.style-2:focus .banner-item-bgr,
  .banner-item.style-3:hover .banner-item-bgr,
  .banner-item.style-3:focus .banner-item-bgr,
  .banner-item.style-4:hover .banner-item-bgr,
  .banner-item.style-4:focus .banner-item-bgr {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1); }

.banner-item.style-1 .banner-item-inner:before {
  display: none; }

.banner-item.style-1 .banner-item-inner-content-title {
  padding: 1rem 3rem;
  margin: 0; }

.banner-item.style-1 .banner-item-inner-content-btn {
  padding: 1rem 3rem;
  text-transform: uppercase;
  letter-spacing: 2.8px;
  font-size: 16px;
  font-weight: 700;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }

.banner-item.style-2:hover .banner-item-inner-content-row-btn, .banner-item.style-2:focus .banner-item-inner-content-row-btn {
  -webkit-transform: translateX(0.5rem);
  -moz-transform: translateX(0.5rem);
  -ms-transform: translateX(0.5rem);
  -o-transform: translateX(0.5rem);
  transform: translateX(0.5rem); }

.banner-item.style-2 .banner-item-inner-content {
  -webkit-box-align: flex-start;
  -moz-box-align: flex-start;
  -ms-flex-align: flex-start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-box-pack: flex-end;
  -moz-box-pack: flex-end;
  -ms-flex-pack: flex-end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end; }
  .banner-item.style-2 .banner-item-inner-content-title {
    padding: 1rem 3rem;
    margin: 0; }
  .banner-item.style-2 .banner-item-inner-content-row {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: space-between;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    padding: 2rem 0 0 3rem;
    width: 100%; }
    .banner-item.style-2 .banner-item-inner-content-row-perex {
      text-align: left;
      max-width: 50rem; }
    .banner-item.style-2 .banner-item-inner-content-row-btn {
      padding: 0 0 0 2rem;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
      .banner-item.style-2 .banner-item-inner-content-row-btn:after {
        content: "\e901";
        display: block;
        -ms-transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        font-size: 2rem; }

.banner-item.style-3:hover .banner-item-inner-content-btn, .banner-item.style-3:focus .banner-item-inner-content-btn {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0); }

.banner-item.style-3 .banner-item-inner:before {
  display: none; }

.banner-item.style-3 .banner-item-inner-content {
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: space-between;
  -moz-box-pack: space-between;
  -ms-flex-pack: space-between;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding: 0 3rem; }
  .banner-item.style-3 .banner-item-inner-content-title {
    padding: 1rem 3rem;
    margin: 0; }
  .banner-item.style-3 .banner-item-inner-content-btn {
    padding: 1.5rem 3rem 2.5rem;
    text-transform: uppercase;
    letter-spacing: 2.8px;
    font-size: 16px;
    font-weight: 700;
    -webkit-transform: translateY(1rem);
    -moz-transform: translateY(1rem);
    -ms-transform: translateY(1rem);
    -o-transform: translateY(1rem);
    transform: translateY(1rem);
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }

.banner-item.style-4:hover .banner-item-inner-content-btn, .banner-item.style-4:focus .banner-item-inner-content-btn {
  -webkit-transform: translate(-50%, -0.5rem);
  -moz-transform: translate(-50%, -0.5rem);
  -ms-transform: translate(-50%, -0.5rem);
  -o-transform: translate(-50%, -0.5rem);
  transform: translate(-50%, -0.5rem); }

.banner-item.style-4 .banner-item-inner-content-title {
  padding: 1rem 3rem;
  margin: 0;
  -webkit-transform: translateY(-4rem);
  -moz-transform: translateY(-4rem);
  -ms-transform: translateY(-4rem);
  -o-transform: translateY(-4rem);
  transform: translateY(-4rem); }

.banner-item.style-4 .banner-item-inner-content-btn {
  padding: 1.5rem 3rem;
  text-transform: uppercase;
  letter-spacing: 2.8px;
  font-size: 16px;
  font-weight: 700;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0); }

.banner-col {
  padding: 7.5px !important; }

.map-box {
  position: relative;
  width: 100%;
  text-align: center; }
  .map-box-name {
    padding: 1.5rem 3rem;
    -webkit-border-radius: 6rem;
    -moz-border-radius: 6rem;
    -ms-border-radius: 6rem;
    border-radius: 6rem;
    display: inline-block;
    position: relative;
    z-index: 888;
    -webkit-transform: translate(0, 50%);
    -moz-transform: translate(0, 50%);
    -ms-transform: translate(0, 50%);
    -o-transform: translate(0, 50%);
    transform: translate(0, 50%); }
  .map-box #map-canvas {
    width: 100%;
    height: 50rem; }

.map-points {
  display: none; }

.marker-detail {
  display: none; }

.marker-detail-inner {
  display: flex; }
  .marker-detail-inner img {
    max-width: 150px; }

.is-image .marker-detail-text {
  padding: 0 0 0 15px; }

.map-box h3 {
  padding-bottom: 5px; }

.marker-detail-service,
.marker-detail-capacity,
.marker-detail-contact,
.marker-detail-mail {
  color: #4c4c4c;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  line-height: 1.5;
  font-size: 15px; }
  .marker-detail-service:before,
  .marker-detail-capacity:before,
  .marker-detail-contact:before,
  .marker-detail-mail:before {
    padding-right: 10px;
    line-height: 1.5; }

.marker-detail-content {
  text-align: left;
  line-height: 1.5;
  font-size: 15px; }

.marker-detail-service:before {
  content: "\e91f"; }

.marker-detail-capacity:before {
  content: "\e91d"; }

.marker-detail-contact:before {
  content: "\e91c"; }

.marker-detail-mail:before {
  content: "\e92b"; }

.marker-detail-close {
  opacity: 1 !important; }
  .marker-detail-close img {
    display: none !important; }
  .marker-detail-close:before {
    content: "\03a7";
    font-family: "proxima-nova", sans-serif !important; }

.insert-image a {
  display: inline-block; }
  .insert-image a:before {
    display: none !important; }

main .tenders-table table {
  width: 100%;
  border-collapse: collapse;
  border-style: hidden; }
  main .tenders-table table thead {
    display: none; }
    @media (min-width: 768px) {
      main .tenders-table table thead {
        display: table-header-group; } }
    main .tenders-table table thead tr th {
      font-weight: 400;
      text-transform: uppercase;
      padding: 1rem 0 1.5rem 0;
      font-size: 2.8rem; }
      @media (min-width: 480px) {
        main .tenders-table table thead tr th {
          font-size: 2.54545rem; } }
      @media (min-width: 545px) {
        main .tenders-table table thead tr th {
          font-size: 2.33333rem; } }
      @media (min-width: 768px) {
        main .tenders-table table thead tr th {
          font-size: 1.86667rem; } }
      @media (min-width: 992px) {
        main .tenders-table table thead tr th {
          font-size: 1.75rem; } }
      @media (min-width: 1200px) {
        main .tenders-table table thead tr th {
          font-size: 1.55556rem; } }
      @media (min-width: 1400px) {
        main .tenders-table table thead tr th {
          font-size: 1.4rem; } }
  main .tenders-table table tbody tr {
    display: block;
    border-bottom: none;
    margin: 0 0 2rem 0;
    position: relative;
    cursor: pointer; }
    @media (min-width: 768px) {
      main .tenders-table table tbody tr {
        display: table-row;
        margin: 0;
        border: none; } }
    main .tenders-table table tbody tr:after {
      content: "";
      width: 100%;
      right: 0;
      position: absolute;
      height: 2px; }
    main .tenders-table table tbody tr:first-child:after {
      display: none; }
    main .tenders-table table tbody tr td {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: space-between;
      -moz-box-pack: space-between;
      -ms-flex-pack: space-between;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      width: 100%;
      position: relative;
      padding: 2.3rem 2.5rem;
      line-height: 1.3;
      text-align: right; }
      @media (min-width: 768px) {
        main .tenders-table table tbody tr td {
          display: table-cell;
          width: auto;
          text-align: left; } }
      @media (min-width: 768px) {
        main .tenders-table table tbody tr td span {
          position: absolute;
          left: 0;
          width: 0.6rem;
          height: 3.8rem;
          top: 50%;
          margin-top: -1.9rem; } }
      main .tenders-table table tbody tr td:before {
        content: attr(data-th);
        display: block;
        text-align: left;
        font-family: "proxima-nova", sans-serif !important;
        margin: 0 1.5rem 0 0;
        line-height: 1.3; }
        @media (min-width: 768px) {
          main .tenders-table table tbody tr td:before {
            display: none; } }
      main .tenders-table table tbody tr td:after {
        content: "";
        width: 2px;
        top: 0;
        right: 0;
        position: absolute;
        height: 100%; }
      main .tenders-table table tbody tr td:last-child {
        text-align: center;
        -webkit-box-pack: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center; }
        main .tenders-table table tbody tr td:last-child:before {
          display: none; }
        main .tenders-table table tbody tr td:last-child:after {
          display: none; }

.logo-carousel .slick-arrow {
  position: absolute;
  top: 50%;
  margin-top: -2rem;
  width: 4rem;
  height: 4rem;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  border: none;
  font-size: 0;
  background: transparent;
  outline: 0;
  z-index: 22;
  display: flex;
  align-items: center;
  cursor: pointer; }
  .logo-carousel .slick-arrow:before {
    font-size: 20px; }
  .logo-carousel .slick-arrow.slick-prev {
    left: 2rem; }
    .logo-carousel .slick-arrow.slick-prev:before {
      content: "";
      display: block;
      opacity: 0.5;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
      filter: alpha(opacity=50);
      -ms-transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg); }
  .logo-carousel .slick-arrow.slick-next {
    right: 2rem; }
    .logo-carousel .slick-arrow.slick-next:before {
      content: "";
      display: block;
      -ms-transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
      opacity: 0.5;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
      filter: alpha(opacity=50); }

.logo-carousel .slick-arrow.slick-prev:before {
  position: relative;
  left: -11px; }

.logo-item-box {
  height: 30rem;
  padding: 2rem 6rem;
  outline: none !important; }

.logo-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex: 0 0 auto;
  filter: grayscale(100%);
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50); }
  .logo-item:hover {
    filter: grayscale(0);
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }
  .logo-item img {
    flex: 0 0 auto; }

.ico-card-item {
  width: 100%;
  height: 100%;
  display: block;
  padding: 3rem;
  color: #4c4c4c; }
  .ico-card-item:hover, .ico-card-item:focus {
    text-decoration: none;
    color: #4c4c4c; }
    .ico-card-item:hover .ico-card-item-icon img, .ico-card-item:focus .ico-card-item-icon img {
      -webkit-transform: scale(1.075);
      -moz-transform: scale(1.075);
      -ms-transform: scale(1.075);
      -o-transform: scale(1.075);
      transform: scale(1.075); }
  .ico-card-item-icon {
    height: 60px; }
    .ico-card-item-icon img {
      max-height: 100%;
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
  .ico-card-item-title {
    padding-top: 3rem;
    padding-bottom: 2rem; }

.icons-bgr {
  background: rgba(238, 238, 238, 0.25); }

.icons-row {
  max-width: 108rem;
  margin: auto !important; }
  @media (min-width: 768px) {
    .icons-row .col-xs-12 {
      padding-right: 2rem;
      padding-left: 2rem; } }
  @media (min-width: 768px) {
    .icons-row .col-xs-12:nth-child(2) {
      border-left: 1px solid rgba(238, 238, 238, 0.75);
      border-right: 1px solid rgba(238, 238, 238, 0.75); } }

.text-container.files,
.text-container.citation {
  padding: 0; }

.notification-files-item {
  padding-bottom: 3rem;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex; }

.citation-item {
  margin: 0 -15px; }
  @media (min-width: 768px) {
    .citation-item {
      margin: 0 -4.5rem; } }
  @media (min-width: 1400px) {
    .citation-item {
      margin: 0 -9rem; } }
  .citation-item-inner {
    padding: 9rem 15px 3.5rem;
    position: relative; }
    @media (min-width: 768px) {
      .citation-item-inner {
        padding: 9rem 4.5rem 3.5rem; } }
    @media (min-width: 1400px) {
      .citation-item-inner {
        padding: 3.5rem 9rem; } }
    .citation-item-inner:before {
      content: "\e928";
      font-size: 3.5rem;
      opacity: 0.15;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=15)";
      filter: alpha(opacity=15);
      position: absolute;
      top: 3.5rem;
      left: 3rem; }
    .citation-item-inner-text {
      font-style: italic;
      padding-bottom: 3rem; }
      @media (min-width: 320px) {
        .citation-item-inner-text {
          font-size: 3.2rem; } }
      @media (min-width: 480px) {
        .citation-item-inner-text {
          font-size: 2.90909rem; } }
      @media (min-width: 545px) {
        .citation-item-inner-text {
          font-size: 2.66667rem; } }
      @media (min-width: 768px) {
        .citation-item-inner-text {
          font-size: 2.13333rem; } }
      @media (min-width: 992px) {
        .citation-item-inner-text {
          font-size: 2rem; } }
      @media (min-width: 320px) {
        .citation-item-inner-text {
          line-height: 4rem; } }
      @media (min-width: 480px) {
        .citation-item-inner-text {
          line-height: 3.63636rem; } }
      @media (min-width: 545px) {
        .citation-item-inner-text {
          line-height: 3.33333rem; } }
      @media (min-width: 768px) {
        .citation-item-inner-text {
          line-height: 2.8rem; } }
    .citation-item-inner-author {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center; }
      .citation-item-inner-author-image {
        width: 50px;
        height: 50px;
        background-position: center center !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%;
        margin-right: 20px; }
      .citation-item-inner-author-content-name {
        font-weight: 700; }
        @media (min-width: 320px) {
          .citation-item-inner-author-content-name {
            font-size: 3.6rem; } }
        @media (min-width: 480px) {
          .citation-item-inner-author-content-name {
            font-size: 3.27273rem; } }
        @media (min-width: 545px) {
          .citation-item-inner-author-content-name {
            font-size: 3rem; } }
        @media (min-width: 768px) {
          .citation-item-inner-author-content-name {
            font-size: 2.4rem; } }
        @media (min-width: 992px) {
          .citation-item-inner-author-content-name {
            font-size: 2.25rem; } }
        @media (min-width: 1200px) {
          .citation-item-inner-author-content-name {
            font-size: 2rem; } }
        @media (min-width: 320px) {
          .citation-item-inner-author-content-name {
            line-height: 5rem; } }
        @media (min-width: 480px) {
          .citation-item-inner-author-content-name {
            line-height: 4.54545rem; } }
        @media (min-width: 545px) {
          .citation-item-inner-author-content-name {
            line-height: 4.16667rem; } }
        @media (min-width: 768px) {
          .citation-item-inner-author-content-name {
            line-height: 3.33333rem; } }
        @media (min-width: 992px) {
          .citation-item-inner-author-content-name {
            line-height: 3.125rem; } }
        @media (min-width: 1200px) {
          .citation-item-inner-author-content-name {
            line-height: 3rem; } }
      .citation-item-inner-author-content-desc {
        text-transform: uppercase;
        letter-spacing: 1.05px; }

.timeline-box {
  padding: 17rem 0 22rem;
  margin: auto;
  max-width: 830px;
  position: relative; }
  .timeline-box:before {
    content: "";
    width: 4px;
    top: 11rem;
    bottom: 16rem;
    left: 18px;
    position: absolute; }
    @media (min-width: 992px) {
      .timeline-box:before {
        left: 50%;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%); } }
  .timeline-box:after {
    content: "";
    width: 4px;
    height: 200px;
    bottom: 16rem;
    left: 18px;
    position: absolute;
    z-index: 1; }
    @media (min-width: 992px) {
      .timeline-box:after {
        left: 50%;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
        height: 300px; } }
  .timeline-box-start, .timeline-box-end {
    width: 6rem;
    height: 6rem;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    position: absolute;
    left: 20px;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center; }
    @media (min-width: 992px) {
      .timeline-box-start, .timeline-box-end {
        left: 50%;
        width: 50px;
        height: 50px; } }
  .timeline-box-start {
    top: 7rem; }
    .timeline-box-start:after {
      content: "\e92a";
      font-size: 3rem;
      -webkit-transform: translateX(-1px);
      -moz-transform: translateX(-1px);
      -ms-transform: translateX(-1px);
      -o-transform: translateX(-1px);
      transform: translateX(-1px); }
      @media (min-width: 992px) {
        .timeline-box-start:after {
          font-size: 30px;
          -webkit-transform: translateX(-1.5px);
          -moz-transform: translateX(-1.5px);
          -ms-transform: translateX(-1.5px);
          -o-transform: translateX(-1.5px);
          transform: translateX(-1.5px); } }
  .timeline-box-end {
    bottom: 11rem; }
    .timeline-box-end:after {
      content: "\e929";
      font-size: 3rem;
      -webkit-transform: translateX(1px);
      -moz-transform: translateX(1px);
      -ms-transform: translateX(1px);
      -o-transform: translateX(1px);
      transform: translateX(1px); }
      @media (min-width: 992px) {
        .timeline-box-end:after {
          font-size: 30px;
          -webkit-transform: translateX(2.5px);
          -moz-transform: translateX(2.5px);
          -ms-transform: translateX(2.5px);
          -o-transform: translateX(2.5px);
          transform: translateX(2.5px); } }

.timeline-item {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 3rem 0; }
  .timeline-item-image {
    width: 100%;
    padding-left: 10rem; }
    @media (min-width: 992px) {
      .timeline-item-image {
        width: 50%;
        order: 2; } }
    .timeline-item-image-wrapper {
      height: 245px;
      width: 100%;
      position: relative; }
      .timeline-item-image-wrapper-bgr {
        background-position: center center !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%; }
  .timeline-item-content {
    width: 100%;
    padding-left: 6.5rem;
    position: relative; }
    @media (min-width: 992px) {
      .timeline-item-content {
        width: 50%;
        order: 1;
        padding-right: 6.5rem;
        padding-left: 0;
        text-align: right; } }
    .timeline-item-content-title {
      padding-top: 2rem; }
      @media (min-width: 992px) {
        .timeline-item-content-title {
          padding-top: 1rem; } }
    .timeline-item-content:after {
      content: "";
      position: absolute;
      border: 5px solid #ffffff;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      left: 18px;
      top: 2rem;
      z-index: 2;
      -webkit-transform: translate(-13px, -5px);
      -moz-transform: translate(-13px, -5px);
      -ms-transform: translate(-13px, -5px);
      -o-transform: translate(-13px, -5px);
      transform: translate(-13px, -5px); }
      @media (min-width: 992px) {
        .timeline-item-content:after {
          right: 0;
          top: 1rem;
          left: auto;
          -webkit-transform: translate(50%, 0px);
          -moz-transform: translate(50%, 0px);
          -ms-transform: translate(50%, 0px);
          -o-transform: translate(50%, 0px);
          transform: translate(50%, 0px); } }
    .timeline-item-content-perex {
      -moz-transition: all ease-in-out 500ms;
      -o-transition: all ease-in-out 500ms;
      -webkit-transition: all ease-in-out 500ms;
      transition: all ease-in-out 500ms; }
      .timeline-item-content-perex.collapsed {
        max-height: 145px;
        overflow: hidden; }
      .timeline-item-content-perex.collapsed.open {
        max-height: 2000px; }
    .timeline-item-content-btn {
      padding-top: 1.5rem;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      -webkit-box-pack: flex-end;
      -moz-box-pack: flex-end;
      -ms-flex-pack: flex-end;
      -webkit-justify-content: flex-end;
      justify-content: flex-end;
      cursor: pointer; }
      .timeline-item-content-btn.hidden {
        display: none; }
      .timeline-item-content-btn:after {
        content: "\e901";
        font-size: 4.5px;
        margin-left: 5px;
        -moz-transition: all ease-in-out 200ms;
        -o-transition: all ease-in-out 200ms;
        -webkit-transition: all ease-in-out 200ms;
        transition: all ease-in-out 200ms; }
      .timeline-item-content-btn.open:after {
        -ms-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg); }
  .timeline-item.reverse .timeline-item-image {
    order: 1; }
    @media (min-width: 992px) {
      .timeline-item.reverse .timeline-item-image {
        padding-left: 0;
        padding-right: 6.5rem;
        text-align: left; } }
  .timeline-item.reverse .timeline-item-content {
    order: 2; }
    @media (min-width: 992px) {
      .timeline-item.reverse .timeline-item-content {
        padding-left: 6.5rem;
        padding-right: 0;
        text-align: left; } }
    @media (min-width: 992px) {
      .timeline-item.reverse .timeline-item-content:after {
        right: auto;
        top: 1rem;
        left: 0;
        -webkit-transform: translate(-50%, 0px);
        -moz-transform: translate(-50%, 0px);
        -ms-transform: translate(-50%, 0px);
        -o-transform: translate(-50%, 0px);
        transform: translate(-50%, 0px); } }

.product-item {
  width: 100%;
  min-height: 240px;
  height: 100%;
  position: relative;
  padding: 100px 6rem 0;
  overflow: hidden; }
  @media (min-width: 768px) {
    .product-item {
      min-height: 30rem;
      padding: 12rem 6rem 0; } }
  .product-item:hover, .product-item:focus {
    text-decoration: none; }
    .product-item:hover .product-item-inner-btn, .product-item:focus .product-item-inner-btn {
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
      transform: translateY(0); }
    .product-item:hover .product-item-bgr, .product-item:focus .product-item-bgr {
      -webkit-transform: scale(1.1);
      -moz-transform: scale(1.1);
      -ms-transform: scale(1.1);
      -o-transform: scale(1.1);
      transform: scale(1.1); }
  .product-item-bgr {
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    -moz-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    -webkit-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .product-item-inner {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: space-between;
    -moz-box-pack: space-between;
    -ms-flex-pack: space-between;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 2; }
    .product-item-inner-title {
      padding: 1rem 3rem;
      margin: 0; }
    .product-item-inner-btn {
      padding: 1.5rem 3rem 2.5rem;
      text-transform: uppercase;
      letter-spacing: 2.8px;
      font-size: 16px;
      font-weight: 700;
      -webkit-transform: translateY(1rem);
      -moz-transform: translateY(1rem);
      -ms-transform: translateY(1rem);
      -o-transform: translateY(1rem);
      transform: translateY(1rem);
      -moz-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      -webkit-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
  .product-item-labels {
    position: absolute;
    top: 1rem;
    left: 1rem; }
    .product-item-labels .category-label {
      padding: 2.5px 5px;
      display: inline-block;
      margin: 1px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      -ms-border-radius: 4px;
      border-radius: 4px;
      font-size: 16px;
      text-transform: uppercase;
      letter-spacing: 1.5px; }

.filter-row {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -10px; }

.checkbox-filter {
  padding: 10px; }

.product-detail-row {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 10rem 0 0; }
  .product-detail-row-image {
    width: 100%;
    min-height: 300px;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    margin-bottom: 3rem; }
    @media (min-width: 992px) {
      .product-detail-row-image {
        width: 40%;
        min-height: 40rem;
        margin-bottom: 0; } }
  .product-detail-row-content {
    width: 100%; }
    @media (min-width: 992px) {
      .product-detail-row-content {
        width: 60%;
        padding-left: 6rem; } }
    .product-detail-row-content-perex {
      padding-bottom: 3rem; }
    .product-detail-row-content-labels {
      margin: 0 -1rem; }
      .product-detail-row-content-labels .category-label {
        padding: 1rem 2rem;
        display: inline-block;
        margin: 1rem;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        border-radius: 4px;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-size: 16px; }

.no-before:before {
  display: none !important; }

.row.products {
  min-height: 240px; }
  @media (min-width: 768px) {
    .row.products {
      min-height: 30rem; } }

.row.product-gallery {
  justify-content: flex-start;
  margin: 0 -7.5px; }
  .row.product-gallery [class^="col-"] {
    padding: 7.5px; }

.row.row-small {
  margin: 0 -7.5px; }

.text-container.product {
  text-align: center; }
  .text-container.product .btn {
    margin: 10px; }

.page-map {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 1000px; }
  .page-map-column {
    padding: 0 2rem 3rem 2rem;
    width: 33.33%; }
    .page-map-column > ul > li {
      padding: 0;
      width: 100%;
      margin: 0 0 2rem 0; }
      .page-map-column > ul > li:before {
        display: none; }
      .page-map-column > ul > li > span,
      .page-map-column > ul > li > a {
        color: #000;
        position: relative; }
        .page-map-column > ul > li > span:after,
        .page-map-column > ul > li > a:after {
          content: "";
          width: 100%;
          height: 1px;
          display: block;
          position: absolute;
          left: 0;
          bottom: 0; }
        .page-map-column > ul > li > span:focus, .page-map-column > ul > li > span:hover,
        .page-map-column > ul > li > a:focus,
        .page-map-column > ul > li > a:hover {
          text-decoration: none; }
      .page-map-column > ul > li > ul {
        display: block;
        margin: 3px 0 0 0; }
        .page-map-column > ul > li > ul > li {
          width: 100%;
          margin: 5px 0; }
          .page-map-column > ul > li > ul > li > a {
            color: #000;
            position: relative; }
            .page-map-column > ul > li > ul > li > a:after {
              content: "";
              width: 100%;
              height: 1px;
              display: block;
              position: absolute;
              left: 0;
              bottom: 0; }
            .page-map-column > ul > li > ul > li > a:focus, .page-map-column > ul > li > ul > li > a:hover {
              text-decoration: none; }

@keyframes slideDown {
  from {
    height: calc(auto - 10px); }
  to {
    height: auto; } }

.collapsible-item {
  display: flex;
  width: 100%;
  max-width: 78rem;
  margin: 0 auto;
  padding: 0;
  box-shadow: 0px 0px 16px -2px rgba(0, 0, 0, 0.2);
  border-width: 1px;
  border-style: solid;
  border-bottom: none;
  border-color: #ebebeb; }
  .collapsible-item.with-numbers .collapsible-item-heading {
    padding: 0 2.5rem 0 0; }
  .collapsible-item-heading {
    width: 100%;
    padding: 2.5rem;
    cursor: pointer;
    display: flex;
    background-color: #fff; }
    .collapsible-item-heading:after {
      content: '\e901';
      font-size: 13px;
      transition: 0.3s ease all;
      margin-left: auto;
      display: flex;
      align-items: center; }
    .collapsible-item-heading h2 {
      display: flex;
      align-items: center;
      padding: 1rem 0; }
    .collapsible-item-heading h2,
    .collapsible-item-heading .collapsible-item-index {
      margin: 0 1.5rem 0 0;
      line-height: 1;
      font-weight: 700;
      text-transform: none; }
      @media (min-width: 320px) {
        .collapsible-item-heading h2,
        .collapsible-item-heading .collapsible-item-index {
          font-size: 4.4rem; } }
      @media (min-width: 480px) {
        .collapsible-item-heading h2,
        .collapsible-item-heading .collapsible-item-index {
          font-size: 4rem; } }
      @media (min-width: 545px) {
        .collapsible-item-heading h2,
        .collapsible-item-heading .collapsible-item-index {
          font-size: 3.66667rem; } }
      @media (min-width: 768px) {
        .collapsible-item-heading h2,
        .collapsible-item-heading .collapsible-item-index {
          font-size: 2.93333rem; } }
      @media (min-width: 992px) {
        .collapsible-item-heading h2,
        .collapsible-item-heading .collapsible-item-index {
          font-size: 2.75rem; } }
      @media (min-width: 1200px) {
        .collapsible-item-heading h2,
        .collapsible-item-heading .collapsible-item-index {
          font-size: 2.44444rem; } }
      @media (min-width: 1400px) {
        .collapsible-item-heading h2,
        .collapsible-item-heading .collapsible-item-index {
          font-size: 2.4rem; } }
    .collapsible-item-heading .collapsible-item-index {
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      width: 50px;
      min-width: 50px;
      min-height: 50px; }
      @media (min-width: 768px) {
        .collapsible-item-heading .collapsible-item-index {
          width: 60px;
          min-width: 60px;
          min-height: 60px; } }
  .collapsible-item-container {
    width: 100%;
    overflow: hidden; }
    .collapsible-item-container .collapsible-item-text {
      transition: 0.3s ease-in-out opacity;
      height: 0;
      opacity: 0; }
    .collapsible-item-container.open {
      height: auto; }
      .collapsible-item-container.open .collapsible-item-text {
        border-top: 1px solid #ebebeb;
        height: auto;
        opacity: 1;
        padding: 1rem 2.5rem 2.5rem 2.5rem; }
      .collapsible-item-container.open .collapsible-item-heading:after {
        transform: rotate(180deg); }
  .collapsible-item-controller {
    width: 7.5rem;
    position: relative;
    cursor: pointer; }
    .collapsible-item-controller .close, .collapsible-item-controller .open {
      font-size: 30px;
      font-weight: 800;
      color: #f8b620;
      text-align: center;
      opacity: 1;
      transform: translate(-50%, -50%);
      position: absolute;
      top: 3.7rem;
      left: 50%; }
    .collapsible-item-controller .close {
      display: inline-block; }
    .collapsible-item-controller .open {
      display: none; }
    .collapsible-item-controller.open .close {
      display: none; }
    .collapsible-item-controller.open .open {
      display: inline-block; }
  .collapsible-item:last-child {
    border-bottom: 1px solid #ebebeb; }

.smaller-card .card-item-label {
  padding: 1rem 2rem; }

.smaller-card .card-item-content {
  padding: 1.5rem 3rem;
  margin-bottom: 3rem; }

.pux-modal-leave,
.pux-modal {
  z-index: 99999; }
  .pux-modal-leave .modal-dialog,
  .pux-modal .modal-dialog {
    margin: 14rem auto 0 auto;
    max-width: 800px; }
  .pux-modal-leave .modal-content,
  .pux-modal .modal-content {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    background-repeat: no-repeat !important;
    border: none;
    text-shadow: none; }
    .pux-modal-leave .modal-content .close,
    .pux-modal .modal-content .close {
      outline: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 30px;
      height: 30px;
      font-size: 30px;
      cursor: pointer;
      z-index: 22;
      opacity: 1;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
      filter: alpha(opacity=100); }
    .pux-modal-leave .modal-content.modal-light,
    .pux-modal .modal-content.modal-light {
      background-color: #fff;
      color: #000;
      border: none; }
      .pux-modal-leave .modal-content.modal-light .close,
      .pux-modal .modal-content.modal-light .close {
        background: #5a5a5a;
        color: red; }
        .pux-modal-leave .modal-content.modal-light .close:focus, .pux-modal-leave .modal-content.modal-light .close:hover,
        .pux-modal .modal-content.modal-light .close:focus,
        .pux-modal .modal-content.modal-light .close:hover {
          background: #404040; }
    .pux-modal-leave .modal-content.modal-dark,
    .pux-modal .modal-content.modal-dark {
      background-color: #585857;
      color: #fff;
      border: none; }
      .pux-modal-leave .modal-content.modal-dark .close,
      .pux-modal .modal-content.modal-dark .close {
        background: grey;
        color: red; }
        .pux-modal-leave .modal-content.modal-dark .close:focus, .pux-modal-leave .modal-content.modal-dark .close:hover,
        .pux-modal .modal-content.modal-dark .close:focus,
        .pux-modal .modal-content.modal-dark .close:hover {
          background: #676767; }
    .pux-modal-leave .modal-content .modal-body,
    .pux-modal .modal-content .modal-body {
      padding: 30px; }
      .pux-modal-leave .modal-content .modal-body-title,
      .pux-modal .modal-content .modal-body-title {
        font-size: 22px;
        font-size: 1.375rem;
        padding: 0 0 15px 0;
        font-weight: bold; }
      .pux-modal-leave .modal-content .modal-body-subtitle,
      .pux-modal .modal-content .modal-body-subtitle {
        font-size: 18px;
        font-size: 1.125rem;
        padding: 0 0 10px 0; }
      .pux-modal-leave .modal-content .modal-body.no-padding,
      .pux-modal .modal-content .modal-body.no-padding {
        padding: 0; }
    .pux-modal-leave .modal-content.background-cover,
    .pux-modal .modal-content.background-cover {
      background-size: cover !important; }
    .pux-modal-leave .modal-content.top-center,
    .pux-modal .modal-content.top-center {
      background-position: top center !important; }
    .pux-modal-leave .modal-content.top-left,
    .pux-modal .modal-content.top-left {
      background-position: top left !important; }
    .pux-modal-leave .modal-content.top-right,
    .pux-modal .modal-content.top-right {
      background-position: top right !important; }
    .pux-modal-leave .modal-content.center-center,
    .pux-modal .modal-content.center-center {
      background-position: center center !important; }
    .pux-modal-leave .modal-content.bottom-center,
    .pux-modal .modal-content.bottom-center {
      background-position: bottom center !important; }
    .pux-modal-leave .modal-content.bottom-left,
    .pux-modal .modal-content.bottom-left {
      background-position: bottom left !important; }
    .pux-modal-leave .modal-content.bottom-right,
    .pux-modal .modal-content.bottom-right {
      background-position: bottom right !important; }

.modal-backdrop {
  background: #000;
  z-index: 9999; }

@media print {
  header,
  footer {
    display: none; } }
